import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClusterModel } from 'src/app/models/cluster.model';
import { CountryModel } from 'src/app/models/country.model';
import { RegionModel } from 'src/app/models/region.model';
import { CountriesService } from 'src/app/providers/countries.service';
import { FormPage } from 'src/app/pages/FormPage';
import { GlobalMessage } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-country-edit.page',
    templateUrl: './country-edit.page.html'
})
export class CountryEditPage extends FormPage implements OnInit {
    public countryForm = this.fb.group({
        countryId: [''],
        code: [''],
        name: [''],
        regionId: [''],
        clusterId: [''],
    });

    public regions: RegionModel[] = null;
    public clusters: ClusterModel[] = null;
    public countryId: number = null;
    private countryData: CountryModel;

    constructor(
        private fb: FormBuilder,
        private countryService: CountriesService,
        private route: ActivatedRoute,
        private errorHandler: GlobalMessage) {
        super();
    }

    ngOnInit() {
        this.updateRegions();
        this.updateClusters();

        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null) {
                this.countryId = Number.parseInt(params.get('id'), 10);
                this.countryService.info(Number.parseInt(params.get('id'), 10)).subscribe(
                    (data) => {
                        this.countryData = data;
                        this.countryForm.setValue({
                            countryId: data.id,
                            code: data.code,
                            name: data.name,
                            regionId: data.region ? data.region.id : null,
                            clusterId: data.cluster ? data.cluster.id : null
                        });
                    }
                );
            }
            else {
                // Only for new
                this.countryForm.get('code').setValidators(Validators.required);
                this.countryForm.get('name').setValidators(Validators.required);
                this.countryForm.get('regionId').setValidators(Validators.required);
                this.countryForm.get('clusterId').setValidators(Validators.required);
            }
        });
    }

    onSubmit() {
        if (this.countryForm.valid) {
            const country = this.countryForm.value;
            const region = new RegionModel({ id: +country.regionId });
            const cluster = new ClusterModel({ id: +country.clusterId });
            const countrySend = new CountryModel({
                id: country.countryId,
                code: country.code,
                name: country.name,
                region: region,
                cluster: cluster,
            });
            if (this.countryData) {
                countrySend.createDate = this.countryData.createDate;
                countrySend.createdBy = this.countryData.createdBy;
                countrySend.deleted = this.countryData.deleted;
            }
            this.countryService.createOrEdit(countrySend).subscribe((data: any) => {
            }, (error) => {
                this.errorHandler.showError('Form contains errors');
            }, () => {
                this.errorHandler.showSuccess('Your country has been saved successfully', 5000);

            });
        }
        else {
            this.validateAllFormFields(this.countryForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    updateRegions() {
        this.countryService.getRegions().subscribe(data => {
            this.regions = data;
        });
    }

    updateClusters() {
        this.countryService.getClusters().subscribe(data => {
            this.clusters = data;
        });
    }

    getForm(): FormGroup {
        return this.countryForm;
    }
}
