import { AttendeeContactModel } from './attendee-contact.model';

export class ContactSocialModel {
    public id: number;
    public social: string;
    public value: string;
    public contact: AttendeeContactModel;

    public fromJson(jsonData)
    {
        this.id = jsonData.ID ? Number.parseInt(jsonData.ID, 10) : null;
        this.social = jsonData.social;
        this.value = jsonData.social_value;

        if (this.contact !== undefined)
        {
            this.contact.fromJson({ID: jsonData.person_id});
        }
        else
        {
            this.contact = new AttendeeContactModel({ID: jsonData.person_id});
        }
    }

    constructor(jsonData) {
        this.fromJson(jsonData);
    }
}
