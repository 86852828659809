import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkHistoryModel } from '../models/work-history.model';
import { WorkHistoryApiService } from './work-history-api.service';

@Injectable({
  providedIn: 'root'
})
export class WorkHistoryService
{
    constructor(
        private workHistoryApi: WorkHistoryApiService
    ) { }

    public list(contactId: number)
    {
        const workHistory: WorkHistoryModel[] = [];
        return this.workHistoryApi.list(contactId).pipe(
            map( l => {
                l.data.forEach( element => workHistory.push(new WorkHistoryModel(element)));
                return workHistory;
            })
        );
    }

    public info(workHistoryId: number): Observable<WorkHistoryModel>
    {
        return this.workHistoryApi.info(workHistoryId).pipe(
            map( res => new WorkHistoryModel(res))
        );
    }

    public delete(workHistory: WorkHistoryModel)
    {
        return this.workHistoryApi.delete(workHistory.id);
    }

    public createOrEdit(workHistory: WorkHistoryModel)
    {
        return this.workHistoryApi.createOrEdit(workHistory);
    }
}
