import { AfterViewInit, Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactHobbyModel } from 'src/app/models/contact-hobby.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { ContactHobbiesService } from 'src/app/providers/contact-hobbies.service';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
    selector: 'app-hobbies',
    templateUrl: './hobby-list.page.html'
})
export class HobbyListPage extends DatatablePage implements OnInit, AfterViewInit {
    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    destroySubject$: Subject<void> = new Subject();
    public hobbies: ContactHobbyModel[];
    private editingId: number;
    private editingValue: string;
    private editHobbi: any;

    constructor(
        private hobbyService: ContactHobbiesService,
        private modalService: NgbModal,
        private messageHandler: GlobalMessage
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.setDtOption('pageLength', 25);
    }

    ngAfterViewInit() {
        this.refreshList();
        this.refreshDt();
    }

    refreshList() {
        const size = 999999;
        this.hobbyService.search(size).subscribe(data => {
            this.hobbies = data.filter(value => {
                if (!value.deleted) {
                    return value;
                }
            });
            this.reRenderDt();
        });
    }

    delete(content, hobbyId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result) {
                this.hobbyService.delete(this.hobbies.find(a => a.id === hobbyId)).subscribe(
                    x => {
                        this.refreshList();
                    }
                );
            }

        }, (reason) => {
        });
    }

    startEdit(hobbyId: number) {
        this.editingId = hobbyId;
        this.editingValue = this.hobbies.find(h => h.id === hobbyId).name;
        this.editHobbi = this.hobbies.find(h => h.id === hobbyId);
    }

    validateEdit() {
        this.editHobbi.name = this.editingValue;
        this.hobbyService.createOrEdit(this.editHobbi).pipe(takeUntil(this.destroySubject$)).subscribe((data: any) => {
        }, error => {
            this.messageHandler.showError('Form contains errors');
        }, () => {
            this.editingId = 0;
            this.editingValue = '';
            this.refreshList();
            this.reRenderDt();
            this.messageHandler.showSuccess('The hobby has been saved successfully', 5000);
        });
    }
}
