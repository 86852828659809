import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { StatisticsApiService } from './statistics-api.service';
import { StatisticsTypeModel } from '../models/statistics-type.model';
import { StatisticsFilterModel } from '../models/statistics-filter.model';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService
{
    constructor(
        private statisticsApi: StatisticsApiService
    ) { }


    public list() {
        return this.statisticsApi.list();
    }

    public get(filter: StatisticsFilterModel)
    {
        return this.statisticsApi.get(filter);
    }

    public types()
    {
        return this.statisticsApi.types().pipe(
            map( (data: any) => {
                const types: StatisticsTypeModel[] = [];
                if (data.status === 'success')
                {
                    data.data.forEach(element => {
                        types.push(new StatisticsTypeModel(element));
                    });
                }
                return types;
            })
        );
    }

}
