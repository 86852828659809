import * as moment from 'moment';
import { ActivityTypeModel } from './activity-type.model';
import { AttendeeContactModel } from './attendee-contact.model';
import { BrandCategoryModel } from './brand-category.model';
import { BrandProgramModel } from './brand-program.model';
import { BrandModel } from './brand.model';
import { OutletModel } from './outlet.model';
import { PictureModel } from './picture.model';
import { ProgramModel } from './program.model';
import { UserModel } from './user.model';
import { AttendeeTypeModel } from './attendee-type.model';
import { AttendeeModel } from './attendee.model';
import { ActivityCategoryModel } from './activity-category.model';

export class ActivityModel {
    public id: number;
    public countryId;
    public outlets: OutletModel[] = [];
    public date: moment.Moment;
    public dateFrom: moment.Moment;
    public dateTo: moment.Moment;
    public addedOn: moment.Moment;
    public typeId;
    public comment;
    public program: BrandProgramModel[];
    public activityType: ActivityTypeModel;
    public activityCategory: ActivityCategoryModel;
    public attendees: AttendeeModel[];
    public attendeesContacts: AttendeeContactModel[];
    public picture: PictureModel;
    public user: UserModel;

    public fromJson(jsonData)
    {
        this.countryId = jsonData.country_id;

        if (jsonData.outlets)
        {
            for (let i = 0; i < jsonData.outlets.length; i++)
            {
                this.outlets.push(new OutletModel(jsonData.outlets[i]));
            }
        }

        this.date = moment(jsonData.date, 'YYYY-MM-DD');
        this.dateFrom = moment(jsonData.date_from, 'HH:mm');
        this.dateTo = moment(jsonData.date_to, 'HH:mm');
        this.addedOn = moment(jsonData.added_on, 'YYYY-MM-DD HH:mm:ss');
        this.typeId = jsonData.activity_type_id;
        this.comment = jsonData.comment;
        if (this.picture !== undefined)
        {
            this.picture.fromJson({ id: jsonData.pic_id, name: jsonData.pic_name, url: jsonData.pic_url });
        }
        else
        {
            this.picture = new PictureModel({ id: jsonData.pic_id, name: jsonData.pic_name, url: jsonData.pic_url });
        }

        this.program = [];
        if (jsonData.brands_programs)
        {
            jsonData.brands_programs.forEach( program => {
                const bp = new BrandProgramModel({});
                bp.fromItems(
                    new BrandModel({ID: program.brand_id, name: program.brand_name}),
                    new BrandCategoryModel({ID: program.brand_category_id}),
                    new ProgramModel({ID: program.program_id})
                );
                this.program.push(bp);
            });
        }

        if (jsonData.attendees)
        {
            this.attendees = [];
            jsonData.attendees.forEach( att => {
                this.attendees.push(new AttendeeModel(
                    new AttendeeTypeModel({id: att.attendees_type_id, name: att.attendees_type_name}), att.attendees, []));
            });
        }

        if (jsonData.persons)
        {
            jsonData.persons.forEach(person => {
                const attendee = this.attendees.find(a => a.type.id === Number.parseInt(person.attendee_type_id, 10));
                if (attendee)
                {
                    attendee.contacts.push(new AttendeeContactModel(person));
                }
            });
        }
    }

    constructor(jsonData: any) {
        this.id = jsonData.ID ? Number.parseInt(jsonData.ID, 10) : null;
        this.fromJson(jsonData);
    }
}

export class ActivityModelResponse {
    constructor(
        public activity: ActivityModel,
    ) {}
}
