import { CountryModel } from './country.model';
import * as moment from 'moment';
import { UserRoleModel } from './user-role.model';

export class UserModel {
    public id: number;
    public uid: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public language: any;
    public countries: CountryModel[] = [];
    public defaultCountryId: number = null;
    public defaultRegion: any;
    public manager: UserModel;
    public role: UserRoleModel;
    public adminLevel: number;
    public createdOn: moment.Moment;
    public deleted: boolean;
    public displayName: string;
    public token: string;
    public tokenExpiration: number;
    public lastTokenUpdate: number;
    public isAdmin = false;
    public isManager = false;

    static fromObject(values: UserModel): UserModel {
        const c = new UserModel({});
        return Object.assign(c, values);
    }

    public fromJson(jsonData: any) {
        this.defaultRegion = jsonData.defaultRegion;
        this.id = jsonData.ID ? Number.parseInt(jsonData.ID, 10) : null;
        this.uid = jsonData.uid;
        this.firstName = jsonData.firstname;
        this.lastName = jsonData.lastname;
        this.email = jsonData.email;
        this.language = 'en';

        if (jsonData.default_country_id) {
            this.defaultCountryId = jsonData.default_country_id;
        }

        if (jsonData.countries) {
            jsonData.countries.forEach(country => {
                this.countries.push(new CountryModel({
                    id: country.country_id,
                    code: country.code,
                    name: country.name,
                    region: country.region_id,
                    cluster: country.cluster_id
                }));

                if (!this.defaultCountryId && country.defaultCountry) {
                    this.defaultCountryId = Number.parseInt(country.userCountryKey.country.id, 10);
                }
            });

            this.countries = [... this.countries].sort((a, b) => (a.name === b.name ? 0 : (a.name < b.name ? 1 : -1)));
        }

        if (jsonData.manager) {
            this.manager = new UserModel(jsonData.manager);
        }
        if (jsonData.role) {
            this.role = new UserRoleModel({ id: jsonData.user_role_id, admin_level: jsonData.adminLevel, name: jsonData.role });
            if (this.role.id !== 1) {
                this.isManager = this.role.id === 2;
                this.isAdmin = this.role.id > 2;
            }
        }

        this.createdOn = moment(jsonData.created_on, 'YYYY-MM-DD HH:mm:ss');
        this.deleted = Number.parseInt(jsonData.deleted, 10) === 1;
        this.displayName = jsonData.display_name;
        if (jsonData.token) {
            this.token = jsonData.token;
        }
        this.tokenExpiration = jsonData.token_expiration;
        this.lastTokenUpdate = jsonData.last_token_update;
        this.adminLevel = jsonData.admin_level ? Number.parseInt(jsonData.admin_level, 10) : null;
    }

    constructor(jsonData) {
        this.fromJson(jsonData);
    }

    get name() {
        let name = '';
        if (this.firstName) {
            name += this.firstName;
            if (this.lastName) {
                name += ' ' + this.lastName;
            }
        }
        else if (this.lastName) {
            name = this.lastName;
        }
        else {
            name = this.email;
        }

        return name;
    }

    set name(name: string) {

    }
}

export class UserModelResponse {
    constructor(
        public user: UserModel,
        // public responseStatus: ResponseStatus
    ) { }
}
