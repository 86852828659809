import { Component, OnInit } from '@angular/core';
import { GlobalMessage, Message } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-message-display',
    templateUrl: './message-display.component.html'
})
export class MessageDisplayComponent implements OnInit
{
    public message: Message;
    public showDetails: boolean;

    constructor(private globalMessage: GlobalMessage) { }

    ngOnInit()
    {
        this.globalMessage.message.subscribe((m) => {
            this.message = m;
            this.showDetails = false;
            setTimeout(() => this.message = null, m.timeout);
        });
    }

    close() {
        this.message = null;
    }

    showMore()
    {
        this.showDetails = true;
    }
}
