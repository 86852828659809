import { CountryModel } from './country.model';

export class WinningInModel {
    public id: number;
    public city: string;
    public country: CountryModel;


    constructor(winning:  Partial<WinningInModel>) {
        this.id = winning.id;
        this.city = winning.city;
        this.country = winning.country;
    }
}
