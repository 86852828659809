import { Component, Input } from '@angular/core';
import { OutletModel } from 'src/app/models/outlet.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-outlet-modal',
    templateUrl: './outlet-modal.component.html'
})
export class OutletModalComponent
{
    constructor(public activeModal: NgbActiveModal)
    {

    }

    @Input() outlet: OutletModel;

    onCreationSuccess(outlet: OutletModel)
    {
        this.activeModal.close(outlet);
    }
}
