import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ContactsApiService } from './contacts-api.service';
import { Observable } from 'rxjs';
import { AttendeeContactModel } from '../models/attendee-contact.model';
import { ActivityModel } from '../models/activity.model';

@Injectable({
  providedIn: 'root'
})
export class ContactsService
{
    constructor(
        private contactsApi: ContactsApiService
    ) { }

    public list(attendeesTypeId: number = 0)
    {
        const contacts: AttendeeContactModel[] = [];
        return this.contactsApi.list(attendeesTypeId).pipe(
            map( l => {
                l.data.forEach( element => contacts.push(new AttendeeContactModel(element)));
                return contacts;
            })
        );
    }

    public info(contactId: number): Observable<AttendeeContactModel>
    {
        return this.contactsApi.info(contactId).pipe(
            map( res => new AttendeeContactModel(res))
        );
    }

    public delete(contact: AttendeeContactModel)
    {
        return this.contactsApi.delete(contact.id);
    }

    public createOrEdit(contact: AttendeeContactModel)
    {
        return this.contactsApi.createOrEdit(contact);
    }

    public ownedByUser()
    {
        const contacts: AttendeeContactModel[] = [];
        return this.contactsApi.ownedByUser().pipe(
            map( l => {
                l.data.forEach( element => contacts.push(new AttendeeContactModel(element)));
                return contacts;
            })
        );
    }

    public getActivities(contactId: number)
    {
        const activities: ActivityModel[] = [];
        return this.contactsApi.getActivities(contactId).pipe(
            map( l => {
                l.data.activities.forEach( element => activities.push(new ActivityModel(element)));
                return activities;
            })
        );
    }
}
