import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BrandCategoriesService } from 'src/app/providers/brand-categories.service';
import { BrandCategoryModel } from 'src/app/models/brand-category.model';
import { FormPage } from 'src/app/pages/FormPage';
import { GlobalMessage } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-brand-category-edit.page',
    templateUrl: './brand-category-edit.page.html',
})
export class BrandCategoryEditPage extends FormPage implements OnInit {
    public brandCategoryForm = this.fb.group({
        brandCategoryId: [''],
        name: ['', Validators.required],
    });

    public brandCategoryId: number = null;

    constructor(
        private fb: FormBuilder,
        private brandCategoryService: BrandCategoriesService,
        private route: ActivatedRoute,
        private errorHandler: GlobalMessage
    ) {
        super();
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            if (params.get('id') !== null) {
                this.brandCategoryId = Number.parseInt(params.get('id'), 10);
                this.brandCategoryService
                    .info(Number.parseInt(params.get('id'), 10))
                    .subscribe((data) => {
                        this.brandCategoryForm.setValue({
                            brandCategoryId: data.id,
                            name: data.name,
                        });
                    });
            }
        });
    }

    onSubmit() {
        if (this.brandCategoryForm.valid) {
            const brandCategory = this.brandCategoryForm.value;
            this.brandCategoryService
                .createOrEdit(
                    new BrandCategoryModel({
                        id: brandCategory.brandCategoryId,
                        name: brandCategory.name,
                    })
                )
                .subscribe(
                    (data: any) => {},
                    (error) => {
                        console.error(error);
                    },
                    () => {
                        this.errorHandler.showSuccess(
                            'Your brand category has been saved successfully',
                            5000
                        );
                    }
                );
        } else {
            this.validateAllFormFields(this.brandCategoryForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.brandCategoryForm;
    }
}
