import { AfterViewInit, Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactTitleModel } from 'src/app/models/contact-title.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { ContactTitlesService } from 'src/app/providers/contact-titles.service';
import { GlobalMessage } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-contact-titles',
    templateUrl: './contact-titles-list.page.html'
})
export class ContactTitleListPage extends DatatablePage implements OnInit, AfterViewInit {
    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    public contactTitles: ContactTitleModel[];
    private editingId: number;
    private editingValue: string;
    private editiContactTitles: any;

    constructor(
        private contactTitleService: ContactTitlesService,
        private modalService: NgbModal,
        private messageHandler: GlobalMessage
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.setDtOption('pageLength', 25);
    }

    ngAfterViewInit() {
        this.refreshList('');
        this.refreshDt();
    }

    refreshList(param: string) {
        const params = {
            'searchStr': param
        };
        const size = 999999;
        this.contactTitleService.search(params, size).subscribe(data => {
            this.contactTitles = data.filter(value => {
                return !value.deleted;
            });
            this.reRenderDt();
        });
    }

    delete(content, contactTitleId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result) {
                this.contactTitleService.delete(this.contactTitles.find(a => a.id === contactTitleId)).subscribe(
                    (x: any) => {
                        if (x.status === 204) {
                            this.refreshList('');
                        }
                    }
                );
            }

        }, (reason) => {
        });
    }

    startEdit(contactTitleId: number) {
        this.editingId = contactTitleId;
        this.editingValue = this.contactTitles.find(ct => ct.id === contactTitleId).name;
        this.editiContactTitles = this.contactTitles.find(ct => ct.id === contactTitleId);
    }

    validateEdit() {
        this.editiContactTitles.name = this.editingValue;
        this.contactTitleService.createOrEdit(this.editiContactTitles).subscribe((data: any) => {
        }, error => {
            this.messageHandler.showError('Form contains errors');
        }, () => {
            this.editingId = 0;
            this.editingValue = '';
            this.refreshList('');
            this.reRenderDt();
            this.messageHandler.showSuccess('The contact title has been saved successfully', 5000);
        });
    }
}
