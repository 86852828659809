import { Injectable } from '@angular/core';
import { ProgramModel } from '../models/program.model';
import { ApiService } from './api.service';
import { UserService } from './user.service';


@Injectable({
    providedIn: 'root'
})
export class ProgramsApiService {
    private endpoint = 'programregion';
    private endpointAlt = 'program';
    private requestHeader: any = {
        headers: { Authorization: this.user.token }
    };

    constructor(
        private api: ApiService,
        private user: UserService
    ) { }

    public list(brandId: number = 0, loadPastPrograms: boolean = false) {
        const params = {};
        if (brandId) {
            params['brand_id'] = brandId;
        }

        params['load_past_programs'] = loadPastPrograms;

        return this.api.get(this.endpoint, params, this.requestHeader)
            .pipe((res: any) => res);
    }

    public info(programId: number) {
        return this.api.get(this.endpoint + '/detail/' + programId, null, this.requestHeader)
            .pipe((res: any) => res);
    }

    public delete(programId: number) {
        return this.api.patch(this.endpoint + '/delete/' + programId, {}, this.requestHeader);
    }

    public createOrEdit(program: ProgramModel) {
        const programObject = program;
        let url;
        if (program.id) {
            url = `${this.endpoint}/edit/${program.id}`;
            return this.api.put(url, programObject, this.requestHeader);
        } else {
            url = `${this.endpoint}/add`;
            // Enviar el region por defecto del usuario logado
            programObject.region = this.user.user.countries[0].region;
            return this.api.post(url, programObject, this.requestHeader);
        }

    }

    public getGlobals() {
        return this.api.get(this.endpointAlt + '/list', null, this.requestHeader);
    }

    public search(body = {}, paramTable?) {

        switch (paramTable.orderBy) {
            case 0:
                paramTable.orderBy = 'name';
                break;
            case 1:
                paramTable.orderBy = 'progFrom';
                break;
            case 2:
                paramTable.orderBy = 'progTo';
                break;
            case 3:
                paramTable.orderBy = 'brandRegion.name';
                break;
            default:
                paramTable.orderBy = 'id';
                break;
        }

        if (paramTable) {
            const param = {
                headers: { Authorization: this.user.token },
                params: {
                    size: paramTable.resultsNum,
                    page: paramTable.offset / paramTable.resultsNum,
                    sort: paramTable.orderBy + ',' + paramTable.order
                }
            };
            return this.api.post(`${this.endpoint}/search/` + this.user.user.id, body, param);
        } else {
            return this.api.post(`${this.endpoint}/search` + this.user.user.id, body, this.requestHeader);
        }
    }

    public getByBrand(brandId: number) {
        return this.api.get(this.endpoint + '/programByBrand/' + brandId, {}, this.requestHeader);
    }

    public getByRegionOfBrand(brandId: number) {
        return this.api.get(this.endpoint + '/programByRegionOfBrand/' + brandId, {}, this.requestHeader);
    }

    public createGlobal(global) {

        return this.api.post(`${this.endpointAlt}/add`, global, this.requestHeader);
    }
}
