import { Injectable } from '@angular/core';
import { UserApiService } from './user-api.service';
import { UserModel } from './../models/user.model';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from './user.service';


@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(
        private userApi: UserApiService,
        private userService: UserService,
        private router: Router
    ) {

    }

    private setUserAfterLogin(res)
    {
        if (res && res.logged_in === true)
        {
            const _user = new UserModel(res.data);
            _user.token = res.api_session_token;
            this.userService.user = _user;
            this.userApi.userInfo(_user.id, _user.token).subscribe(data => {
                _user.fromJson(data);
                this.userService.user = _user;
            });
            return true;
        }
        else
        {
            this.userService.clearUser();
            return false;
        }
    }

    public login(email: string, password: string)
    {
        return this.userApi.login(email, password).pipe(
            map(
                res => this.setUserAfterLogin(res)
            )
        );
    }

    public logout()
    {
        if (this.userService.user.token)
        {
            this.userApi.logout(this.userService.user.token).subscribe(
                d => {
                    this.userService.clearUser();
                    this.router.navigate(['/login']);
                }
            );
        }
    }

    /**
     * Login with token
     */
    public loginWithToken(token: string) {
        return this.userApi.loginWithToken(token).pipe(
            map(
                res => this.setUserAfterLogin(res)
            )
        );
    }

    public requestToken(email: string) {
        return this.userApi.loginTokenRequest(email).pipe(
            map(
                res => {
                    return res;
                }
            )
        );
    }
}
