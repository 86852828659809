import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { StatisticsFilterModel } from '../models/statistics-filter.model';

@Injectable({
    providedIn: 'root'
})
export class StatisticsApiService {
    private endpoint = 'statistics';
    private endpointTypes = 'statistics_types';

    constructor(
        private api: ApiService,
        private user: UserService)
    { }

    public list() {
        return this.api.get(this.endpoint + '/statisticsTypes', {}, {headers: {Authorization: this.user.token}});
    }

    public get(filter: StatisticsFilterModel)
    {
        const params = {
            actSegmentGlobal: filter.activitySegment ? filter.activitySegment.id : null,
            activityTypeGlobal: filter.activityType ? filter.activityType.id : null,
            brandGlobal: filter.brand ? filter.brand.id : null,
            cluster: filter.cluster ? filter.cluster.id : null,
            country: filter.country ? filter.country.id : null,
            from: filter.from,
            posSegmentGlobal: filter.posSegment ? filter.posSegment.id : null,
            programGlobal: filter.program ? filter.program.id : null,
            region: filter.region ? filter.region.id : null,
            to: filter.to,
            users: filter.users ? filter.users : [53],
            winningIn: filter.winningIn ? filter.winningIn.id : null
        };

        return this.api.post(this.endpoint + '/getStatistics/' + filter.type.id,
            params,
            {headers: {Authorization: this.user.token}});
    }

    public types()
    {
        return this.api.get(this.endpointTypes, {}, {headers: {Authorization: this.user.token}});
    }
}
