import { Component, Input, ViewChild } from '@angular/core';
import { StatisticsService } from 'src/app/providers/statistics.service';
import { BaseChartDirective } from 'ng2-charts/ng2-charts';
import { StatisticsFilterModel } from 'src/app/models/statistics-filter.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
    selector: 'app-statistics-view',
    templateUrl: './statistics-view.component.html',
    styleUrls: ['./statistics-view.component.scss']
})
export class StatisticsViewComponent {
    @ViewChild(BaseChartDirective, null) chart: BaseChartDirective;
    private _filter: StatisticsFilterModel;
    public _statsData: any;

    public chartType = 'doughnut';
    public chartOptions = {
        legend: {
            display: true
        },
        options: {
            responsive: true,
            maintainAspectRatio: true
        }
    };

    constructor(
        private statisticsService: StatisticsService,
        private ngxService: NgxUiLoaderService
    ) {}

    @Input() set filter(v: StatisticsFilterModel) {
        if (v) {
            this._filter = v;
            this.ngxService.start();
            this.statisticsService.get(this._filter).subscribe(data => {
                const canvasContainer = document.getElementById('chartCanvas');
                this._statsData = {
                    values: [],
                    labels: [],
                    size: 0
                };

                canvasContainer.classList.add('hidden');

                if (data.total > 0) {
                    let total = 0;
                    // Calculamos el total
                    data.values.forEach(val => (total += val.value));

                    this._statsData.size = total;
                    // Calculamos los valores para el canvas
                    for (let i = 0; i < data.values.length; i++) {
                        const value = data.values[i];

                        this._statsData.labels.push(
                            value.name + ' (' + value.pct + '%)'
                        );
                        this._statsData.values.push(
                            Math.round((1000 / total) * value.value) / 10
                        );
                    }

                    // Mostramos el resultado
                    canvasContainer.classList.remove('hidden');

                    this.chartType = data.graphType;
                    this.chart.chart.update();
                    this.ngxService.stop();
                }
            });
        }
    }

    get chartData(): number[] {
        if (this._statsData) {
            this.chart.chart.config.data.labels = this.chartLabels;
            return this._statsData.values;
        }
        return new Array(25).fill(1);
    }

    get chartLabels(): string[] {
        if (this._statsData) {
            return this._statsData.labels;
        }
        return ['', '', ''];
    }

    get hideChart(): boolean {
        return this._statsData === undefined || this._statsData.size === 0;
    }

    chartHovered($event) {}

    chartClicked($event) {}
}
