import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TargetsService } from 'src/app/providers/targets.service';
import { TargetTypeModel } from 'src/app/models/target-type.model';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide : NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TargetTypeSelectComponent),
    multi : true,
};
@Component({
    selector: 'app-target-type-select',
    templateUrl: './target-type-select.component.html',
    providers : [CUSTOM_VALUE_ACCESSOR]
})
export class TargetTypeSelectComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    public disabled = false;
    public value: string = null;
    public targetTypes: TargetTypeModel[];
    private onChange: Function = (_: any) => {};
    private onTouched: Function = () => {};

    constructor(private targetService: TargetsService) {}

    ngOnInit() {
        this.targetService.getTypes().subscribe(data => {
            this.targetTypes = data;
            this.targetTypes.unshift(new TargetTypeModel({'ID': null, 'name': ''}));
        });
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    targetTypeChange(ev)
    {
        this.value = ev.target.value;
        this.onChange(this.value);
        this.onTouched();
    }
}
