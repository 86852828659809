import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ContactTitlesApiService } from './contact-titles-api.service';
import { Observable } from 'rxjs';
import { ContactTitleModel } from '../models/contact-title.model';
import { GlobalModel } from '../models/global.model';
import { GlobalId } from './global-id.interface';

@Injectable({
    providedIn: 'root'
})
export class ContactTitlesService implements GlobalId {
    constructor(
        private contactTitlesApi: ContactTitlesApiService
    ) { }

    public search(params: any, size?: number, page?: number) {
        const contactTitles: ContactTitleModel[] = [];
        return this.contactTitlesApi.search(params, size, page).pipe(
            map(l => {
                if (l && l.content) {
                    l.content.forEach(element => contactTitles.push(new ContactTitleModel(element)));
                }
                return contactTitles;
            })
        );
    }

    public info(contactTitleId: number): Observable<ContactTitleModel> {
        return this.contactTitlesApi.info(contactTitleId).pipe(
            map(res => new ContactTitleModel(res))
        );
    }

    public delete(contactTitle: ContactTitleModel) {
        return this.contactTitlesApi.delete(contactTitle.id);
    }

    public createOrEdit(contactTitle: ContactTitleModel) {
        return this.contactTitlesApi.createOrEdit(contactTitle);
    }

    public getGlobals() {
        const globals: GlobalModel[] = [];
        return this.contactTitlesApi.getGlobals().pipe(
            map(l => {
                l.forEach(element => globals.push(new GlobalModel(element)));
                return globals;
            })
        );
    }

    public createGlobal(global) {
        return this.contactTitlesApi.createGlobal(global);
    }
}
