import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { OutletSelectComponent } from 'src/app/components/outlet-select/outlet-select.component';
import { AttendeeTypeModel } from 'src/app/models/attendee-type.model';
import { AttendeeModel } from 'src/app/models/attendee.model';
import { PictureModel } from 'src/app/models/picture.model';
import { UserModel } from 'src/app/models/user.model';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { UserService } from 'src/app/providers/user.service';
import { UsersService } from 'src/app/providers/users.service';
import { FormPage } from '../../../../FormPage';
import { ActivityTypeModel } from '../../../../../models/activity-type.model';
import { OutletModel } from '../../../../../models/outlet.model';
import { BrandCategoryModel } from '../../../../../models/brand-category.model';
import { BrandModel } from '../../../../../models/brand.model';
import { ProgramModel } from '../../../../../models/program.model';
import { ActivitiesService } from '../../../../../providers/activities.service';
import { ActivityTypesService } from '../../../../../providers/activity-types.service';
import { OutletsService } from '../../../../../providers/outlets.service';
import { AttendeesTypesService } from '../../../../../providers/attendees-types.service';
import { BrandCategoriesService } from '../../../../../providers/brand-categories.service';
import { BrandsService } from '../../../../../providers/brands.service';
import { ProgramsService } from '../../../../../providers/programs.service';
import { BrandProgramModel } from '../../../../../models/brand-program.model';
import { ActivityModel } from '../../../../../models/activity.model';
import { ActivityCategoryModel } from '../../../../../models/activity-category.model';

@Component({
    selector: 'app-activity-edit',
    templateUrl: './activity-edit.page.html'
})
export class ActivityEditPage extends FormPage implements OnInit {
    @ViewChild('activityForm', null) activityForm: NgForm;
    @ViewChildren(OutletSelectComponent) outletSelects: QueryList<
        OutletSelectComponent
    >;

    public displayForm = true;

    public activity: any;
    public activityTypes: ActivityTypeModel[];
    public activityCategories: ActivityCategoryModel[];
    public outlets: OutletModel[];
    // Cambiar service de BrandCategories a list
    public brandCategories: BrandCategoryModel[] = [];
    public brands: BrandModel[][] = [];
    public brandPrograms: ProgramModel[][] = [];

    public idUser: number;
    public date: NgbDateStruct;
    public from: any;
    public to: any;
    public useOutlet = true;
    public addingOutletId: number;
    public selectedOutlets: OutletModel[] = [];
    public activityCategory: ActivityCategoryModel;
    public activityCategoryId: number = null;
    public activityType: ActivityTypeModel;
    public activityTypeId: number = null;
    public brandCategory: BrandCategoryModel[] = [];
    public brandCategoryId: number[] = [];
    public brand: BrandModel[] = [];
    oldBrand: BrandModel;
    public brandId: number[] = [];
    public brandProgram: ProgramModel[] = [];
    public brandProgramId: number[] = [];
    public brandProgramaIsMenuListing: boolean[] = [];
    public brandProgramaIsNewListing: boolean[] = [];
    public brandProgramaIsNewContract: boolean[] = [];
    public brandProgramaIsHandsOn: boolean[] = [];
    public brandProgramNumberOfSites: number[] = [];
    public brandProgramNumberOfServes: number[] = [];
    public brandProgramSalesVolume: number[] = [];
    public brandProgramBottlesMerchandised: number[] = [];
    public brandProgramDisplaysBuilt: number[] = [];
    public brandProgramIncreasedVisibility: number[] = [];
    public comment: string;
    public countryId = 1;
    public languageId = '';
    public pictureFile: File;
    public pictureResultBase64: string;

    public attendeesTypes: AttendeeTypeModel[] = [];
    public attendees: any[] = [];

    public brandCategoryLoading = true;
    public brandLoading: boolean;
    public brandProgramLoading: boolean;
    public isFormLoading = false;
    public formSubmited = false;
    currentPrograms: any[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private activitiesService: ActivitiesService,
        private activityTypesService: ActivityTypesService,
        private outletService: OutletsService,
        private attendeesTypeService: AttendeesTypesService,
        private brandCategoryService: BrandCategoriesService,
        private brandsService: BrandsService,
        private programsService: ProgramsService,
        private userService: UserService,
        private usersService: UsersService,
        private errorHandler: GlobalMessage
    ) {
        super();
    }

    ngOnInit() {
        this.countryId = this.userService.user.defaultCountryId;
        this.languageId = this.userService.user.language.id;

        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null) {
                this.activitiesService
                    .info(Number(params.get('id')))
                    .subscribe(data => {
                        if (!data) {
                            this.errorHandler.showError(
                                'An error occurred, this activity does not exist.'
                            );
                            this.displayForm = false;
                            return;
                        }

                        this.activity = data;

                        // Date
                        const stringDate = String(this.activity.date).split(
                            'T'
                        )[0];
                        this.date = {
                            year: Number(stringDate.split('-')[0]),
                            month: Number(stringDate.split('-')[1]),
                            day: Number(stringDate.split('-')[2])
                        };

                        // From and To
                        this.from = {
                            hour: Number(String(this.activity.dateFrom).split(':')[0]),
                            minute: Number(String(this.activity.dateFrom).split(':')[1])
                        };
                        this.to = {
                            hour: Number(String(this.activity.dateTo).split(':')[0]),
                            minute: Number(String(this.activity.dateTo).split(':')[1])
                        };

                        // Country
                        this.countryId = this.activity.country.id;
                        this.updateChoices();

                        // Activity Type
                        this.activityTypeId = this.activity.activityType.id;

                        // Activity Category
                        if (this.activity.activityCategory) {
                            this.activityCategoryId = this.activity.activityCategory.id;
                        }

                        // Outlets
                        this.selectedOutlets = this.activity.activityOutletList;
                        if (this.selectedOutlets && this.selectedOutlets.length > 0) {
                            for (let i = 0; i < this.selectedOutlets.length; i++) {
                                this.onOutletChange(this.selectedOutlets[i].id, i);
                            }
                        } else {
                            this.useOutlet = false;
                        }

                        // Brands & Programs
                        if (this.activity.activityProgramList && this.activity.activityProgramList.length > 0) {
                            this.activity.activityProgramList.forEach(
                                activityProgram => {
                                    this.brandsService
                                        .getByBrandCategory(
                                            activityProgram.brand.brandCategory.id,
                                            this.countryId
                                        )
                                        .subscribe(brand => {
                                            this.programsService
                                                .getByRegionOfBrand(activityProgram.brand.id)
                                                .subscribe(program => {
                                                    // Brand Category
                                                    this.brandCategoryId.push(activityProgram.brand.brandCategory.id);
                                                    this.brandCategories.push(activityProgram.brand.brandCategory);

                                                    // Brand

                                                    // Fix for activities with deleted/closed brands
                                                    if (!brand || brand === null || brand === undefined) {
                                                        brand = [];
                                                    }
                                                    if (brand.findIndex(brnd => brnd.id === activityProgram.brand.id) === -1) {
                                                        brand.push(activityProgram.brand);
                                                        this.brands.push(brand);
                                                        this.oldBrand = activityProgram.brand;
                                                    } else {
                                                        this.brands.push(brand);
                                                    }
                                                    this.brandId.push(activityProgram.brand.id);
                                                    this.brand.push(activityProgram.brand);

                                                    // Program

                                                    // Fix for activities with deleted/closed programs
                                                    if (!program || program === null || program === undefined) {
                                                        program = [];
                                                    }
                                                    if (program.findIndex(prog => prog.id === activityProgram.program.id) === -1) {
                                                        program.push(activityProgram.program);
                                                        this.brandPrograms.push(program);
                                                    } else {
                                                        this.brandPrograms.push(program);
                                                    }
                                                    this.brandProgramId.push(activityProgram.program.id);
                                                    this.brandProgram.push(activityProgram.program);

                                                    this.onBrandProgramChange(
                                                        activityProgram.program.id,
                                                        this.brandPrograms.indexOf(program)
                                                    );

                                                }, err => console.error(err),
                                                    () => {
                                                        // Numeric inputs
                                                        this.brandProgramNumberOfSites.push(activityProgram.numberOfSites);
                                                        this.brandProgramNumberOfServes.push(activityProgram.numberOfServes);
                                                        this.brandProgramSalesVolume.push(activityProgram.salesVolume);
                                                        this.brandProgramBottlesMerchandised.push(activityProgram.bottlesMerchandised);
                                                        this.brandProgramDisplaysBuilt.push(activityProgram.displaysBuilt);
                                                        this.brandProgramIncreasedVisibility.push(activityProgram.increasedVisibility);
                                                    });

                                        });
                                }
                            );
                        }

                        // Attendees
                        if (
                            this.activity.activityAttendeeList &&
                            this.activity.activityAttendeeList.length > 0
                        ) {
                            this.attendees = this.activity.activityAttendeeList.map(
                                attendee => {
                                    return {
                                        type: attendee.attendeeType.id,
                                        number: attendee.attendees
                                    };
                                }
                            );
                        }

                        // Picture
                        if (this.activity.activityFile) {
                            this.pictureResultBase64 = this.activity.activityFile.file;
                        }

                        // Comment
                        this.comment = this.activity.comment;
                    });
            } else {
                if (params.get('idUser') !== null) {
                    this.idUser = Number(params.get('idUser'));
                }
                let hour = new Date().getHours();
                let minute = this.roundTo(new Date().getMinutes(), 15);
                if (minute >= 60) {
                    minute = 0;
                    hour += 1;
                }

                this.date = {
                    year: new Date().getFullYear(),
                    month: new Date().getMonth() + 1,
                    day: new Date().getDate()
                };
                this.from = { hour: hour - 1, minute: minute };
                this.to = { hour: hour, minute: minute };
                this.addBrand();

                this.selectedOutlets.push(new OutletModel({}));

                this.updateChoices();
            }
        });
    }

    roundTo(value: number, interval: number) {
        return value % interval < interval / 2
            ? value - (value % interval)
            : value + interval - (value % interval);
    }

    updateChoices() {
        if (this.countryId) {
            this.outletService.getByCountry(this.countryId).subscribe(data => {
                this.outlets = data;
            });

            this.activityTypesService
                .getByCountry(this.countryId)
                .subscribe(data => {
                    this.activityTypes = data;
                });

            this.usersService.getActivityCategoriesByUser().subscribe(data => {
                this.activityCategories = data;
            });

            this.brandCategoryService
                .list(this.countryId)
                .subscribe(data => {
                    this.brandCategories = data;
                    this.brandCategoryLoading = false;
                });

            this.attendeesTypeService
                .getByCountry(this.countryId)
                .subscribe(data => {
                    this.attendeesTypes = data;
                });
        }
    }

    onOutletChange(outletId: number, index: number) {
        if (
            outletId &&
            !isNaN(Number(outletId)) &&
            this.selectedOutlets[index]
        ) {
            this.outletService.info(outletId).subscribe(outlet => {
                if (outlet) {
                    this.selectedOutlets[index] = outlet;
                } else {
                    this.errorHandler.showError(
                        'This Outlet does not exist anymore.'
                    );
                    this.selectedOutlets.splice(index, 1);
                }
            });
        } else {
            this.selectedOutlets[index] = new OutletModel({});
        }

        this.outletSelects.forEach(o => o.refresh());
    }

    public addOutlet() {
        this.selectedOutlets.push(new OutletModel({}));
    }

    public removeOutlet(index) {
        this.selectedOutlets.splice(index, 1);
        this.outletSelects.forEach(o => o.refresh());
    }

    onBrandCategoryChange(e, i) {
        if (e !== undefined) {
            const oldCategory = this.brandCategory[i];
            this.brandCategory[i] = this.brandCategories.find(
                x => x.id === parseInt(e, 10)
            );
            this.brandCategoryId[i] = parseInt(e, 10);
            if (this.brandCategory[i] !== oldCategory) {
                this.brandLoading = true;
                this.brandsService
                    .getByBrandCategory(this.brandCategory[i].id, this.countryId)
                    .subscribe(brands => {
                        this.brands[i] = brands;
                        this.brand[i] = undefined;
                        this.brandId[i] = null;
                        this.brandProgram[i] = undefined;
                        this.brandProgramId[i] = null;
                        this.brandLoading = false;
                    });
            }
        }
    }

    onBrandChange(e, i) {
        if (e !== undefined) {
            const id = Number.parseInt(e, 10);
            this.brand[i] = this.brands[i].find(x => x.id === id);
            this.brandId[i] = id;

            this.brandProgramLoading = true;

            if (this.oldBrand && this.oldBrand.id && this.oldBrand.id === id) {
                this.programsService
                    .getByRegionOfBrand(this.brandId[i])
                    .subscribe(programs => {
                        this.brandPrograms[i] = programs;
                        this.brandProgramLoading = false;
                    });
            } else {
                this.programsService
                    .getByBrand(this.brandId[i])
                    .subscribe(programs => {
                        this.brandPrograms[i] = programs;
                        this.brandProgramLoading = false;
                    });
            }
        }
    }

    onBrandProgramChange(e, i) {
        if (e !== undefined) {
            const id = Number.parseInt(e, 10);
            this.brandProgram[i] = this.brandPrograms[i].find(x => x.id === id);
            this.brandProgramId[i] = id;

            this.brandProgramaIsMenuListing[i]
                = this.brandProgram[i].name.toUpperCase().includes('MENU LISTING');

            this.brandProgramaIsNewListing[i]
                = this.brandProgram[i].name.toUpperCase().includes('NEW LISTING');

            this.brandProgramaIsNewContract[i]
                = this.brandProgram[i].name.toUpperCase().includes('NEW CONTRACT');

            this.brandProgramaIsHandsOn[i]
                = this.brandProgram[i].name.toUpperCase().includes('OFFTRADE HANDS ON');
        }
    }


    onBrandProgramNumberOfSitesChange(e, i, prop) {
        if (e !== undefined) {
            this[prop][i] = e;
        }
    }

    /*onActivityCategoryChange(e, i, prop) {
        if (e !== undefined) {
            this[prop][i] = e
        }
    }*/

    public addBrand() {
        this.brandCategoryId.push(null);
        this.brandCategory.push(undefined);
        this.brandId.push(null);
        this.brand.push(undefined);
        this.brandProgramId.push(null);
        this.brandProgram.push(undefined);
        this.brandProgramNumberOfSites.push(null);
        this.brandProgramNumberOfServes.push(null);
        this.brandProgramSalesVolume.push(null);
        this.brandProgramBottlesMerchandised.push(null);
        this.brandProgramDisplaysBuilt.push(null);
        this.brandProgramIncreasedVisibility.push(null);
    }

    public deleteBrand(i: number) {
        this.brandCategory.splice(i, 1);
        this.brandCategoryId.splice(i, 1);
        this.brand.splice(i, 1);
        this.brandId.splice(i, 1);
        this.brands.splice(i, 1);
        this.brandProgram.splice(i, 1);
        this.brandProgramId.splice(i, 1);
        this.brandPrograms.splice(i, 1);
    }

    public canAddBrand() {
        return (
            this.brandCategoryId &&
            this.brandCategoryId.every(bc => bc !== null)
        );
    }

    onFileChanged(event) {
        this.pictureFile = event.target.files[0];
        this.handleFileInput(event.target);
    }

    handleFileInput(target: any) {
        const file = target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            this.pictureResultBase64 = this.arrayBufferToBase64(reader.result);
        };

        reader.readAsArrayBuffer(file);
    }

    arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    onSubmit() {
        this.formSubmited = true;

        if (
            this.activityForm.valid &&
            ((this.useOutlet &&
                this.selectedOutlets.length > 0 &&
                this.selectedOutlets[0].id) ||
                !this.useOutlet)
        ) {
            let activity: ActivityModel;

            if (this.activity) {
                activity = this.activity;
            } else {
                activity = new ActivityModel({ country_id: 1 });
            }

            // Configuramos el usuario para el que se crea la actividad
            activity.user = new UserModel({ ID: this.idUser });

            activity.date = moment()
                .year(this.date.year)
                .month(this.date.month - 1)
                .date(this.date.day);
            activity.dateFrom = moment()
                .hour(this.from.hour)
                .minute(this.from.minute);
            activity.dateTo = moment()
                .hour(this.to.hour)
                .minute(this.to.minute);
            activity.outlets = this.useOutlet ? this.selectedOutlets.filter(outlet => outlet && outlet.id) : null;
            activity.activityType = this.activityTypes.find(
                at => at.id === +this.activityTypeId
            );

            const programs: BrandProgramModel[] = [];
            this.brand.forEach((b, index) => {
                programs.push(
                    new BrandProgramModel({}).fromItems(
                        this.brand[index],
                        this.brandCategory[index],
                        this.brandProgram[index],
                        this.brandProgramNumberOfSites[index],
                        this.brandProgramNumberOfServes[index],
                        this.brandProgramSalesVolume[index],
                        this.brandProgramBottlesMerchandised[index],
                        this.brandProgramDisplaysBuilt[index],
                        this.brandProgramIncreasedVisibility[index]
                    )
                );
            });

            activity.program = programs;

            activity.comment = this.comment;

            if (this.attendees && this.attendees.length > 0) {
                activity.attendees = [];
                for (let i = 0; i < this.attendees.length; i++) {
                    if (this.attendees[i] && this.attendees[i].type !== -1) {
                        activity.attendees.push(new AttendeeModel(this.attendees[i].type, this.attendees[i].number, null));
                    }
                }
            }
            if (this.pictureFile !== undefined && this.pictureFile !== null) {
                activity.picture = new PictureModel({ ID: null });
                activity.picture.data = this.pictureFile;
                activity.picture.base64Result = this.pictureResultBase64;
            }
            activity.countryId = this.countryId;
            if (this.activityCategories) {
                activity.activityCategory = this.activityCategories.find(
                    at => at.id === +this.activityCategoryId
                );
            }
            this.isFormLoading = true;
            this.activitiesService
                .createOrEdit(activity)
                .subscribe(
                    (data: any) => {
                        this.errorHandler.showSuccess(
                            'Your activity has been saved successfully',
                            5000
                        );
                        this.isFormLoading = false;
                        this.router.navigate(['/workspace/dashboard']);
                    }
                );
        } else {
            this.validateAllFormFields(this.activityForm.form);
            this.errorHandler.showError('Form contains errors');
        }
    }

    trackBy(index, item) {
        return index;
    }

    onCountryChange(event) {
        this.selectedOutlets = [];
        this.selectedOutlets.push(new OutletModel({}));

        this.brand = [];
        this.brandId = [];
        this.brandCategoryId = [];
        this.brandCategory = [];
        this.brandProgramId = [];
        this.brandProgram = [];
        this.addBrand();

        this.attendees = [];
        this.countryId = event.target.value;
        this.updateChoices();
    }

    onAttendeeTypeChange(type, i) {
        this.attendees[i].persons = [];
    }

    attendeeTypeNameFromId(id: number) {
        return this.attendeesTypes.find(a => a.id === id);
    }

    removeContact(i: number, j: number) {
        this.attendees[i].persons.splice(j, 1);
    }

    addAttendeeType() {
        this.attendees.push({
            type: -1,
            number: 1,
            persons: []
        });
    }

    removeAttendeeType(i) {
        this.attendees.splice(i, 1);
    }

    removeAttendee(i: number) {
        this.attendees[i].number = this.attendees[i].number - 1;
        if (this.attendees[i].number < 1) {
            this.attendees[i].number = 1;
        }
    }

    addAttendee(i: number) {
        this.attendees[i].number = +this.attendees[i].number + 1;
    }

    attendeesTypesRestricted(i: number) {
        return this.attendeesTypes.filter(
            a =>
                this.attendees.filter(
                    (b, index) => +a.id === +b.type && index !== +i
                ).length === 0
        );
    }

    getForm() {
        return this.activityForm.form;
    }
}
