import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormPage } from 'src/app/pages/FormPage';
import { OutletPosSegmentsService } from 'src/app/providers/outlet-pos-segments.service';
import { GlobalModel } from 'src/app/models/global.model';
import { POSSegmentModel } from 'src/app/models/pos-segment.model';
import { GlobalMessage } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-pos-segment-edit.page',
    templateUrl: './outlet-pos-segment-edit.page.html'
})
export class OutletPosSegmentEditPage extends FormPage implements OnInit
{
    public posSegmentForm = this.fb.group({
        posSegmentId: [''],
        name: ['', Validators.required],
        global: ['', Validators.required],
    });

    public posSegmentId: number = null;

    constructor(
        private fb: FormBuilder,
        public outletPosSegmentsService: OutletPosSegmentsService,
        private route: ActivatedRoute,
        private errorHandler: GlobalMessage)
    {
        super();
    }

    ngOnInit()
    {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null)
            {
                this.posSegmentId = Number.parseInt(params.get('id'), 10);
                this.outletPosSegmentsService.info(Number.parseInt(params.get('id'), 10)).subscribe(
                    (data) => {
                        this.posSegmentForm.setValue({
                            posSegmentId: data.id,
                            name: data.name,
                            global: data.posSegment ? data.posSegment.id : null
                        });
                    }
                );
            }
        });
    }

    onSubmit()
    {
        if (this.posSegmentForm.valid)
        {
            const posSegment = this.posSegmentForm.value;
            this.outletPosSegmentsService.update(new POSSegmentModel({
                id: posSegment.posSegmentId,
                name: posSegment.name,
                posSegment: new GlobalModel({id: posSegment.global.id, name: posSegment.posSegment.name})
            })).subscribe((data: any) => {
                if (data.status === 'success')
                {
                    this.errorHandler.showSuccess('Your outlet POS has been saved successfully', 5000);
                }
                else
                {
                    this.errorHandler.showError(data.error.error_msg);
                }
            });
        }
        else
        {
            this.validateAllFormFields(this.posSegmentForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.posSegmentForm;
    }
}
