import { Component, OnInit } from '@angular/core';
import saveAs from 'file-saver';
import * as moment from 'moment';
import { AdminService } from 'src/app/providers/admin.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from '../../../providers/user.service';
import { UserRoleConstants } from '../../../shared/constants/user-role.constants';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.page.html'
})
export class SettingsPage implements OnInit
{
    public readonly UserRoleConstants = UserRoleConstants;

    public showSuccess = false;
    public importId: number = null;
    public importedFile: any = null;

    constructor(public userService: UserService, private adminService: AdminService, private ngxService: NgxUiLoaderService) { }

    ngOnInit() {
    }

    public exportConfig()
    {
        this.ngxService.start();
        this.adminService.getExportConfig().subscribe(fileContent => {
            const blob = new Blob([fileContent], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'export-config-' + moment().format('Y-M-D').toString() + '.xlsx');
            this.ngxService.stop();
        }, () => {
            this.ngxService.stop();
        });
    }

    onFileChanged(event)
    {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0)
        {
            this.ngxService.start();
            this.adminService.importConfig(fileList[0]).subscribe( (data: any) => {
                if (data.status === 'success')
                {
                    this.importedFile = data.data.programs;
                    this.importId = data.data.db_import_id;
                }
                this.ngxService.stop();
            }, () => {
                this.ngxService.stop();
            });
        }
    }

    confirmImportation()
    {
        this.ngxService.start();
        this.adminService.confirmImportConfig(this.importId).subscribe((data: any) => {
            if (data.status === 'success')
            {
                this.showSuccess = true;
                setTimeout(() => this.showSuccess = false, 5000);
                this.importId = null;
                this.importedFile = null;
            }
            this.ngxService.stop();
        }, () => {
            this.ngxService.stop();
        });
    }
}
