import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { TargetsApiService } from './targets-api.service';
import { TargetModel } from '../models/target.model';
import { TargetTypeModel } from '../models/target-type.model';
import { UsersService } from './users.service';
import { ActivityTypesService } from './activity-types.service';
import { BrandsService } from './brands.service';
import { ProgramsService } from './programs.service';

@Injectable({
    providedIn: 'root'
})
export class TargetsService {
    constructor(
        private targetsApi: TargetsApiService,
        private usersService: UsersService,
        private activityTypesService: ActivityTypesService,
        private brandsService: BrandsService,
        private programsService: ProgramsService
    ) { }

    public list(ignoreValidity = false, loadManagerUsers = false, countryId = null) {
        const targets: TargetModel[] = [];
        return this.usersService.list(null).pipe(
            flatMap(users => {
                return this.activityTypesService.search().pipe(
                    flatMap(activityTypes => {
                        return this.brandsService.search().pipe(
                            flatMap((brands: any) => {
                                return this.programsService.list().pipe(
                                    flatMap((programs: any) => {
                                        return this.targetsApi.list(ignoreValidity, loadManagerUsers, countryId).pipe(
                                            map(l => {
                                                if (l.data) {
                                                    l.data.forEach(element => {
                                                        const t = new TargetModel(element);
                                                        t.user = users.find(u => u.id === t.user.id);

                                                        const activityType = activityTypes.find(a => a.id === t.activityType.id);
                                                        if (activityType) {
                                                            t.activityType = activityType.clone();
                                                        }

                                                        const brand = brands.find(b => b.id === t.brand.id);
                                                        if (brand) {
                                                            t.brand = brand.clone();
                                                        }

                                                        const program = programs.find(p => p.id === t.program.id);
                                                        if (program) {
                                                            t.program = program.clone();
                                                        }
                                                        targets.push(t);
                                                    });
                                                }
                                                return targets;
                                            })
                                        );
                                    })
                                );
                            })
                        );
                    })
                );
            })
        );
    }

    public info(targetId: number): Observable<TargetModel> {
        return this.targetsApi.info(targetId).pipe(
            map(res => new TargetModel(res))
        );
    }

    public delete(target: TargetModel) {
        return this.targetsApi.delete(target.id);
    }

    public createOrEdit(target: TargetModel) {
        return this.targetsApi.createOrEdit(target);
    }

    public getTypes() {
        const target_types: TargetTypeModel[] = [];
        return this.targetsApi.getTypes().pipe(
            map(t => {
                if (t.data) {
                    t.data.forEach(element => target_types.push(new TargetTypeModel(element)));
                }
                return target_types;
            })
        );
    }
}
