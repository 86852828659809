import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CountryModel } from '../models/country.model';
import { AdminApiService } from './admin-api.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService
{
    constructor(
        private adminApi: AdminApiService
    ) { }

    public getExportConfig()
    {
        return this.adminApi.getExportConfig();
    }

    public importConfig(file: File)
    {
        return this.adminApi.importConfig(file);
    }

    public confirmImportConfig(importId: number)
    {
        return this.adminApi.confirmImportConfig(importId);
    }

    public getCountries()
    {
        const countries: CountryModel[] = [];
        return this.adminApi.getCountries().pipe(
            map( l => {
                if (l)
                {
                    l.forEach( element => countries.push(new CountryModel(element)));
                }
                return countries;
            })
        );
    }

    public searchCountries() {
        const countries: CountryModel[] = [];
        return this.adminApi.searchCountries().pipe(
            map( l => {
                if (l.content)
                {
                    l.content.forEach( element => countries.push(new CountryModel(element)));
                }
                return countries;
            })
        );
    }
}
