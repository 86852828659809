import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GlobalModel } from 'src/app/models/global.model';
import { GlobalId } from 'src/app/providers/global-id.interface';
import { GlobalMessage } from '../../providers/global-message.service';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GlobalSelectComponent),
    multi: true,
};
@Component({
    selector: 'app-global-select',
    templateUrl: './global-select.component.html',
    providers: [CUSTOM_VALUE_ACCESSOR]
})
export class GlobalSelectComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    @Input() globalService: GlobalId;
    @Input() canCreate = true;

    @Output() change = new EventEmitter<GlobalModel>();

    public disabled = false;
    public value: string = null;
    public globals: GlobalModel[];
    public creatingValue: string = null;

    private onChange: Function = (_: any) => { };
    private onTouched: Function = () => { };

    constructor(
        private cdr: ChangeDetectorRef,
        private errorHandler: GlobalMessage) { }

    ngOnInit() {
        this.globalService.getGlobals().subscribe(data => {
            this.globals = data;
            this.cdr.detectChanges();

            const selectizeObj = $('#' + this.inputId)['selectize']({
                placeholder: 'Select ' + ((this.canCreate) ? '/ Create ' : '') + 'a global reference',
                persist: false,
                create: (this.canCreate) ? (input) => {
                    if (this.creatingValue) {
                        selectizeObj[0].selectize.removeOption(this.creatingValue);
                    }
                    this.creatingValue = input;
                    return { value: input, text: input };
                } : false,
            });

            selectizeObj[0].selectize.on('change', (value) => {
                this.createGlobalObject(value);
            });
        });
    }

    createGlobalObject(value) {
        let valueNumber = Number(value);
        if (isNaN(valueNumber)) {
            const globalObj = {
                name: value
            };
            // Llamada al back que devuelve el id del Global creado
            this.globalService.createGlobal(globalObj).subscribe(
                res => {
                    if (res) {
                        valueNumber = res.id;
                        this.globals.push(res);
                        this.setGlobalObject(valueNumber);
                    }
                },
                error => {
                    this.errorHandler.showError('Error adding the new global reference.');
                }
            );
        }
        else {
            this.setGlobalObject(valueNumber);
        }

    }

    setGlobalObject(valueNumber) {
        const global = this.globals.find(g => g.id === valueNumber);
        this.emitChangeValue(new GlobalModel({ id: valueNumber, name: global.name }));
    }

    writeValue(obj: any): void {
        if (typeof obj === 'object' && obj) {
            this.value = obj.id;
        }
        else {
            this.value = obj;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    globalChange(ev) {
        this.createGlobalObject(ev);
    }

    emitChangeValue(value) {
        this.value = value;
        this.onChange(value);
        this.onTouched();
        this.change.emit(value);
    }
}
