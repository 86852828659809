import { Component, OnInit } from '@angular/core';
import { TargetsService } from 'src/app/providers/targets.service';
import { TargetModel } from 'src/app/models/target.model';
import { TargetTypeModel } from 'src/app/models/target-type.model';

@Component({
    selector: 'app-target-dashboard',
    templateUrl: './target-dashboard.component.html'
})
export class TargetDashboardComponent implements OnInit
{
    public targets: TargetModel[];
    public targetsType: TargetTypeModel[];

    constructor(
        private targetsService: TargetsService
    ) { }

    ngOnInit() {
        this.targetsService.list().subscribe(data => {
            this.targets = [... data].sort((a, b) => a.to.diff(b.to, 'seconds')).slice(0, 5);
        });

        this.targetsService.getTypes().subscribe(data => this.targetsType = data);
    }

    getTargetTypName(targetId: number)
    {
        if (targetId)
        {
            const target = this.targetsType.find(type => type.id === targetId);
            if (target)
            {
                return target.name;
            }
        }
    }
}
