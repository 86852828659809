import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { ContactHobbyModel } from '../models/contact-hobby.model';

@Injectable({
    providedIn: 'root'
})
export class ContactHobbiesApiService {
    private endpointRegion = 'hobbiesregion';
    private endpoint = 'hobbies';
    private requestHeader = {
        headers: { Authorization: this.user.token }
    };

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public search(size?: number, page?: number) {
        const body = {
            'searchStr': ''
        };
        const params = { size: size, page: page };

        return this.api.post(this.endpointRegion
            + '/search/'
            + this.user.user.id, body, { headers: { Authorization: this.user.token }, params: params })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public info(contactHobbyId: number) {
        return this.api.get(this.endpoint + '/' + contactHobbyId.toString(), {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res.data;
                    }
                )
            );
    }

    public delete(contactHobbyId: number) {
        return this.api.patch(this.endpointRegion
            + '/delete/'
            + contactHobbyId.toString(), {}, { headers: { Authorization: this.user.token }, observe: 'response' });
    }

    public createOrEdit(contactHobby: ContactHobbyModel) {
        let contactHobbyObject: any;
        const url = this.endpointRegion + '/save';
        if (contactHobby.id) {
            contactHobbyObject = {
                id: contactHobby.id,
                name: contactHobby.name,
                region: {
                    id: contactHobby.region.id,
                    code: contactHobby.region.code,
                    name: contactHobby.region.name,
                },
                hobbyGlobal: {
                    id: contactHobby.hobbyGlobal.id,
                    hobby: contactHobby.hobbyGlobal.name,
                }
            };
        } else {
            contactHobbyObject = {
                name: contactHobby.name,
                region: {
                    id: contactHobby.region.id,
                    code: contactHobby.region.code,
                    name: contactHobby.region.name,
                },
                hobbyGlobal: {
                    id: contactHobby.hobbyGlobal.id,
                    hobby: contactHobby.hobbyGlobal.name,
                }
            };
        }
        return this.api.post(url, contactHobbyObject, { headers: { Authorization: this.user.token } })
            .pipe(
                map(res => {
                    return res;
                })
            );
    }

    public getGlobals() {
        const params = {};

        return this.api.get(this.endpoint + '/list', params, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        // FRISTMY cambiar la etiqueta hobby por name
                        if (res) {
                            res.map((x: any) => {
                                x.name = x.hobby;
                                delete x.hobby;
                            });
                        }
                        else {
                            res = null;
                        }
                        return res;
                    }
                )
            );
    }

    public createGlobal(global) {

        const globalHobbie = {
            hobby: global.name
        };

        return this.api.post(`${this.endpoint}/add`, globalHobbie, this.requestHeader);
    }
}
