import { Component, OnInit } from '@angular/core';
import { AttendeeContactModel } from 'src/app/models/attendee-contact.model';
import { ContactsService } from 'src/app/providers/contacts.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-contact-view-page',
    templateUrl: './contact-view.page.html'
})
export class ContactViewPage implements OnInit {

    public contact: AttendeeContactModel;

    constructor(
        private contactService: ContactsService,
        private route: ActivatedRoute
    )
    {
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null)
            {
                this.contactService.info(Number.parseInt(params.get('id'), 10)).subscribe(data => this.contact = data);
            }
        });
    }

}
