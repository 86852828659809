import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { ActivitySegmentModel } from 'src/app/models/activity-segment.model';
import { ActivityTypeModel } from 'src/app/models/activity-type.model';
import { BrandModel } from 'src/app/models/brand.model';
import { ClusterModel } from 'src/app/models/cluster.model';
import { CountryModel } from 'src/app/models/country.model';
import { POSSegmentModel } from 'src/app/models/pos-segment.model';
import { ProgramModel } from 'src/app/models/program.model';
import { RegionModel } from 'src/app/models/region.model';
import { StatisticsFilterModel } from 'src/app/models/statistics-filter.model';
import { StatisticsTypeModel } from 'src/app/models/statistics-type.model';
import { UserModel } from 'src/app/models/user.model';
import { WinningInModel } from 'src/app/models/winning-in.model';
import { BrandsService } from 'src/app/providers/brands.service';
import { CountriesService } from 'src/app/providers/countries.service';
import { OutletActivitySegmentsService } from 'src/app/providers/outlet-activity-segments.service';
import { ProgramsService } from 'src/app/providers/programs.service';
import { StatisticsService } from 'src/app/providers/statistics.service';
import { UserService } from 'src/app/providers/user.service';
import { UsersService } from 'src/app/providers/users.service';
import { ActivityTypesService } from '../../providers/activity-types.service';
import { OutletPosSegmentsService } from '../../providers/outlet-pos-segments.service';
import { OutletWinningInApiService } from '../../providers/outlet-winning-in-api.service';
import { UserRoleConstants } from 'src/app/shared/constants/user-role.constants';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StatisticsFilterComponent),
    multi: true,
};

@Component({
    selector: 'app-statistics-filter',
    templateUrl: './statistics-filter.component.html',
    styleUrls: ['./statistics-filter.component.scss'],
    providers: [CUSTOM_VALUE_ACCESSOR]
})
export class StatisticsFilterComponent implements ControlValueAccessor, OnInit {

    private readonly ANY_VALUE = undefined;

    @Input() inputId: string;
    public statisticTypes: StatisticsTypeModel[];
    public filter: StatisticsFilterModel = new StatisticsFilterModel();
    public users: UserModel[];
    public activityTypes: ActivityTypeModel[] = [];
    public regions: RegionModel[] = [];
    public clusters: ClusterModel[] = [];
    public countries: CountryModel[] = [];
    public winningIns: WinningInModel[] = [];
    public posSegments: any[] = [];
    public activitySegments: any[] = [];
    public brands: BrandModel[] = [];
    public programs: ProgramModel[] = [];
    private user: UserModel;

    private checkedUsers: number[] = [];
    public from: any;
    public to: any;
    public activityTypeId: number = this.ANY_VALUE;
    public regionId: number = this.ANY_VALUE;
    public clusterId: number = this.ANY_VALUE;
    public countryId: number = this.ANY_VALUE;
    public winningInId: number = this.ANY_VALUE;
    public posSegmentId: number = this.ANY_VALUE;
    public activitySegmentId: number = this.ANY_VALUE;
    public brandId: number = this.ANY_VALUE;
    public programId: number = this.ANY_VALUE;

    private onChange: Function = (_: any) => { };
    private onTouched: Function = () => { };

    constructor(
        private statisticsService: StatisticsService,
        private userService: UserService,
        private usersService: UsersService,
        private activityTypesService: ActivityTypesService,
        private countriesService: CountriesService,
        private outletWinningInApiService: OutletWinningInApiService,
        private outletActivitySegmentService: OutletActivitySegmentsService,
        private outletPosSegmentsService: OutletPosSegmentsService,
        private brandsService: BrandsService,
        private programsService: ProgramsService
    ) { }

    ngOnInit() {
        this.statisticsService.list().subscribe(types => {
            this.statisticTypes = (types as any[]).map(
                type => {
                    return new StatisticsTypeModel(type);
                }
            );

            if (this.filter === undefined) {
                this.filter = new StatisticsFilterModel();
            }
            if (this.filter.type === undefined) {
                this.filter.type = this.statisticTypes[0];
                this.propagateChange(this.filter);
            }
        });

        this.user = this.userService.user;

        if (this.user.role.id !== UserRoleConstants.AMBASSARDOR) {
            this.usersService.listUsers().subscribe(users => {
                this.users = users;
                this.users.forEach(u => {
                    this.toggleUser(u.id);
                });
            });
        }

        if (this.user.isAdmin) {
            this.countriesService.getRegions().subscribe(
                regions => {
                    this.regions = [new RegionModel({ id: this.ANY_VALUE, code: '', name: 'Any' }), ...regions];
                }
            );
            this.countriesService.getClusters()
                .subscribe(
                    clusters => this.clusters = [new ClusterModel({ id: this.ANY_VALUE, name: 'Any' }), ...clusters]
                );
        }

        this.activityTypesService.list().subscribe(
            activityTypes => this.activityTypes = [new ActivityTypeModel({ id: this.ANY_VALUE, name: 'Any' }), ...activityTypes]
        );
        this.countriesService.search().subscribe(
            countries => this.countries = [new CountryModel({ id: this.ANY_VALUE, name: 'Any' }), ...countries.content]
        );
        this.brandsService.list().subscribe(
            (data: any) => this.brands = [new BrandModel({ id: this.ANY_VALUE, name: 'Any' }), ...data]
        );

    }

    private propagateChange = (_: any) => { };

    writeValue(obj: StatisticsFilterModel): void {
        if (obj !== undefined && obj !== null) {
            this.filter = obj;
        }
    }

    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    public registerOnTouched() { }

    statisticsTypeChange(ev) {
        if (this.filter === undefined || this.filter === null) {
            this.filter = new StatisticsFilterModel();
        }
        this.filter = this.filter.clone();
        this.filter.type = this.statisticTypes[ev.target.selectedIndex];
        this.propagateChange(this.filter);
    }

    onActivityTypeChange(value) {
        this.filter = this.filter.clone();
        this.filter.activityType = this.activityTypes.find(a => a.id === parseInt(value, 10));
        this.propagateChange(this.filter);
    }

    onFromChange(value) {
        this.filter.from = null;
        if (value && (typeof value === 'object' || moment(value, 'YYYY-MM-DD', true).isValid())) {
            this.filter = this.filter.clone();
            this.filter.from = moment().year(value.year).month(value.month - 1).date(value.day);
        }
        this.propagateChange(this.filter);
    }

    onToChange(value) {
        this.filter.to = null;
        if (value && (typeof value === 'object' || moment(value, 'YYYY-MM-DD', true).isValid())) {
            this.filter = this.filter.clone();
            this.filter.to = moment().year(value.year).month(value.month - 1).date(value.day);
        }
        this.propagateChange(this.filter);
    }

    onRegionChange(value) {
        this.filter = this.filter.clone();
        if (value) {
            this.filter.region = this.regions.find(r => r.id === parseInt(value, 10));
        }
        this.propagateChange(this.filter);
    }

    onClusterChange(value) {
        this.filter = this.filter.clone();
        if (value) {
            this.filter.cluster = this.clusters.find(c => c.id === parseInt(value, 10));
        }
        this.propagateChange(this.filter);
    }

    onCountryChange(value) {
        this.filter = this.filter.clone();
        this.winningInId = this.ANY_VALUE;
        this.posSegmentId = this.ANY_VALUE;
        this.activitySegmentId = this.ANY_VALUE;
        if (value === null || value === 'null') {
            this.winningIns.length = 0;
            this.posSegments.length = 0;
            this.activitySegments.length = 0;
            this.filter.country = undefined;
        }
        else {
            this.outletWinningInApiService.getWinningIns(value, 999999).subscribe(
                winningIns => this.winningIns = [new WinningInModel({ id: this.ANY_VALUE, city: 'Any' }), ...winningIns.content]);
            this.outletPosSegmentsService.getGlobals()
                .subscribe(
                    posSegments => this.posSegments = [new POSSegmentModel({ id: this.ANY_VALUE, name: 'Any' }), ...posSegments]
                );
            this.outletActivitySegmentService.getGlobals().subscribe(
                activitySegments => this.activitySegments = [new ActivitySegmentModel({ id: this.ANY_VALUE, name: 'Any' }),
                ...activitySegments]);
            this.filter.country = this.countries.find(c => c.id === parseInt(value, 10));
        }
        this.propagateChange(this.filter);
    }

    onWinningInChange(value) {
        this.filter = this.filter.clone();
        if (value) {
            this.filter.winningIn = this.winningIns.find(w => w.id === parseInt(value, 10));
        }
        this.propagateChange(this.filter);
    }

    onPosSegmentChange(value) {
        this.filter = this.filter.clone();
        if (value) {
            this.filter.posSegment = this.posSegments.find(p => p.id === parseInt(value, 10));
        }
        this.propagateChange(this.filter);
    }

    onActivitySegmentChange(value) {
        this.filter = this.filter.clone();
        if (value) {
            this.filter.activitySegment = this.activitySegments.find(a => a.id === parseInt(value, 10));
        }
        this.propagateChange(this.filter);
    }

    onBrandChange(value) {
        this.filter = this.filter.clone();
        this.programId = this.ANY_VALUE;
        if (value === null || value === 'null') {
            this.programs.length = 0;
            this.filter.brand = undefined;
            this.filter.program = undefined;
        }
        else {
            this.programsService.getGlobals().subscribe(
                (programs: any) => this.programs = [new ProgramModel({ id: this.ANY_VALUE, name: 'Any', brandRegion: null }), ...programs]);
            this.filter.brand = this.brands.find(b => b.id === parseInt(value, 10));
        }
        this.propagateChange(this.filter);
    }

    onProgramChange(value) {
        this.filter = this.filter.clone();
        if (value) {
            this.filter.program = this.programs.find(p => p.id === parseInt(value, 10));
        }
        this.propagateChange(this.filter);
    }

    toggleUser(id: number) {
        const index = this.checkedUsers.findIndex(v => v === id);
        if (index > -1) {
            this.checkedUsers.splice(index, 1);
        }
        else {
            this.checkedUsers.push(id);
        }
        this.filter = this.filter.clone();
        this.filter.users = this.checkedUsers;
        this.propagateChange(this.filter);
    }

    toggleAll(e) {
        if (e.target.checked) {
            this.checkedUsers = this.users.map(u => u.id);
        }
        else {
            this.checkedUsers.length = 0;
        }
        this.filter = this.filter.clone();
        this.filter.users = this.checkedUsers;
        this.propagateChange(this.filter);
    }

    isUserChecked(id: number): boolean {
        return (this.checkedUsers.findIndex(v => v === id) > -1);
    }
}
