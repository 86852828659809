import { AfterViewInit, Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttendeeContactModel } from 'src/app/models/attendee-contact.model';
import { AttendeeTypeModel } from 'src/app/models/attendee-type.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { AttendeesTypesService } from 'src/app/providers/attendees-types.service';
import { ContactsService } from 'src/app/providers/contacts.service';
import { UserService } from 'src/app/providers/user.service';

@Component({
    selector: 'app-contacts',
    templateUrl: './contact-list.page.html'
})
export class ContactListPage extends DatatablePage implements OnInit, AfterViewInit
{
    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;

    public attendeeTypes: AttendeeTypeModel[] = [];
    private typeFilter: number = null;
    public contactsFiltered: AttendeeContactModel[];
    private contacts: AttendeeContactModel[];

    constructor(
        private contactService: ContactsService,
        private attendeeService: AttendeesTypesService,
        private modalService: NgbModal,
        private userService: UserService
    )
    {
        super();
    }

    ngOnInit() {
        this.attendeeService.search().subscribe(data => {
            this.attendeeTypes = data;
        });
    }

    ngAfterViewInit() {
        this.refreshList();
        this.refreshDt();
    }

    refreshList() {
        if (this.userService.user.isAdmin)
        {
            this.contactService.list().subscribe(data => {
                this.saveAndUpdate(data);
            });
        }
        else
        {
            this.contactService.ownedByUser().subscribe(data => {
                this.saveAndUpdate(data);
            });
        }
    }

    private saveAndUpdate(data: any): void {
        this.contacts = data;
        this.contactsFiltered = data;
        this.updateFilters();
    }

    delete(content, contactId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result)
            {
                this.contactService.delete(this.contacts.find( a => a.id === contactId)).subscribe(
                    x => {
                        this.refreshList();
                    }
                );
            }

        }, (reason) => {
        });
    }

    getAttendeeTypeName(attendeeTypeId: number)
    {
        const attendeeType = this.attendeeTypes.find(value => value.id === attendeeTypeId);
        return (attendeeType) ? attendeeType.name : '';
    }

    setFilterType(e)
    {
        const val = e.target.value;
        this.typeFilter = (val) ? Number.parseInt(val, 10) : null;
        this.updateFilters();
    }

    updateFilters()
    {
        this.contactsFiltered = this.contacts
            .filter(attendeeContact => {
                return attendeeContact.attendeesType.id === this.typeFilter || !this.typeFilter;
            });

        this.reRenderDt();
    }
}
