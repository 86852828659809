import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TargetModel } from '../models/target.model';
import { ApiService } from './api.service';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class TargetsApiService {
    private endpointMulti = 'targets';
    private endpointSingle = 'target';

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public list(ignoreValidity = false, loadManagerUsers = false, countryId = null)
    {
        const params = {};
        if (ignoreValidity)
        {
            params['ignore_validity'] = 1;
        }

        if (loadManagerUsers)
        {
            params['load_manager_users'] = 1;
        }

        if (countryId && countryId !== 'null')
        {
            params['country_id'] = countryId;
        }

        return this.api.get(this.endpointMulti, params, {headers: {Authorization: this.user.token}})
        .pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }

    public info(targetId: number)
    {
        return this.api.get(this.endpointSingle + '/' + targetId.toString(), {}, {headers: {Authorization: this.user.token}})
        .pipe(
            map(
                (res: any) => {
                    return res.data;
                }
            )
        );
    }

    public delete(targetId: number)
    {
        return this.api.delete(this.endpointSingle + '/' + targetId.toString(), {}, {headers: {Authorization: this.user.token}});
    }

    public createOrEdit(target: TargetModel)
    {
        const targetObject = {
            user_id: (target.user && target.user.id) ? target.user.id : null,
            name: target.name,
            target_type_id: (target.targetType && target.targetType.id) ? target.targetType.id : null,
            target_value: target.value,
            target_from: target.from,
            target_to: target.to,
            comment: target.comment,
            country_id: (target.country && target.country.id) ? target.country.id : null
        };

        if (target.activityType && target.activityType.id)
        {
            targetObject['activity_type_id'] = target.activityType.id;
        }

        if (target.brand && target.brand.id)
        {
            targetObject['brand_id'] = target.brand.id;
        }

        if (target.program && target.program.id)
        {
            targetObject['program_id'] = target.program.id;
        }

        if (target.winningIn && target.winningIn.id)
        {
            targetObject['winning_in_id'] = target.winningIn.id;
        }

        let url = this.endpointSingle;
        if (target.id)
        {
            url = url + '/' + target.id.toString();
        }

        return this.api.post(url, targetObject, {headers: {Authorization: this.user.token}});
    }

    public getTypes()
    {
        return this.api.get('target_types', {}, {headers: {Authorization: this.user.token}})
        .pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }
}
