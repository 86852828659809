import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountryModel } from 'src/app/models/country.model';
import { CountriesService } from '../../providers/countries.service';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CountryByUserComponent),
    multi: true,
};
@Component({
    selector: 'app-country-by-user',
    templateUrl: './country-by-user.component.html',
    providers: [CUSTOM_VALUE_ACCESSOR],
})
export class CountryByUserComponent
    implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    @Input() canChooseAll = true;

    public disabled = false;
    public value: number;
    public countries: CountryModel[];

    private onChange: Function = (_: any) => {};
    private onTouched: Function = () => {};

    constructor(
        private countryService: CountriesService
    ) {}

    ngOnInit() {
        this.countryService.search().subscribe((data) => {
            this.countries = data != null ? data.content : null;
        });
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public countryChange(ev) {
        this.value = Number.parseInt(ev.target.value, 10);
        this.onChange(this.value);
        this.onTouched();
    }
}
