import { Component, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormPage } from 'src/app/pages/FormPage';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { UserService } from 'src/app/providers/user.service';
import { UsersService } from 'src/app/providers/users.service';
import { UserRoleConstants } from '../../../../../shared/constants/user-role.constants';

@Component({
    selector: 'app-user-edit.page',
    templateUrl: './user-edit.page.html'
})
export class UserEditPage extends FormPage implements OnInit {
    public readonly UserRoleConstants = UserRoleConstants;
    public userId: number;
    public defaultCountryId: number = null;
    public managerRequired: boolean;
    public cancelRouterLink = '/workspace/users';
    public managerName: string;

    public userForm = this.fb.group({
        userId: [''],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.email, Validators.required]],
        languageId: ['', Validators.required],
        roleId: ['', Validators.required],
        managerId: [''],
        isAdmin: [false],
        isActive: [false],
        countries: ['', Validators.required],
        sendMail: [false]
    });

    public editedUser: any;

    constructor(
        private fb: FormBuilder,
        private usersService: UsersService,
        public userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private errorHandler: GlobalMessage
    ) {
        super();
        if (!(this.userService.user.isAdmin || this.userService.user.isManager)) {
            // Los Ambassadors deben redirigirse a Dashboard
            this.cancelRouterLink = '/workspace/dashboard';
        }
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null) {
                this.userId = Number.parseInt(params.get('id'), 10);

                this.userForm.get('firstName').disable();
                this.userForm.get('lastName').disable();
                this.userForm.get('email').disable();
                if (this.userService.user.role.id === UserRoleConstants.MANAGER) {
                    this.userForm.get('managerId').disable();
                }
                if (this.userId === this.userService.user.id) {
                    this.userForm.get('managerId').disable();
                    this.userForm.get('isActive').disable();
                    this.userForm.get('sendMail').disable();
                }
                this.usersService
                    .info(Number.parseInt(params.get('id'), 10))
                    .subscribe(data => {
                        this.editedUser = data;
                        if (data.manager) {
                            this.managerName = data.manager.firstName + ' ' + data.manager.lastName;
                        }
                        this.userForm.setValue({
                            userId: data.id,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            email: data.email,
                            languageId: data.language ? data.language : null,
                            roleId: data.rol ? data.rol.id : 0,
                            managerId: data.manager ? data.manager.id : '',
                            isAdmin: (data.rol && data.rol.id) ?
                                (data.rol.id !== UserRoleConstants.AMBASSARDOR && data.rol.id !== UserRoleConstants.MANAGER) :
                                false,
                            isActive: data.isActive,
                            sendMail: false,
                            countries: data.userCountries.map(x => {
                                return x.country.id;
                            })
                        });
                        this.defaultCountryId = data.userCountries
                            .filter(x => x.defaultCountry)
                            .map(x => x.userCountryKey.country.id)
                            .pop();
                    });
            } else {
                if (this.userService.user.language) {
                    this.userForm.patchValue({
                        languageId: this.userService.user.language.id
                    });
                }
            }
        });
    }

    onSubmit() {
        if (this.userForm.valid) {
            const user = this.userForm.value;
            let userCreate;
            const countriesObj = [];
            user.countries.forEach(v => {
                const userCountryKey = {
                    country: {
                        id: v
                    }
                };
                if (v === this.defaultCountryId) {
                    countriesObj.push({
                        userCountryKey: userCountryKey,
                        defaultCountry: true
                    });
                } else {
                    countriesObj.push({
                        userCountryKey: userCountryKey,
                        defaultCountry: false
                    });
                }
            });

            userCreate = {
                id: user.userId,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                language: user.languageId,
                rol: { id: user.roleId },
                manager: this.userForm.controls['managerId'].value !== '' ? { id: this.userForm.controls['managerId'].value } : null,
                isAdmin: this.userForm.controls['isAdmin'].value,
                isActive: this.userForm.controls['isActive'].value,
                userCountries: countriesObj,
                sendMail: this.userForm.controls['sendMail'].value
            };

            this.usersService
                .createOrEdit(userCreate)
                .subscribe((result: any) => {
                    if (userCreate.sendMail && result.id) {
                        this.usersService
                            .sendInvitation(result.id)
                            .subscribe((a: any) => {
                                if (a) {
                                    this.errorHandler.showSuccess(
                                        'Invitation was sent'
                                    );
                                    if (this.userService.user.isAdmin || this.userService.user.isManager) {
                                        this.router.navigate(['/workspace/users']);
                                    } else {
                                        // Los Ambassadors deben redirigirse a Dashboard
                                        this.router.navigate(['/workspace/dashboard']);
                                    }
                                } else {
                                    this.errorHandler.showError(
                                        'Invitation contains errors'
                                    );
                                }
                            });
                        this.userForm.patchValue({ sendMail: false });
                    } else {
                        if (result) {
                            this.errorHandler.showSuccess(
                                'Your user has been saved successfully',
                                5000
                            );
                            if (this.userService.user.isAdmin || this.userService.user.isManager) {
                                this.router.navigate(['/workspace/users']);
                            } else {
                                // Los Ambassadors deben redirigirse a Dashboard
                                this.router.navigate(['/workspace/dashboard']);
                            }
                        } else {
                            this.errorHandler.showError('Form contains errors');
                        }
                    }
                });
        } else {
            this.validateAllFormFields(this.userForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.userForm;
    }

    defaultCountrySelected(countryId) {
        this.defaultCountryId = countryId;
    }

    onRoleChange(event) {
        // Role Ambassador
        if (event.target.value === 1) {
            this.userForm.controls['managerId'].setValidators([
                Validators.required
            ]);
            this.userForm.updateValueAndValidity();
            this.managerRequired = true;
        } else {
            this.userForm.controls['managerId'].clearValidators();
            this.userForm.updateValueAndValidity();
            this.managerRequired = false;
        }

        if (event.target.value === 1 || event.target.value === 2) {
            this.userForm.controls['isAdmin'].setValue(false);
        } else {
            this.userForm.controls['isAdmin'].setValue(true);
        }
    }
}
