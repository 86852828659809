import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ActivitySegmentModel } from '../models/activity-segment.model';
import { ApiService } from './api.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class OutletActivitySegmentsApiService {
    private endpointMulti = 'segmentsCountry/search';
    private endpointSingle = 'segmentsCountry';
    private endpointSegments = 'segments';
    private requestHeader: any = {
        headers: { Authorization: this.user.token }
    };

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public list(countryId: string, size?: number, page?: number) {
        const body = { country: countryId, searchStr: '' };
        const params = { size: size, page: page };

        return this.api.post(this.endpointMulti, body, { headers: { Authorization: this.user.token }, params: params })
            .pipe(
                map(
                    (res: any) => res
                )
            );
    }

    public search(countryId: number) {
        return this.api.post(this.endpointSingle
            + '/search?size=99999&page=undefined&sort=name,ASC&sort=id,ASC',
            { country: countryId },
            { headers: { Authorization: this.user.token } });
    }

    public info(activitySegmentId: number) {
        const params = {};

        return this.api.get(this.endpointSingle + '/' + activitySegmentId.toString(),
            params, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public update(activitySegment: ActivitySegmentModel) {
        const activitySegmentObject = {
            country: { id: activitySegment.country.id },
            createDate: activitySegment.createDate,
            createdBy: activitySegment.createdBy,
            deleted: false,
            name: activitySegment.name,
            segment: { id: activitySegment.segment.id },
            selectable: true,
            updatedBy: activitySegment.updatedBy
        };

        return this.api.put(
            this.endpointSingle + '/edit/' + activitySegment.id.toString(),
            activitySegmentObject,
            { headers: { Authorization: this.user.token } }
        );
    }

    public getGlobals() {
        const params = {};

        return this.api.get(`${this.endpointSegments}/list`, params, { headers: { Authorization: this.user.token } })
            .pipe(
                map((res: any) => res)
            );
    }

    public createGlobal(global) {

        return this.api.post(`${this.endpointSegments}/add`, global, this.requestHeader);
    }
}
