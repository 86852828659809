import { AfterViewInit, Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from 'src/app/models/user.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { UsersService } from 'src/app/providers/users.service';
import { UserService } from 'src/app/providers/user.service';

@Component({
    selector: 'app-users',
    templateUrl: './user-list.page.html',
    styleUrls: ['./user-list.page.css']
})
export class UserListPage extends DatatablePage implements OnInit, AfterViewInit {
    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    public currentUser: UserModel;
    public users: UserModel[];
    private params = {
        show: this.userStatus,
        searchStr: null,
    };
    private _userStatus = 'ALL'; // 'ALL' | 'DELETED' | 'NOTDELETED'

    constructor(
        private usersService: UsersService,
        private userService: UserService,
        private modalService: NgbModal
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.setDtOption('pageLength', 25);
        this.currentUser = this.userService.user;

        if (sessionStorage.getItem('userStatus')) {
            this.userStatus = sessionStorage.getItem('userStatus');
        }
        this.params.show = this.userStatus;

        this.dtOptions.pagingType = 'full_numbers';
        this.dtOptions.serverSide = true;
        this.dtOptions.processing = true;
        this.dtOptions.columnDefs = [
            { 'orderable': false, 'targets': 2 }
        ];

        this.dtOptions.ajax = (dataTablesParameters: any, callback) => {

            this.params.searchStr = dataTablesParameters.search.value;

            const paramTablet = {
                resultsNum: dataTablesParameters.length,
                offset: dataTablesParameters.start,
                orderBy: dataTablesParameters.order[0].column,
                order: dataTablesParameters.order[0].dir
            };

            this.usersService.list(this.params, paramTablet).subscribe(
                (data: any) => {
                    if (data && data.content) {
                        this.users = data.content;
                        if (data.totalElements) {
                            callback(
                                {
                                    recordsTotal: data.totalElements,
                                    recordsFiltered: data.totalElements,
                                    data: []
                                }
                            );
                        }
                    } else {
                        callback(
                            {
                                recordsTotal: 0,
                                recordsFiltered: 0,
                                data: []
                            }
                        );
                    }
                }
            );
        };
    }

    ngAfterViewInit() {
        this.refreshDt();
    }

    activateUser(content, userId): void {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result
            .then(res => res && this.usersService.activate(userId).subscribe(() => this.reRenderDt()));
    }

    acceptUser(content, user): void {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result
            .then(res => res && this.usersService.acceptRequestUser(user).subscribe(() => this.reRenderDt()));
    }

    denyUser(content, user): void {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result
            .then(res => res && this.usersService.denyRequestUser(user).subscribe(() => this.reRenderDt()));
    }

    delete(content, userId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result) {
                this.usersService.delete(userId).subscribe(
                    x => {
                        this.reRenderDt();
                    }
                );
            }

        }, (reason) => {
        });
    }

    public set userStatus(value) {
        this._userStatus = value;
        this.params.show = this._userStatus;

        if (this._userStatus) {
            sessionStorage.setItem('userStatus', this._userStatus);
        } else {
            sessionStorage.removeItem('userStatus');
        }
        this.reRenderDt();
    }

    public get userStatus() {
        return this._userStatus;
    }

}
