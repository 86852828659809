import { BrandCategoryModel } from './brand-category.model';
import { RegionModel } from './region.model';
import { GlobalModel } from './global.model';

export class BrandModel {
    public brandCategory: BrandCategoryModel;
    public brandGlobal: GlobalModel;
    public createDate: Date;
    public createdBy: string;
    public deleted: boolean;
    public id: number;
    public name: string;
    public updateDate: Date;
    public updatedBy: string;
    public region: {};

    public fromJson(jsonData: any) {
        this.id = jsonData.ID ? Number.parseInt(jsonData.ID, 10) : null;
        this.name = jsonData.name;
        if (this.brandCategory !== undefined) {
            this.brandCategory.fromJson({ ID: jsonData.category_id, name: jsonData.brand_category });
        }
        else {
            this.brandCategory = new BrandCategoryModel({ ID: jsonData.category_id, name: jsonData.brand_category });
        }

        if (this.brandGlobal !== undefined) {
            this.brandGlobal.fromJson({ ID: jsonData.global_id, name: jsonData.global_name });
        }
        else {
            this.brandGlobal = new GlobalModel({ ID: jsonData.global_id, name: jsonData.global_name });
        }
    }

    constructor(data: any) {
        if (data) {
            if (data.brandCategory) { this.brandCategory = new BrandCategoryModel(data.brandCategory); }
            if (data.brandGlobal) { this.brandGlobal = new GlobalModel(data.brandGlobal); }
            this.region = data.region || new RegionModel({});
            if (data.createDate) { this.createDate = data.createDate; }
            if (data.createdBy) { this.createdBy = data.createdBy; }
            this.deleted = data.deleted || false;
            if (data.id) { this.id = data.id; }
            this.name = data.name;
            this.createDate = data.createDate || new Date();
            this.createdBy = data.createdBy || 'c786968532d8481482ac60bb9190ab73';
        }
    }

    clone(): BrandModel {
        const c = new BrandModel({});
        return Object.assign(c, this);
    }
}
