import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
import { ActivityModel } from '../models/activity.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ActivitiesApiService {
    private endpointMulti = 'activities';
    private endpointSingle = 'activity';

    constructor(private api: ApiService, private user: UserService) { }

    public list(
        resultsNum: number = 20,
        offset: number = 0,
        search: string = '',
        orderBy: string = 'date', // No es posible ordenar por otro campo que no sea Fecha en la query a BBDD
        order: string = 'ASC',
        ambassadorId: number = null
    ) {
        const url = this.endpointMulti + '/search/';

        const body = {
            searchStr: search,
            ambassadorId: ambassadorId
        };

        const params = {
            size: resultsNum,
            page: offset / resultsNum,
            sort: orderBy + ',' + order
        };

        return this.api
            .post(url, body, { headers: { Authorization: this.user.token }, params })
            .pipe(
                map((res: any) => {
                    if (res && res.content && res.content.length > 0) {
                        for (let i = 0; i < res.content.length; i++) {
                            res.content[i].date = res.content[i].date.split('T')[0];
                        }
                    }
                    return res;
                })
            );
    }

    public info(activityId: number) {
        return this.api
            .get(
                this.endpointMulti + '/detail/' + activityId.toString(),
                {},
                { headers: { Authorization: this.user.token } }
            )
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    public createOrEdit(activity: ActivityModel) {
        let obs: Observable<any>;
        const activityObject: any = {
            user: activity.user,
            country: { id: activity.countryId },
            activityType: { id: activity.activityType.id },
            activityCategory: null,
            brands: activity.program.map(p => p.brand.id),
            programs: activity.program.map(p => p.program.id),
            activityProgramList: activity.program.map(activityProgram => {
                return {
                    program: {
                        id: activityProgram.program.id
                    },
                    brand: {
                        id: activityProgram.brand.id
                    },
                    numberOfSites: activityProgram.brandProgramNumberOfSites || null,
                    numberOfServes: activityProgram.brandProgramNumberOfServes || null,
                    salesVolume: activityProgram.brandProgramSalesVolume || null,
                    bottlesMerchandised: activityProgram.brandProgramBottlesMerchandised || null,
                    displaysBuilt: activityProgram.brandProgramDisplaysBuilt || null,
                    increasedVisibility: activityProgram.brandProgramIncreasedVisibility || null
                };
            }),
            activityAttendeeList: activity.attendees && activity.attendees.length > 0
                ? activity.attendees.map(attendee => {
                    return {
                        activityAttendeeKey: {
                            attendeeType: {
                                id: attendee.type
                            }
                        },
                        attendees: attendee.number,
                        attendeeType: {
                            id: attendee.type
                        }
                    };
                })
                : [],
            activityOutletList: activity.outlets && activity.outlets.length > 0
                ? activity.outlets.map(
                    outlet => {
                        return {
                            id: outlet.id
                        };
                    }
                )
                : [],
            activityFile: null,
            date: activity.date,
            dateFrom: activity.dateFrom.format('HH:mm'),
            dateTo: activity.dateTo.format('HH:mm'),
            comment: activity.comment
        };

        if (activity.activityCategory) {
            activityObject.activityCategory = { id: activity.activityCategory.id };
        }

        if (activity.picture && activity.picture.data) {
            activityObject.activityFile = {
                name: activity.picture.data.name,
                size: activity.picture.data.size,
                file: activity.picture.base64Result
            };
            if (activity.id) {
                activityObject.activityFile.idActivity = activity.id;
            }
        }

        obs = this.create(activityObject);
        if (activity.id) {
            activityObject.id = activity.id;
            obs = this.update(activityObject);
        }

        return obs;
    }

    private create(activity: any) {
        return this.api.post(this.endpointMulti + '/add', activity, {
            headers: { Authorization: this.user.token }
        });
    }

    private update(activity: any) {
        return this.api.put(this.endpointMulti + '/edit/' + activity.id, activity, {
            headers: { Authorization: this.user.token }
        });
    }

    public delete(activityId: number) {
        return this.api.patch(
            this.endpointMulti + '/delete/' + activityId.toString(),
            {},
            { headers: { Authorization: this.user.token } }
        );
    }

    public getActivityTypes() {
        return this.api
            .get(
                this.endpointSingle + '/activitytypes',
                {},
                { headers: { Authorization: this.user.token } }
            )
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    public getOutletInfo(outletId: number) {
        return this.api
            .get(
                'outlet/' + outletId.toString(),
                {},
                { headers: { Authorization: this.user.token } }
            )
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    public getBrands(brandCategoryId: number, countryId: number) {
        return this.api
            .get(
                this.endpointSingle + '/brands',
                { country_id: countryId, brand_category_id: brandCategoryId },
                { headers: { Authorization: this.user.token } }
            )
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    public getBrandPrograms(brandId: number, countryId: number) {
        return this.api
            .get(
                this.endpointSingle + '/programs',
                { country_id: countryId, brand_id: brandId },
                { headers: { Authorization: this.user.token } }
            )
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    public getCountries() {
        return this.api
            .get(
                'countries/list',
                {},
                { headers: { Authorization: this.user.token } }
            )
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    public getWinningIns(countryId: number) {
        return this.api
            .get(
                this.endpointSingle + '/winning_ins',
                { country_id: countryId },
                { headers: { Authorization: this.user.token } }
            )
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    public getAttendeesContacts(countryId: number, attendeesTypeId: number) {
        return this.api
            .get(
                this.endpointSingle + '/contacts',
                { country_id: countryId, attendees_type_id: attendeesTypeId },
                { headers: { Authorization: this.user.token } }
            )
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    public export(ambassadorId: number, searchStr: string = '') {
        return this.api
            .postExcel(this.endpointMulti + '/export',
                { searchStr: searchStr, ambassadorId: ambassadorId },
                { headers: { Authorization: this.user.token } })
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    public exportAll() {
        return this.api.postExcel(this.endpointMulti + '/exportAll', { headers: { Authorization: this.user.token } }).pipe(
            map((res: any) => {
                return res;
            })
        );
    }
}
