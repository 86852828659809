import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivityTypesApiService } from './activity-types-api.service';
import { ActivityTypeModel } from '../models/activity-type.model';
import { GlobalId } from './global-id.interface';
import { GlobalModel } from '../models/global.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityTypesService implements GlobalId
{
    constructor(
        private activityTypesApi: ActivityTypesApiService
    ) { }

    public list() {
        return this.activityTypesApi.list();
    }

    public search()
    {
        const activityTypes: ActivityTypeModel[] = [];
        return this.activityTypesApi.search().pipe(
            map( r => {
                if (r.content)
                {
                    r.content.forEach( element => activityTypes.push(new ActivityTypeModel(element)));
                }
                return activityTypes;
            })
        );
    }

    public info(activityTypeId: number): Observable<ActivityTypeModel>
    {
        return this.activityTypesApi.info(activityTypeId).pipe(
            map( res => new ActivityTypeModel(res))
        );
    }

    public delete(activityType: ActivityTypeModel)
    {
        return this.activityTypesApi.delete(activityType.id);
    }

    public createOrEdit(activityType: ActivityTypeModel)
    {
        return this.activityTypesApi.createOrEdit(activityType);
    }

    public getGlobals()
    {
        return this.activityTypesApi.getGlobals();
        // const globals: GlobalModel[] = [];
        // return this.activityTypesApi.getGlobals().pipe(
        //     map( l => {
        //         l.data.forEach( element => globals.push(new GlobalModel(element)));
        //         return globals;
        //     })
        // );
    }

    public getByCountry(countryId: number)
    {
    return this.activityTypesApi.getByCountry(countryId);
    }

    public createGlobal(global) {
        return this.activityTypesApi.createGlobal(global);
    }
}
