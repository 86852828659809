import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormPage } from 'src/app/pages/FormPage';
import { ContactSocialsService } from 'src/app/providers/contact-socials.service';
import { ContactSocialModel } from 'src/app/models/contact-social.model';
import { GlobalMessage } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-contact-socials-edit.page',
    templateUrl: './contact-socials-edit.page.html'
})
export class ContactSocialEditPage extends FormPage implements OnInit
{
    public contactSocialForm = this.fb.group({
        contactSocialId: [''],
        social: ['', Validators.required],
        value: ['', Validators.required],
    });

    public contactSocialId: number = null;
    public userId: number = null;

    constructor(
        private fb: FormBuilder,
        private contactSocialService: ContactSocialsService,
        private route: ActivatedRoute,
        private errorHandler: GlobalMessage,
        private router: Router)
    {
        super();
    }

    ngOnInit()
    {
        this.route.paramMap.subscribe(params => {
            if (params.get('userid'))
            {
                this.userId = Number.parseInt(params.get('userid'), 10);
            }
            if (params.get('id') !== null)
            {
                this.contactSocialId = Number.parseInt(params.get('id'), 10);
                this.contactSocialService.info(Number.parseInt(params.get('id'), 10)).subscribe(
                    (data) => {
                        this.contactSocialForm.setValue({
                            contactSocialId: data.id,
                            social: data.social,
                            value: data.value
                        });
                    }
                );
            }
        });
    }

    onSubmit()
    {
        if (this.contactSocialForm.valid)
        {
            const contactSocial = this.contactSocialForm.value;
            this.contactSocialService.createOrEdit(new ContactSocialModel({
                ID: contactSocial.contactSocialId,
                social: contactSocial.social,
                social_value: contactSocial.value,
                person_id: this.userId,
            })).subscribe((data: any) => {
                if (data.status === 'success')
                {
                    this.errorHandler.showSuccess('Your contact social has been saved successfully', 5000);
                    this.router.navigate(['/workspace/contact/' + this.userId]);
                }
                else
                {
                    this.errorHandler.showError(data.error.error_msg);
                }
            });
        }
        else
        {
            this.validateAllFormFields(this.contactSocialForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.contactSocialForm;
    }
}
