import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivitySegmentModel } from 'src/app/models/activity-segment.model';
import { OutletModel } from 'src/app/models/outlet.model';
import { POSSegmentModel } from 'src/app/models/pos-segment.model';
import { WinningInModel } from 'src/app/models/winning-in.model';
import { FormPage } from 'src/app/pages/FormPage';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { OutletsService } from 'src/app/providers/outlets.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OutletWinningInService } from '../../providers/outlet-winning-in.service';
import { OutletActivitySegmentsService } from '../../providers/outlet-activity-segments.service';
import { OutletPosSegmentsService } from '../../providers/outlet-pos-segments.service';

@Component({
    selector: 'app-outlet-form',
    templateUrl: './outlet-form.component.html',
    styleUrls: ['./outlet-form.component.scss']
})

export class OutletFormComponent extends FormPage implements OnInit {
    public outletForm = this.fb.group({
        outletId: [''],
        name: ['', Validators.required],
        address: ['', Validators.required],
        zip: ['', Validators.required],
        city: ['', Validators.required],
        phone: [''],
        countryId: ['', Validators.required],
        winningInId: ['', Validators.required],
        posSegmentId: ['', Validators.required],
        manager: [''],
        activitySegmentId: ['', Validators.required],
        bacId: ['']
    });

    public winningIns: WinningInModel[] = null;
    public activitySegments: ActivitySegmentModel[] = null;
    public posSegments: POSSegmentModel[] = null;
    public managers: any[] = null;

    public showCountry = true;
    public isEdition = false;

    @Input() activeModal: NgbActiveModal = null;

    constructor(
        private fb: FormBuilder,
        private outletService: OutletsService,
        private outletWinningInService: OutletWinningInService,
        private outletActivitySegmentsService: OutletActivitySegmentsService,
        private outletPosSegmentsService: OutletPosSegmentsService,
        private route: ActivatedRoute,
        private router: Router,
        private errorHandler: GlobalMessage) {
        super();
    }

    @Input() returnToList = true;

    @Input() set outlet(data: any) {
        if (data !== undefined) {
            this.outletForm.patchValue({
                outletId: data.id ? data.id : null,
                name: data.name ? data.name : null,
                address: data.address ? data.address : null,
                zip: data.zip ? data.zip : null,
                city: data.city ? data.city : null,
                phone: data.phone ? data.phone : null,
                countryId: data.country ? data.country.id : null,
                winningInId: data.winningIn ? data.winningIn.id : '',
                activitySegmentId: data.activitySegment ? data.activitySegment.id : '',
                posSegmentId: data.posSegment ? data.posSegment.id : '',
                manager: data.manager,
                bacId: data.bacId ? data.bacId : null
            });

            if (data.country) {
                this.updateWinningIns(data.country.id);
                this.updateActivitySegments(data.country.id);
                this.updatePOSSegments(data.country.id);
                this.updateManagers(data.country.id);
                this.showCountry = false;
            }
        }
    }

    @Output() creationSuccess = new EventEmitter<OutletModel>();

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null) {
                this.isEdition = true;
                this.outletService.info(Number.parseInt(params.get('id'), 10)).subscribe(
                    (data) => {
                        this.outlet = data;
                    }
                );
            }
        });
    }

    onSubmit() {
        if (this.outletForm.valid) {
            const outlet = this.outletForm.value;
            const outletObjc = new OutletModel({
                id: outlet.outletId,
                name: outlet.name,
                address: outlet.address,
                zip: outlet.zip,
                city: outlet.city,
                phone: outlet.phone,
                country: { id: outlet.countryId },
                winningIn: { id: outlet.winningInId },
                activitySegment: { id: outlet.activitySegmentId },
                posSegment: { id: outlet.posSegmentId },
                manager: outlet.manager,
                bacId: outlet.bacId
            });

            this.outletService.createOrEdit(outletObjc).subscribe((data: any) => {
                if (data) {
                    this.errorHandler.showSuccess('Your outlet has been saved successfully', 5000);
                    this.creationSuccess.emit(new OutletModel(data));
                    if (this.returnToList) {
                        this.router.navigate(['/workspace/outlets']);
                    }
                }
            }, error => {
                this.errorHandler.showError('Form contains errors');
                console.error(error);
            });
        }
        else {
            this.validateAllFormFields(this.outletForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    countryChange(ev) {
        const countryId = parseInt(ev.target.value, 10);
        this.updateWinningIns(countryId);
        this.updateActivitySegments(countryId);
        this.updatePOSSegments(countryId);
        this.updateManagers(countryId);
    }

    updateWinningIns(countryId: number) {
        if (countryId) {
            this.outletWinningInService.search(countryId).subscribe((data: any) => {
                if (data) {
                    this.winningIns = data.content;
                }
            });
        }
        else {
            this.winningIns = null;
        }
    }

    updateActivitySegments(countryId: number) {
        if (countryId) {
            this.outletActivitySegmentsService.search(countryId).subscribe(data => {
                if (data) {
                    this.activitySegments = data.content;
                }
            });
        }
        else {
            this.activitySegments = null;
        }
    }

    updatePOSSegments(countryId: number) {
        if (countryId) {
            this.outletPosSegmentsService.search(countryId).subscribe(data => {
                if (data) {
                    this.posSegments = data.content;
                }
            });
        }
        else {
            this.posSegments = null;
        }
    }

    updateManagers(countryId: number) {
        if (countryId) {
            this.outletService.getOutletManagersByCountry(countryId).subscribe(data => {
                if (data) {
                    this.managers = data;
                }
            });
        }
        else {
            this.managers = null;
        }
    }

    getForm(): FormGroup {
        return this.outletForm;
    }
}
