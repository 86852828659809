import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserService } from '../providers/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private userService: UserService, private router: Router) { }

    canActivate(next: ActivatedRouteSnapshot) {
        let canActivate = this.userService.user !== undefined && this.userService !== null;

        if (canActivate) {
            // Por defecto, dejamos pasar a los Admins
            canActivate = this.userService.user.isAdmin;

            // Si hay configuracion en la ruta a acceder...
            if (next.data) {
                // canActivate = (el usuario es Admin)
                //    O (el usuario es Manager Y se permite el acceso a Managers)
                //    O (el usuario es Ambassador y se permite el acceso a Ambassadors)
                canActivate = this.userService.user.isAdmin
                    || this.userService.user.isManager === next.data.managerAccessGranted
                    || !this.userService.user.isManager === next.data.ambassadorAccessGranted;
            }
        }

        if (!canActivate) {
            this.router.navigate(['login']);
        }

        return canActivate;
    }
}
