import { Component, ContentChild, TemplateRef, Input, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactSocialsService } from 'src/app/providers/contact-socials.service';
import { Subject } from 'rxjs';
import { ContactSocialModel } from 'src/app/models/contact-social.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';

@Component({
    selector: 'app-social-list',
    templateUrl: './social-list.component.html'
})
export class SocialListComponent extends DatatablePage implements AfterViewInit
{
    private _userId: number = null;
    @Input() set userId(v) {
        this._userId = v;
        this.refreshList();
    }
    get userId()
    {
        return this._userId;
    }

    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    public dtTrigger: Subject<void> = new Subject();
    public dtOptions: any = {};
    public socials: ContactSocialModel[];

    constructor(
        private socialService: ContactSocialsService,
        private modalService: NgbModal
    )
    {
        super();
    }

    ngAfterViewInit()
    {
        this.refreshList();
        this.refreshDt();
    }

    refreshList() {
        if (this._userId)
        {
            this.socialService.list(this._userId).subscribe(data => {
                this.socials = data;
                this.reRenderDt();
            });
        }
    }

    delete(content, socialId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result)
            {
                this.socialService.delete(this.socials.find( a => a.id === socialId)).subscribe(
                    x => {
                        this.refreshList();
                    }
                );
            }

        }, (reason) => {
        });
    }

}
