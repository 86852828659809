import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { ActivitySegmentModel } from '../models/activity-segment.model';
import { GlobalModel } from '../models/global.model';
import { GlobalId } from './global-id.interface';
import { OutletActivitySegmentsApiService } from './outlet-activity-segments-api.service';
import { CountriesService } from './countries.service';

@Injectable({
    providedIn: 'root'
})
export class OutletActivitySegmentsService implements GlobalId {
    constructor(
        private outletActivitySegmentsApi: OutletActivitySegmentsApiService,
        private countryService: CountriesService
    ) { }

    public list(countryId: number, size?: number, page?: number) {
        const activitySegments: ActivitySegmentModel[] = [];
        return this.countryService.info(countryId)
            .pipe(
                flatMap(country => {
                    return this.outletActivitySegmentsApi.list(String(countryId), size, page)
                        .pipe(
                            map(r => {
                                if (r && r.content) {
                                    r.content.forEach(element => {
                                        const asm = new ActivitySegmentModel(element);
                                        asm.country = country;
                                        activitySegments.push(asm);
                                    });
                                    return activitySegments;
                                }
                            })
                        );
                })
            );
    }

    public search(countryId: number) {
        return this.outletActivitySegmentsApi.search(countryId);
    }

    public info(activitySegmentId: number): Observable<ActivitySegmentModel> {
        return this.outletActivitySegmentsApi.info(activitySegmentId).pipe(
            map(res => new ActivitySegmentModel(res))
        );
    }

    public update(activitySegment: ActivitySegmentModel) {
        return this.outletActivitySegmentsApi.update(activitySegment);
    }

    public getGlobals() {
        const globals: GlobalModel[] = [];
        return this.outletActivitySegmentsApi.getGlobals().pipe(
            map(l => {
                l.forEach(element => globals.push(new GlobalModel(element)));
                return globals;
            })
        );
    }

    public createGlobal(global) {
        return this.outletActivitySegmentsApi.createGlobal(global);
    }
}
