import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { POSSegmentModel } from '../models/pos-segment.model';
import { ApiService } from './api.service';
import { UserService } from './user.service';


@Injectable({
    providedIn: 'root'
})
export class OutletPosSegmentsApiService {
    private endpointMulti = 'posSegmentsCountry/search';
    private endpointSingle = 'posSegmentsCountry';
    private endpointGlobals = 'posSegments';
    private requestHeader: any = {
        headers: { Authorization: this.user.token }
    };

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public list(countryId: string, size?: number, page?: number) {
        const body = { country: countryId, searchStr: '' };
        const params = { size: size, page: page };

        return this.api.post(this.endpointMulti, body, { headers: { Authorization: this.user.token }, params: params })
            .pipe(
                map(
                    (res: any) => res
                )
            );
    }

    public search(countryId: number) {
        return this.api.post(this.endpointSingle
            + '/search?size=99999&page=undefined&sort=name,ASC&sort=id,ASC',
            { country: countryId },
            { headers: { Authorization: this.user.token } });
    }

    public info(posSegmentId: number) {
        const params = {};

        return this.api.get(this.endpointSingle + '/' + posSegmentId.toString(),
            params, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public update(posSegment: POSSegmentModel) {
        const posSegmentObject = {
            country: { id: posSegment.country.id },
            createDate: posSegment.createDate,
            createdBy: posSegment.createdBy,
            deleted: false,
            name: posSegment.name,
            posSegment: { id: posSegment.posSegment.id },
            selectable: true,
            updatedBy: posSegment.updatedBy
        };

        return this.api.put(
            this.endpointSingle + '/edit/' + posSegment.id.toString(),
            posSegmentObject,
            { headers: { Authorization: this.user.token } }
        );
    }

    public getGlobals() {
        const params = {};

        return this.api.get(`${this.endpointGlobals}/list`, params, { headers: { Authorization: this.user.token } })
            .pipe(
                map((res: any) => res)
            );
    }

    public createGlobal(global) {
        return this.api.post(`${this.endpointGlobals}/add`, global, this.requestHeader);
    }
}
