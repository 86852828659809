import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OutletModel } from 'src/app/models/outlet.model';
import { OutletModalComponent } from '../outlet-modal/outlet-modal.component';
import { CountryModel } from 'src/app/models/country.model';
import { OutletsService } from '../../providers/outlets.service';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OutletSelectComponent),
    multi: true,
};
@Component({
    selector: 'app-outlet-select',
    templateUrl: './outlet-select.component.html',
    providers: [CUSTOM_VALUE_ACCESSOR]
})
export class OutletSelectComponent implements ControlValueAccessor, AfterViewInit {
    @Input() inputId: string;
    @Input() clearAfterSelect = false;
    @Input() canCreate = false;
    @Input() excludeOutlets: OutletModel[] = [];

    @Output() change = new EventEmitter<number>();

    public disabled = false;
    public value: number = null;
    public outlets: OutletModel[];

    private isAdding = false;
    private isLoading = 0;
    private selectize: any;
    private _countryId: number;
    private onChange: Function = (_: any) => { };
    private onTouched: Function = () => { };

    constructor(private outletService: OutletsService, private modalService: NgbModal) { }

    @Input() set countryId(val) {
        this._countryId = val;

        if (val) {
            this.refresh();
        }
    }

    get countryId() {
        return this._countryId;
    }

    ngAfterViewInit() {
        const selectizeObj = $('#' + this.inputId)['selectize']({
            placeholder: 'Select' + (this.canCreate ? ' / Create' : '') + ' an outlet',
            create: this.canCreate,
            render: {
                option: (item, escape) => {
                    return this.renderOption(item);
                },
                item: (item, escape) => {
                    return this.renderOption(item);
                }
            }
        });
        if (selectizeObj[0]) { this.selectize = selectizeObj[0].selectize; }

        this.selectize.on('option_add', (value) => {
            if (this.isLoading <= 0) {
                this.isAdding = true;
                const modalRef = this.modalService.open(OutletModalComponent, { size: 'xl' });
                modalRef.componentInstance.outlet =
                    new OutletModel({
                        name: value,
                        country: new CountryModel({ id: this.countryId })
                    });

                modalRef.result.then(outlet => {
                    if (outlet) {
                        this.emitChangeValue(parseInt(outlet.id, 10));
                    }
                    else {
                        this.selectize.clear();
                        this.selectize.removeOption(value);
                    }
                    this.isAdding = false;
                    modalRef.close();
                },
                    () => {
                        this.selectize.clear();
                        this.selectize.removeOption(value);
                        this.isAdding = false;
                    });
            }
        });

        this.selectize.on('change', (value) => {
            if (!this.isAdding && this.isLoading <= 0) {
                this.emitChangeValue(parseInt(value, 10));
            }
        });
    }

    private renderOption(item): string {
        return `<div class='item' data-value='' + item.value + ''>` + item.text +
            ((item.isSfa) ? ` <img src='/assets/icon_win_red.png' alt='icon_win_red' class='mr-1' style='width: 16px'></div>` : '')
            + '</div>';
    }

    public refresh() {
        this.isLoading++;

        this.outletService.getByCountry(this.countryId).subscribe(
            data => {
                this.outlets = data
                    ? data.filter(o => !this.excludeOutlets.map(m => m.id).filter(f => f !== this.value).includes(o.id))
                    : [];
                this.selectize.clear(true);
                this.selectize.clearOptions();

                for (const outlet of this.outlets) {
                    this.selectize.addOption({
                        value: outlet.id,
                        text: outlet.name,
                        isSfa: outlet.bacId && Number(outlet.bacId) !== 0
                    });
                }

                this.selectize.refreshOptions(false);
                this.selectize.setValue(this.value);
                this.isLoading--;
            }
        );
    }

    writeValue(obj: any): void {
        this.value = parseInt(obj, 10);
        if (this.selectize) {
            this.selectize.setValue(this.value);
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    outletChange(ev) {
        this.emitChangeValue(parseInt(ev.target.value, 10));
    }

    emitChangeValue(value: number) {
        this.value = value;
        this.onChange(value);
        this.onTouched();
        this.change.emit(value);
    }
}
