import { FormGroup, FormControl, NgModel } from '@angular/forms';

export abstract class FormPage
{
    constructor() {}

    abstract getForm(): FormGroup;

    protected validateAllFormFields(formGroup: FormGroup)
    {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    public isFieldValid(field: string)
    {
        return this.getForm().get(field).enabled && this.getForm().get(field).invalid && this.getForm().get(field).touched;
    }

    public isModelValid(field: NgModel)
    {
        return field.errors && (field.dirty || field.touched);
    }

    public displayFieldCss(field: string)
    {
        return {
            'has-error': this.isFieldValid(field),
            'has-feedback': this.isFieldValid(field)
        };
    }
}
