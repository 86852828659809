import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { ContactTitleModel } from '../models/contact-title.model';

@Injectable({
    providedIn: 'root'
})
export class ContactTitlesApiService {
    private endpointRegion = 'personstitleregion';
    private endpoint = 'personstitle';
    private requestHeader = {
        headers: { Authorization: this.user.token }
    };

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public search(body: any, size?: number, page?: number) {
        const params = { size: size, page: page };

        return this.api.post(this.endpointRegion
            + '/search/'
            + this.user.user.id, body,
            {
                headers: { Authorization: this.user.token },
                params: params
            })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public info(contactTitleId: number) {
        return this.api.get(this.endpoint + '/' + contactTitleId.toString(), {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res.data;
                    }
                )
            );
    }

    public delete(contactTitleId: number) {
        return this.api.patch(this.endpointRegion
            + '/delete/'
            + contactTitleId.toString(), {}, { headers: { Authorization: this.user.token }, observe: 'response' });
    }

    public createOrEdit(contactTitle: ContactTitleModel) {
        let contactTitleObject: any;

        const url = this.endpointRegion + '/save';
        if (contactTitle.id) {
            contactTitleObject = {
                id: contactTitle.id,
                name: contactTitle.name,
                region: {
                    id: contactTitle.region.id,
                    code: contactTitle.region.code,
                    name: contactTitle.region.name,
                },
                personTitleGlobal: {
                    id: contactTitle.personTitleGlobal.id,
                    hobby: contactTitle.personTitleGlobal.name,
                }
            };
        } else {
            contactTitleObject = {
                name: contactTitle.name,
                region: {
                    id: contactTitle.region.id,
                    code: contactTitle.region.code,
                    name: contactTitle.region.name,
                },
                personTitleGlobal: {
                    id: contactTitle.personTitleGlobal.id,
                    hobby: contactTitle.personTitleGlobal.name,
                }
            };

        }
        return this.api.post(url, contactTitleObject, { headers: { Authorization: this.user.token } });
    }

    public getGlobals() {
        const params = {};

        return this.api.get(this.endpoint + '/list', params, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public createGlobal(global) {
        return this.api.post(`${this.endpoint}/add`, global, this.requestHeader);
    }
}
