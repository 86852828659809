import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ActivityTypesService } from 'src/app/providers/activity-types.service';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { FormPage } from '../../../../FormPage';
import { UserService } from 'src/app/providers/user.service';

@Component({
    selector: 'app-activity-type-edit.page',
    templateUrl: './activity-type-edit.page.html'
})
export class ActivityTypeEditPage extends FormPage implements OnInit
{
    public activityTypeForm = this.fb.group({
        activityTypeGlobal: [{}, Validators.required],
        activityTypeId: [''],
        name: ['', Validators.required],
    });

    public activityTypeId: number = null;

    constructor(
        private fb: FormBuilder,
        public activityTypeService: ActivityTypesService,
        private errorHandler: GlobalMessage,
        private userService: UserService,
    ) {
        super();
    }

    ngOnInit()
    {}

    onSubmit()
    {
        if (this.activityTypeForm.valid)
        {
            const activityType = this.activityTypeForm.value;
            delete activityType.activityTypeId;
            activityType.region = this.userService.user.defaultRegion;

            this.activityTypeService.createOrEdit(activityType).subscribe(
                () => {
                    this.errorHandler.showSuccess('Your activity type has been saved successfully', 5000);
                }
            );
        }
        else
        {
            this.validateAllFormFields(this.activityTypeForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.activityTypeForm;
    }
}
