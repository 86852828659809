import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelMenuComponent } from './panel-menu.component';
import { RouterModule } from '@angular/router';

@NgModule(
    {
        imports: [
            CommonModule,
            RouterModule
        ],
        declarations: [
            PanelMenuComponent
        ],
        exports: [
            PanelMenuComponent
        ]
    }
)
export class PanelMenuModule { }
