export class StatisticsTypeModel {
    public id: string;
    public name: string;
    public fields: string[];

    public fromJson(jsonData: any)
    {
        this.id = jsonData.ID;
        this.name = jsonData.name;
        this.fields = jsonData.fields;
    }

    constructor(jsonData: any) {
        this.fromJson(jsonData);
    }
}
