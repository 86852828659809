import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormPage } from 'src/app/pages/FormPage';
import { OutletActivitySegmentsService } from 'src/app/providers/outlet-activity-segments.service';
import { ActivitySegmentModel } from 'src/app/models/activity-segment.model';
import { GlobalMessage } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-activity-segment-edit.page',
    templateUrl: './outlet-activity-segment-edit.page.html'
})
export class OutletActivitySegmentEditPage extends FormPage implements OnInit
{
    public activitySegmentForm = this.fb.group({
        activitySegmentId: [''],
        name: ['', Validators.required],
        global: ['', Validators.required],
    });

    public activitySegmentId: number = null;

    constructor(
        private fb: FormBuilder,
        public outletActivitySegmentsService: OutletActivitySegmentsService,
        private route: ActivatedRoute,
        private errorHandler: GlobalMessage)
    {
        super();
    }

    ngOnInit()
    {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null)
            {
                this.activitySegmentId = Number.parseInt(params.get('id'), 10);
                this.outletActivitySegmentsService.info(Number.parseInt(params.get('id'), 10)).subscribe(
                    (data) => {
                        this.activitySegmentForm.setValue({
                            activitySegmentId: data.id,
                            name: data.name
                        });
                    }
                );
            }
        });
    }

    onSubmit()
    {
        if (this.activitySegmentForm.valid)
        {
            const activitySegment = this.activitySegmentForm.value;
            this.outletActivitySegmentsService.update(new ActivitySegmentModel({
                id: activitySegment.activitySegmentId,
                name: activitySegment.name
            })).subscribe((data: any) => {
                if (data.status === 'success')
                {
                    this.errorHandler.showSuccess('Your outlet activity has been saved successfully', 5000);
                }
                else
                {
                    this.errorHandler.showError(data.error.error_msg);
                }
            });
        }
        else
        {
            this.validateAllFormFields(this.activitySegmentForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.activitySegmentForm;
    }
}
