import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContactSocialModel } from '../models/contact-social.model';
import { ContactSocialsApiService } from './contact-socials-api.service';

@Injectable({
  providedIn: 'root'
})
export class ContactSocialsService
{
    constructor(
        private contactSocialsApi: ContactSocialsApiService
    ) { }

    public list(contactId: number)
    {
        const contactSocials: ContactSocialModel[] = [];
        return this.contactSocialsApi.list(contactId).pipe(
            map( l => {
                l.data.forEach( element => contactSocials.push(new ContactSocialModel(element)));
                return contactSocials;
            })
        );
    }

    public info(contactSocialId: number): Observable<ContactSocialModel>
    {
        return this.contactSocialsApi.info(contactSocialId).pipe(
            map( res => new ContactSocialModel(res))
        );
    }

    public delete(contactSocial: ContactSocialModel)
    {
        return this.contactSocialsApi.delete(contactSocial.id);
    }

    public createOrEdit(contactSocial: ContactSocialModel)
    {
        return this.contactSocialsApi.createOrEdit(contactSocial);
    }
}
