import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
import { routes as WorkspaceRoutes } from './pages/workspace/workspace-routing.module';
import { WorkspaceComponent } from './pages/workspace/workspace.component';

const routes: Routes = [
    { path: 'login', component: LoginPage },
    { path: 'login-token/:token', component: LoginPage },
    { path: 'workspace', component: WorkspaceComponent, children: WorkspaceRoutes },
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    // { path: '**', component: NotFoundPage },
  ];

@NgModule({
    exports: [ RouterModule ],
    imports: [ RouterModule.forRoot(routes, { useHash: true }) ],
})
export class AppRoutingModule { }
