import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ContactHobbyModel } from 'src/app/models/contact-hobby.model';
import { ContactHobbiesService } from 'src/app/providers/contact-hobbies.service';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide : NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HobbySelectComponent),
    multi : true,
};
@Component({
    selector: 'app-hobby-select',
    templateUrl: './hobby-select.component.html',
    providers : [CUSTOM_VALUE_ACCESSOR]
})
export class HobbySelectComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    @Input() multiple: boolean;

    public disabled = false;
    public value: number = null;
    public hobbies: ContactHobbyModel[];
    public selectedHobbies: ContactHobbyModel[] = [];

    public pendingValues: any[] = [];

    private onChange: Function = (_: any) => {};
    private onTouched: Function = () => {};

    constructor(private contactHobbiesService: ContactHobbiesService) {}

    ngOnInit() {
        this.contactHobbiesService.search().subscribe(data => {
            this.hobbies = data;
            this.flushPending();
        });
    }

    writeValue(obj: any): void {
        if (obj)
        {
            if (typeof obj === 'object')
            {
                obj.forEach(v => this.pendingValues.push(v));
            }
            else
            {
                this.pendingValues.push(obj);
            }
        }

        if (this.hobbies)
        {
            this.flushPending();
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private flushPending()
    {
        let v = this.pendingValues.pop();
        while (v)
        {
            if (typeof v === 'string')
            {
                this.selectedHobbies.push(this.getHobbyById(Number.parseInt(v, 10)));
            }
            else if (typeof v === 'number')
            {
                if (this.multiple)
                {
                    this.selectedHobbies.push(this.getHobbyById(v));
                }
                else
                {
                    this.value = v;
                }
            }
            else if (v instanceof ContactHobbyModel && v.id)
            {
                this.selectedHobbies.push(this.getHobbyById(v.id));
            }
            v = this.pendingValues.pop();
        }

        if (this.multiple)
        {
            this.onChange(this.getMultipleValues());
        }
        else
        {
            this.onChange(this.value);
        }
    }

    private getHobbyById(hobbyId: number)
    {
        const found = this.hobbies.find(b => b.id === hobbyId);
        return found ? found : null;
    }

    hobbyChange(ev)
    {
        this.value = Number.parseInt(ev.target.value, 10);

        if (!this.multiple)
        {
            this.onChange(this.value);
            this.onTouched();
        }
    }

    public add()
    {
        if (this.value && this.getMultipleValues().indexOf(this.value) < 0)
        {
            this.selectedHobbies.push(this.getHobbyById(this.value));
            this.onChange(this.getMultipleValues());
            this.onTouched();
        }
    }

    public remove(hobbyId: number)
    {
        this.selectedHobbies = this.selectedHobbies.filter(b => b.id !== hobbyId);
        this.onChange(this.getMultipleValues());
        this.onTouched();
    }

    public getMultipleValues()
    {
        return this.selectedHobbies.map(hobby => hobby.id);
    }
}
