import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormPage } from 'src/app/pages/FormPage';
import { ContactTitlesService } from 'src/app/providers/contact-titles.service';
import { ContactTitleModel } from 'src/app/models/contact-title.model';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { GlobalModel } from '../../../../../models/global.model';
import { UserService } from '../../../../../providers/user.service';

@Component({
    selector: 'app-contact-titles-edit.page',
    templateUrl: './contact-titles-edit.page.html'
})
export class ContactTitleEditPage extends FormPage implements OnInit {
    public contactTitleForm = this.fb.group({
        contactTitleId: [''],
        name: ['', Validators.required],
        global: ['', Validators.required],
    });

    public contactTitleId: number = null;

    constructor(
        private fb: FormBuilder,
        private contactTitleService: ContactTitlesService,
        private route: ActivatedRoute,
        private router: Router,
        private errorHandler: GlobalMessage,
        private userService: UserService) {
        super();
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null) {
                this.contactTitleId = Number.parseInt(params.get('id'), 10);
                this.contactTitleService.info(Number.parseInt(params.get('id'), 10)).subscribe(
                    (data) => {
                        this.contactTitleForm.setValue({
                            contactTitleId: data.id,
                            name: data.name,
                            global: data.personTitleGlobal ? data.personTitleGlobal.id : null
                        });
                    }
                );
            }
        });
    }

    onSubmit() {
        if (this.contactTitleForm.valid) {
            const contactTitle = this.contactTitleForm.value;
            const personTitleGlobal = new GlobalModel({ id: contactTitle.global.id, name: contactTitle.global.name });
            this.contactTitleService.createOrEdit(new ContactTitleModel({
                id: contactTitle.contactTitleId,
                name: contactTitle.name,
                personTitleGlobal: personTitleGlobal,
                region: this.userService.user.defaultRegion,
            })).subscribe((data: any) => {
            }, error => {
                this.errorHandler.showError('Form contains errors');
            }, () => {
                this.errorHandler.showSuccess('Your contact title has been saved successfully', 5000);
                this.router.navigate(['/workspace/contact-titles']);
            });
        }
        else {
            this.validateAllFormFields(this.contactTitleForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.contactTitleForm;
    }
}
