import {
    Component,
    forwardRef,
    OnInit,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountryModel } from 'src/app/models/country.model';
import { CountriesService } from 'src/app/providers/countries.service';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CountrySelectComponent),
    multi: true
};
@Component({
    selector: 'app-country-select',
    templateUrl: './country-select.component.html',
    providers: [CUSTOM_VALUE_ACCESSOR]
})
export class CountrySelectComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    @Input() multiple: boolean;
    @Input() defaultId;
    @Input() canEdit = true;
    @Output() defaultSelect = new EventEmitter<number>();

    public disabled = false;
    public value: number;
    public countries: CountryModel[];
    public selectedCountries: CountryModel[] = [];

    public pendingValues: any[] = [];

    private onChange: Function = (_: any) => { };
    private onTouched: Function = () => { };

    constructor(
        private countryService: CountriesService
    ) { }

    ngOnInit() {
        this.countryService.search().subscribe(data => {
            if (data != null) {
                this.saveAndFlush(data.content);
            }
        });
    }

    private saveAndFlush(data: any): void {
        if (data) {
            this.countries = data;
            this.flushPending();
        }
    }

    writeValue(obj: any): void {
        if (obj) {
            if (typeof obj === 'object') {
                obj.forEach(v => this.pendingValues.push(v));
            } else {
                this.pendingValues.push(obj);
            }
        }

        if (this.countries) {
            this.flushPending();
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private flushPending() {
        let v = this.pendingValues.pop();
        while (v) {
            if (typeof v === 'string') {
                this.selectedCountries.push(
                    this.getCountryById(Number.parseInt(v, 10))
                );
            } else if (typeof v === 'number') {
                if (this.multiple) {
                    this.selectedCountries.push(this.getCountryById(v));
                } else {
                    this.value = v;
                }
            } else if (v instanceof CountryModel && v.id) {
                this.selectedCountries.push(this.getCountryById(v.id));
            }
            v = this.pendingValues.pop();
        }

        if (this.multiple) {
            this.onChange(this.getMultipleValues());
        } else {
            this.onChange(this.value);
        }
    }

    private getCountryById(countryId: number) {
        const found = this.countries.find(c => c.id === countryId);
        return found ? found : null;
    }

    public countryChange(ev) {
        this.value = Number.parseInt(ev.target.value, 10);

        if (!this.multiple) {
            this.onChange(this.value);
            this.onTouched();
        }
    }

    public add() {
        if (this.value && this.getMultipleValues().indexOf(this.value) < 0) {
            this.selectedCountries.push(this.getCountryById(this.value));
            this.onChange(this.getMultipleValues());
            this.onTouched();

            if (!this.defaultId) {
                this.defaultCountryChange(this.selectedCountries[0].id);
            }
        }
    }

    public remove(countryId: number) {
        this.selectedCountries = this.selectedCountries.filter(
            c => c.id !== countryId
        );
        this.onChange(this.getMultipleValues());
        this.onTouched();

        if (this.selectedCountries.length <= 0) {
            this.defaultCountryChange(undefined);
        } else if (countryId === this.defaultId) {
            this.defaultCountryChange(this.selectedCountries[0].id);
        }
    }

    public getMultipleValues() {
        return this.selectedCountries.map(country => country.id);
    }

    public defaultCountryChange(countryId) {
        this.defaultSelect.emit(countryId);
    }
}
