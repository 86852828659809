import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TargetModel } from 'src/app/models/target.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { TargetsService } from 'src/app/providers/targets.service';

@Component({
    selector: 'app-target-list-static',
    templateUrl: './target-list-static.page.html'
})
export class TargetListStaticPage extends DatatablePage implements AfterViewInit, OnInit {
    public targets: TargetModel[];

    constructor(
        private targetService: TargetsService
    ) {
        super();
    }

    ngAfterViewInit() {
        this.refreshList();
        this.refreshDt();
    }

    refreshList() {
        this.targetService.list().subscribe(data => {
            this.targets = data;

            if (data.length > 0) {
                this.reRenderDt();
            }
        });
    }
}
