import { AttendeeTypeModel } from './attendee-type.model';
import { AttendeeContactModel } from './attendee-contact.model';

export class AttendeeModel {
    constructor(
        public type: AttendeeTypeModel,
        public number: number = 0,
        public contacts: AttendeeContactModel[]
    ) {}
}
