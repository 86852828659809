import { AfterViewInit, Component, ContentChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandCategoryModel } from 'src/app/models/brand-category.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { BrandCategoriesService } from 'src/app/providers/brand-categories.service';
import { FormBuilder, Validators } from '@angular/forms';
import { GlobalMessage } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-brand-categories',
    templateUrl: './brand-category-list.page.html'
})
export class BrandCategoryListPage extends DatatablePage implements AfterViewInit {
    public brandCategoryForm = this.fb.group({
        brandCategoryId: [''],
        name: ['', Validators.required],
    });
    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    public brandCategories: BrandCategoryModel[];
    private editingId: number;
    private editingValue: string;

    constructor(
        private brandCategoryService: BrandCategoriesService,
        private modalService: NgbModal,
        private fb: FormBuilder,
        private messageHandler: GlobalMessage
    ) {
        super();
    }

    ngAfterViewInit() {
        this.refreshList();
        this.refreshDt();
    }

    refreshList() {
        this.brandCategoryService.list().subscribe(data => {
            this.brandCategories = data;
            this.reRenderDt();
        });
    }

    delete(content, brandCategoryId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result) {
                this.brandCategoryService.delete(this.brandCategories.find(a => a.id === brandCategoryId)).subscribe(
                    x => {
                        this.refreshList();
                    }
                );
            }

        }, (reason) => {
        });
    }

    startEdit(brandCategoryId: number) {
        this.editingId = brandCategoryId;
        this.editingValue = this.brandCategories.find(b => b.id === brandCategoryId).name;
    }

    validateEdit() {
        this.brandCategoryService.createOrEdit(new BrandCategoryModel({
            id: this.editingId,
            name: this.editingValue
        })).subscribe((data: any) => {

        }, (error) => {
            console.error(error);
        },
            () => {
                this.editingId = 0;
                this.editingValue = '';
                this.refreshList();
                this.reRenderDt();
                this.messageHandler.showSuccess('Your brand category has been saved successfully', 5000);
            });
    }

}
