import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AttendeeTypeModel } from '../models/attendee-type.model';
import { ApiService } from './api.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class AttendeesTypesApiService {
    private endpointMulti = 'attendeetypesregion';
    private endpointSingle = 'attendeetypesregion';
    private endpointAttendeeTypes = 'attendeetypes';
    private requestHeader = {
        headers: { Authorization: this.user.token }
    };

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public search(ignoreRegion: number[] = []) {
        const params = {};
        if (ignoreRegion) {
            params['ignore_region'] = ignoreRegion;
        }

        return this.api.post(this.endpointMulti + '/search/' + this.user.user.id, params, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public info(attendeesTypeId: number) {
        return this.api.get(this.endpointSingle + '/' + attendeesTypeId.toString(), {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res.data;
                    }
                )
            );
    }

    public delete(attendeesTypeId: number) {
        return this.api.patch(this.endpointSingle + '/delete/' +
            attendeesTypeId.toString(), {}, { headers: { Authorization: this.user.token } });
    }

    public createOrEdit(attendeesType: AttendeeTypeModel) {
        /*const attendeesTypeObject = {
            name: attendeesType.name
        };*/

        const url = this.endpointSingle + '/save/';

        if (attendeesType.id) {
            // url = url + '/' + attendeesType.id.toString();
            return this.api.post(url, attendeesType, { headers: { Authorization: this.user.token } });
        }
        else {
            /*attendeesTypeObject['global_id'] = attendeesType.global.id;
            attendeesTypeObject['global_name'] = attendeesType.global.name;*/
            delete attendeesType.id;
            return this.api.post(url, attendeesType, { headers: { Authorization: this.user.token } });
        }

        // return this.api.post(url, attendeesTypeObject, {headers: {Authorization: this.user.token}});
    }

    public getGlobals() {
        const params = {};

        return this.api.get(this.endpointAttendeeTypes + '/list', params, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public getByCountry(countryId: number) {
        return this.api.get(this.endpointMulti + '/attendeeTypeByCountry/' + countryId, {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public createGlobal(global) {
        return this.api.post(`${this.endpointAttendeeTypes}/add`, global, this.requestHeader);
    }
}
