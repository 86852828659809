import * as moment from 'moment';
import { ActivityTypeModel } from './activity-type.model';
import { BrandModel } from './brand.model';
import { ProgramModel } from './program.model';
import { TargetTypeModel } from './target-type.model';
import { UserModel } from './user.model';
import { WinningInModel } from './winning-in.model';
import { CountryModel } from './country.model';

export class TargetModel {
    public id: number;
    public name: string;
    public user: UserModel;
    public country: CountryModel;
    public activityType: ActivityTypeModel;
    public brand: BrandModel;
    public program: ProgramModel;
    public targetType: TargetTypeModel;
    public winningIn: WinningInModel;
    public value: number;
    public fromDate: string;
    public toDate: string;
    public comment: string;
    public createdBy: UserModel;
    public createdOn: moment.Moment;
    public lastUpdateOn: moment.Moment;
    public deleted: boolean;
    public progress: number;
    public progressPct: number;
    public saved = 'Yes';

    private defaultFormat = 'MM/DD/YYYY';

    static fromObject(values: TargetModel): TargetModel
    {
        const c = new TargetModel({});
        return Object.assign(c, values);
    }

    public get from()
    {
        return moment(this.fromDate);
    }

    public set from(date)
    {
        this.fromDate = date.format();
    }

    public get to()
    {
        return moment(this.toDate);
    }

    public set to(date)
    {
        this.toDate = date.format();
    }

    public fromJson(jsonData: any)
    {
        this.id = jsonData.ID ? Number.parseInt(jsonData.ID, 10) : null;
        this.value = Number.parseInt(jsonData.target_value, 10);
        this.fromDate = moment(jsonData.target_from, 'YYYY-MM-DD HH:mm:ss').format(this.defaultFormat);
        this.toDate = moment(jsonData.target_to, 'YYYY-MM-DD HH:mm:ss').format(this.defaultFormat);
        this.createdOn = moment(jsonData.created_on, 'YYYY-MM-DD HH:mm:ss');
        this.lastUpdateOn = moment(jsonData.last_update_on, 'YYYY-MM-DD HH:mm:ss');
        this.comment = jsonData.comment;
        this.deleted = jsonData.deleted === '1';
        this.name = jsonData.target_name ? jsonData.target_name : jsonData.name;
        this.progress = (jsonData.progress) ? Number.parseInt(jsonData.progress, 10) : 0;
        this.progressPct = (jsonData.progress_pct) ? Number.parseInt(jsonData.progress_pct, 10) : 0;

        if (this.user !== undefined)
        {
            this.user.fromJson({ ID: jsonData.user_id});
        }
        else
        {
            this.user = new UserModel({ID: jsonData.user_id});
        }

        if (this.brand !== undefined)
        {
            this.brand.fromJson({ ID: jsonData.brand_id, name: jsonData.brand_name});
        }
        else
        {
            this.brand = new BrandModel({ID: jsonData.brand_id, name: jsonData.brand_name});
        }

        if (this.program !== undefined)
        {
            this.program.fromJson({ ID: jsonData.program_id, name: jsonData.program_name});
        }
        else
        {
            this.program = new ProgramModel({ID: jsonData.program_id, name: jsonData.program_name});
        }

        if (this.targetType !== undefined)
        {
            this.targetType.fromJson({ ID: jsonData.target_type_id});
        }
        else
        {
            this.targetType = new TargetTypeModel({ID: jsonData.target_type_id, name: jsonData.target_type_name});
        }


        if (this.createdBy !== undefined)
        {
            this.createdBy.fromJson({ ID: jsonData.created_by});
        }
        else
        {
            this.createdBy = new UserModel({ID: jsonData.created_by});
        }

    }

    constructor(jsonData: any) {
        this.fromJson(jsonData);
    }

}
