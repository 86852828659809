export class LanguageModel {
    public id: string;
    public name: string;

    public fromJson(jsonData: any)
    {
        this.id = jsonData.ID ? jsonData.ID : null;
        this.name = jsonData.name;
    }

    constructor(jsonData: any) {
        this.fromJson(jsonData);
    }
}
