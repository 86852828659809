import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandCategoryModel } from 'src/app/models/brand-category.model';
import { BrandModel } from 'src/app/models/brand.model';
import { RegionModel } from 'src/app/models/region.model';
import { FormPage } from 'src/app/pages/FormPage';
import { BrandsService } from 'src/app/providers/brands.service';
import { GlobalMessage } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-brand-edit.page',
    templateUrl: './brand-edit.page.html'
})
export class BrandEditPage extends FormPage implements OnInit {
    public brandForm = this.fb.group({
        brandId: [''],
        name: ['', Validators.required],
        brandCategory: ['', Validators.required],
        global: ['', Validators.required],
    });

    public brand: BrandModel;
    public brandCategories: BrandCategoryModel[] = null;
    public brandId: number = null;
    public regions: RegionModel[] = null;

    constructor(
        private fb: FormBuilder,
        public brandService: BrandsService,
        private route: ActivatedRoute,
        private errorHandler: GlobalMessage,
        private router: Router
    ) {
        super();
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null) {
                this.brandId = Number.parseInt(params.get('id'), 10);

                this.brandService.info(this.brandId).subscribe(
                    (data) => {
                        this.brand = data;

                        this.brandForm.patchValue({
                            brandId: data.id,
                            name: data.name,
                            brandCategory: data.brandCategory || null,
                            global: data.brandGlobal.id || null,
                        });
                    }
                );
            }
        });
    }

    createBrand(brandFormValue: any) {
        this.brandService.create(new BrandModel({
            name: brandFormValue.name,
            brandCategory: brandFormValue.brandCategory,
            brandGlobal: brandFormValue.global
        })).subscribe((data: any) => {
            this.showResponse(data);
        });
    }

    getForm(): FormGroup {
        return this.brandForm;
    }

    onSubmit() {
        if (this.brandForm.valid) {
            const brandFormValue = this.brandForm.value;
            brandFormValue.brandId ? this.updateBrand(brandFormValue) : this.createBrand(brandFormValue);
        } else {
            this.validateAllFormFields(this.brandForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    updateBrand(brandFormValue: any) {
        const brandUpdated = this.brand;
        brandUpdated.name = brandFormValue.name;
        brandUpdated.brandCategory = brandFormValue.brandCategory;

        this.brandService.edit(new BrandModel(brandUpdated)).subscribe((data: any) => {
            this.showResponse(data);
        });
    }

    private showResponse(data: any): void {
        if (data) {
            this.errorHandler.showSuccess('Your brand has been saved successfully', 5000);
            this.router.navigate(['/workspace/brands']);
        } else {
            this.errorHandler.showError(data.error.error_msg);
        }
    }
}
