import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BrandCategoriesService } from 'src/app/providers/brand-categories.service';
import { BrandCategoryModel } from 'src/app/models/brand-category.model';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BrandCategorySelectComponent),
    multi: true,
};
@Component({
    selector: 'app-brand-category-select',
    templateUrl: './brand-category-select.component.html',
    providers: [CUSTOM_VALUE_ACCESSOR]
})
export class BrandCategorySelectComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    public disabled = false;
    public value: any = null;
    public brandCategories: BrandCategoryModel[];
    private onChange: Function = (_: any) => { };
    private onTouched: Function = () => { };

    constructor(private brandCategoryService: BrandCategoriesService) { }

    ngOnInit() {
        this.brandCategoryService.list().subscribe(data => {
            this.brandCategories = data;
        });
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    brandCategoryChange(ev) {
        this.value = this.brandCategories.find(i => i.id === ev.target.value);
        this.onChange(this.value);
        this.onTouched();
    }
}
