export class EventModel {
    public id: number;
    public type: string;
    public ident: string;
    public table_relationship: string;
    public date_time: Date;
    constructor(type, ident, table_relationship, date_time) {
        this.type = type;
        this.ident = ident;
        this.table_relationship = table_relationship;
        this.date_time = date_time;
    }
}
