import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProgramsApiService } from './programs-api.service';
import { ProgramModel } from '../models/program.model';
import { GlobalId } from './global-id.interface';
import { GlobalModel } from '../models/global.model';

@Injectable({
    providedIn: 'root'
})
export class ProgramsService implements GlobalId {
    constructor(
        private programsApi: ProgramsApiService
    ) { }

    public list(brandId: number = 0, loadPastPrograms: boolean = false) {
        const programs: ProgramModel[] = [];
        return this.programsApi.list(brandId, loadPastPrograms)
            .pipe((res: any) => res);
    }

    public info(programId: number): Observable<ProgramModel> {
        return this.programsApi.info(programId).pipe(
            (res: any) => res
        );
    }

    public delete(program: ProgramModel) {
        return this.programsApi.delete(program.id);
    }

    public createOrEdit(program: ProgramModel) {
        return this.programsApi.createOrEdit(program);
    }

    public getGlobals() {
        const globals: GlobalModel[] = [];
        return this.programsApi.getGlobals().pipe(
            map((data: any) => {
                if (data) {
                    data.forEach(element => globals.push(new GlobalModel(element)));
                }
                return globals;
            })
        );
    }

    public search(params, paramTable?) {
        return this.programsApi.search(params, paramTable);
    }

    public getByBrand(brandId: number): Observable<any> {
        return this.programsApi.getByBrand(brandId);
    }

    public getByRegionOfBrand(brandId: number): Observable<any> {
        return this.programsApi.getByRegionOfBrand(brandId);
    }

    public createGlobal(global) {
        return this.programsApi.createGlobal(global);
    }
}
