import * as moment from 'moment';
import { RegionModel } from './region.model';
import { ClusterModel } from './cluster.model';
import { CountryModel } from './country.model';
import { WinningInModel } from './winning-in.model';
import { POSSegmentModel } from './pos-segment.model';
import { ActivitySegmentModel } from './activity-segment.model';
import { BrandCategoryModel } from './brand-category.model';
import { BrandModel } from './brand.model';
import { ProgramModel } from './program.model';
import { StatisticsTypeModel } from './statistics-type.model';
import { ActivityTypeModel } from './activity-type.model';

export enum StatisticsTypeEnum {
    ACTIVITY_TYPE = 'activity_type',
    WINNING_IN = 'winning_in',
    ACTIVITY_SEGMENT = 'act_segment',
    BRAND = 'brands'
}

export class StatisticsFilterModel {
    constructor(
        public type?: StatisticsTypeModel,
        public users?: number[],
        public from?: moment.Moment,
        public to?: moment.Moment,
        public activityType?: ActivityTypeModel,
        public region?: RegionModel,
        public cluster?: ClusterModel,
        public country?: CountryModel,
        public winningIn?: WinningInModel,
        public posSegment?: POSSegmentModel,
        public activitySegment?: ActivitySegmentModel,
        public brandCategory?: BrandCategoryModel,
        public brand?: BrandModel,
        public program?: ProgramModel,
    ) {
    }

    clone(): StatisticsFilterModel
    {
        const c = new StatisticsFilterModel();
        return Object.assign(c, this);
    }
}
