import { GlobalModel } from './global.model';
import { RegionModel } from './region.model';

export class ContactTitleModel {
    public id: number;
    public name?: string;
    public region?: RegionModel;
    public deleted?: boolean;
    public personTitleGlobal?: GlobalModel;
    public createDate?: Date;
    public createdBy?: string;


    constructor(contactTitleObjc: Partial<ContactTitleModel>) {
        this.id = contactTitleObjc.id;
        this.name = contactTitleObjc.name;
        this.region = contactTitleObjc.region || new RegionModel({});
        this.deleted = contactTitleObjc.deleted;
        this.personTitleGlobal = contactTitleObjc.personTitleGlobal;
        this.createDate = contactTitleObjc.createDate || new Date();
        this.createdBy = contactTitleObjc.createdBy || 'c786968532d8481482ac60bb9190ab73';
    }
}
