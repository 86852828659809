import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Fetchable } from './fetchable.interface';
import { ActivitiesApiService } from './activities-api.service';
import { Observable } from 'rxjs';
import { ActivityModel } from '../models/activity.model';
import { ActivityTypeModel } from '../models/activity-type.model';
import { OutletModel } from '../models/outlet.model';
import { BrandCategoryModel } from '../models/brand-category.model';
import { BrandModel } from '../models/brand.model';
import { ProgramModel } from '../models/program.model';
import { CountryModel } from '../models/country.model';
import { WinningInModel } from '../models/winning-in.model';
import { AttendeeContactModel } from '../models/attendee-contact.model';

@Injectable({
    providedIn: 'root'
})
export class ActivitiesService implements Fetchable {

    constructor(
        private activitiesApi: ActivitiesApiService
    ) { }

    public list(resultsNum: number = 20,
        offset: number = 0,
        search: string = '',
        orderBy: string = 'date',
        order: string = 'ASC',
        ambassadorId: number = null)
    {
        return this.activitiesApi.list(resultsNum, offset, search, orderBy, order, ambassadorId)
            .pipe(
                map(res => res)
            );
    }

    public info(activityId: number): Observable<ActivityModel>
    {
        return this.activitiesApi.info(activityId)
        .pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }

    public createOrEdit(activity: ActivityModel)
    {
        return this.activitiesApi.createOrEdit(activity);
    }

    public delete(activity: ActivityModel)
    {
        return this.activitiesApi.delete(activity.id);
    }

    public populate(activity: ActivityModel)
    {
        return this.activitiesApi.info(activity.id)
        .pipe(
            map( data => {
                activity.fromJson(data);
                return activity;
            })
        );
    }

    public getActivityTypes(): Observable<ActivityTypeModel[]>
    {
        return this.activitiesApi.getActivityTypes()
        .pipe(
            map( res => {
                const activityTypes: ActivityTypeModel[] = [];
                if (res.data)
                {
                    res.data.forEach( atm => {
                        activityTypes.push(new ActivityTypeModel(atm));
                    });
                }
                return activityTypes;
            })
        );
    }

    public getOutletInfo(outletId: number): Observable<OutletModel>
    {
        return this.activitiesApi.getOutletInfo(outletId)
        .pipe(
            map( res => {
                if (res.data)
                {
                    return new OutletModel(res.data);
                }
                return null;
            })
        );
    }

    public getBrands(brandCategory: BrandCategoryModel, countryId: number): Observable<BrandModel[]>
    {
        return this.activitiesApi.getBrands((brandCategory) ? brandCategory.id : null, countryId)
        .pipe(
            map( res => {
                const brands: BrandModel[] = [];
                if (res.data)
                {
                    res.data.forEach( b => {
                        brands.push(new BrandModel(b));
                    });
                }
                return brands;
            })
        );
    }

    public getBrandPrograms(brand: BrandModel, countryId: number): Observable<ProgramModel[]>
    {
        return this.activitiesApi.getBrandPrograms(brand.id, countryId)
        .pipe(
            map( res => {
                const brandPrograms: ProgramModel[] = [];
                if (res.data)
                {
                    res.data.forEach( bp => {
                        brandPrograms.push(new ProgramModel(bp));
                    });
                }

                return brandPrograms;
            })
        );
    }

    public getCountries(): Observable<CountryModel[]>
    {
        return this.activitiesApi.getCountries()
        .pipe(
            map( res => {
                const countries: CountryModel[] = [];
                if (res)
                {
                    res.forEach( c => {
                        countries.push(new CountryModel(c));
                    });
                }

                return countries;
            })
        );
    }

    public getWinningIns(countryId: number): Observable<WinningInModel[]>
    {
        return this.activitiesApi.getWinningIns(countryId)
        .pipe(
            map( res => {
                const winningIns: WinningInModel[] = [];
                if (res.data)
                {
                    res.data.forEach( w => {
                        winningIns.push(new WinningInModel(w));
                    });
                }

                return winningIns;
            })
        );
    }

    public getAttendeesContacts(countryId: number, attendeesTypeId: number): Observable<AttendeeContactModel[]>
    {
        return this.activitiesApi.getAttendeesContacts(countryId, attendeesTypeId)
        .pipe(
            map( res => {
                const attendeesContacts: AttendeeContactModel[] = [];
                if (res.data)
                {
                    res.data.forEach( a => {
                        attendeesContacts.push(new AttendeeContactModel(a));
                    });
                }

                return attendeesContacts;
            })
        );
    }

    public export(ambassadorId: number, searchStr: string = ''): Observable<string>
    {
        return this.activitiesApi.export(ambassadorId, searchStr);
    }

    public exportAll(): Observable<string> {
        return this.activitiesApi.exportAll();
    }
}
