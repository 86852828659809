import { AfterViewInit, Component, ContentChild, OnInit, TemplateRef, AfterContentInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandModel } from 'src/app/models/brand.model';
import { ProgramModel } from 'src/app/models/program.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { BrandsService } from 'src/app/providers/brands.service';
import { ProgramsService } from 'src/app/providers/programs.service';

@Component({
    selector: 'app-programs',
    templateUrl: './program-list.page.html'
})
export class ProgramListPage extends DatatablePage implements OnInit, AfterContentInit, AfterViewInit {
    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    public _brandId: number = null;
    public programs: ProgramModel[];
    public brands: BrandModel[];
    private params = {
        'brandRegionDTOId': null,
        'searchStr': ''
    };

    constructor(
        private programService: ProgramsService,
        private brandService: BrandsService,
        private modalService: NgbModal
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();

        this.brandService.search().subscribe((data: any) => {
            this.brands = data.content;
        });

        if (sessionStorage.getItem('programByBrand')) {
            this._brandId = Number(sessionStorage.getItem('programByBrand'));
            this.params.brandRegionDTOId = Number(sessionStorage.getItem('programByBrand'));
        }

        this.dtOptions.pagingType = 'full_numbers';
        this.dtOptions.serverSide = true;
        this.dtOptions.processing = true;

        const that = this;

        this.dtOptions.ajax = (dataTablesParameters: any, callback) => {

            this.params.searchStr = dataTablesParameters.search.value;

            const paramTablet = {
                resultsNum: dataTablesParameters.length,
                offset: dataTablesParameters.start,
                orderBy: dataTablesParameters.order[0].column,
                order: dataTablesParameters.order[0].dir
            };
            that.programService.search(this.params, paramTablet).subscribe(
                data => {
                    if (data && data.content) {
                        that.programs = data.content;
                        if (data.totalElements) {
                            callback(
                                {
                                    recordsTotal: data.totalElements,
                                    recordsFiltered: data.totalElements,
                                    data: []
                                }
                            );
                        }
                    } else {
                        callback(
                            {
                                recordsTotal: 0,
                                recordsFiltered: 0,
                                data: []
                            }
                        );
                    }
                }
            );
        };
    }

    ngAfterContentInit() {
    }

    ngAfterViewInit() {
        this.refreshDt();
    }

    delete(content, programId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result) {
                this.programService.delete(this.programs.find(a => a.id === programId)).subscribe(
                    () => { this.reRenderDt(); }
                );
            }

        }, (reason) => {
        });
    }

    getBrandName(id: number) {
        if (this.brands) {
            const o = this.brands.find(value => value.id === id);
            return (o) ? o.name : '';
        }
    }

    public set brandId(value) {
        this._brandId = +value;
        this.params.brandRegionDTOId = this._brandId;

        if (this._brandId) {
            sessionStorage.setItem('programByBrand', JSON.stringify(this._brandId));
        }
        else {
            sessionStorage.removeItem('programByBrand');
        }
        this.reRenderDt();
    }

    public get brandId() {
        return this._brandId;
    }
}
