import { AfterViewInit, Component, ContentChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityTypeModel } from 'src/app/models/activity-type.model';
import { ActivityTypesService } from 'src/app/providers/activity-types.service';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { DatatablePage } from '../../../../DatatablePage';

@Component({
    selector: 'app-activity-types',
    templateUrl: './activity-type-list.page.html'
})
export class ActivityTypeListPage extends DatatablePage implements AfterViewInit
{
    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    public activityTypes: ActivityTypeModel[];
    public activityType: ActivityTypeModel;
    public editingId: number;
    private editingValue: string;

    constructor(
        private activityTypeService: ActivityTypesService,
        private modalService: NgbModal,
        private messageHandler: GlobalMessage
    )
    {
        super();
    }

    ngAfterViewInit() {
        this.refreshList();
        this.refreshDt();
    }

    refreshList() {
        this.activityTypeService.search().subscribe(data => {
            this.activityTypes = data;
            this.reRenderDt();
        });
    }

    delete(content, activityTypeId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result)
            {
                this.activityTypeService.delete(this.activityTypes.find( a => a.id === activityTypeId)).subscribe(
                    x => {
                        this.refreshList();
                    }
                );
            }

        }, (reason) => {
        });
    }

    startEdit(activityType: ActivityTypeModel)
    {
        this.editingId = activityType.id;
        this.activityType = activityType;
        // this.editingValue = this.activityTypes.find(at => at.id === activityTypeId).name;
    }

    validateEdit()
    {
        this.activityTypeService.createOrEdit(this.activityType)
        .subscribe((r: Response) => {
            if (r === null)
            {
                this.editingId = 0;
                this.editingValue = '';
                this.refreshList();
                this.reRenderDt();
                this.messageHandler.showSuccess('The activity type has been saved successfully', 5000);
            }
            else
            {
                this.messageHandler.showError('error');
            }
        });
    }
}
