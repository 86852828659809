import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ContactHobbiesApiService } from './contact-hobbies-api.service';
import { Observable } from 'rxjs';
import { ContactHobbyModel } from '../models/contact-hobby.model';
import { GlobalModel } from '../models/global.model';
import { GlobalId } from './global-id.interface';

@Injectable({
    providedIn: 'root'
})
export class ContactHobbiesService implements GlobalId {
    constructor(
        private contactHobbiesApi: ContactHobbiesApiService
    ) { }

    public search(size?: number, page?: number) {
        const contactHobbies: ContactHobbyModel[] = [];
        return this.contactHobbiesApi.search(size, page).pipe(
            map(l => {
                if (l && l.content) {
                    l.content.forEach(element => contactHobbies.push(new ContactHobbyModel(element)));
                }
                return contactHobbies;
            })
        );
    }

    public info(contactHobbyId: number): Observable<ContactHobbyModel> {
        return this.contactHobbiesApi.info(contactHobbyId).pipe(
            map(res => new ContactHobbyModel(res))
        );
    }

    public delete(contactHobby: ContactHobbyModel) {
        return this.contactHobbiesApi.delete(contactHobby.id);
    }

    public createOrEdit(contactHobby: ContactHobbyModel) {
        return this.contactHobbiesApi.createOrEdit(contactHobby);
    }

    public getGlobals() {
        let globals: GlobalModel[] = [];
        return this.contactHobbiesApi.getGlobals().pipe(
            map(l => {
                if (l) {
                    l.forEach(element => globals.push(new GlobalModel(element)));
                }
                else {
                    globals = [];
                }
                return globals;
            })
        );
    }

    public createGlobal(global) {
        return this.contactHobbiesApi.createGlobal(global);
    }
}
