import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactHobbyModel } from 'src/app/models/contact-hobby.model';
import { FormPage } from 'src/app/pages/FormPage';
import { ContactHobbiesService } from 'src/app/providers/contact-hobbies.service';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { GlobalModel } from 'src/app/models/global.model';
import { UserService } from '../../../../../providers/user.service';

@Component({
    selector: 'app-hobby-edit.page',
    templateUrl: './hobby-edit.page.html'
})
export class HobbyEditPage extends FormPage implements OnInit {
    public hobbyForm = this.fb.group({
        hobbyId: [''],
        name: ['', Validators.required],
        global: ['', Validators.required],
    });

    public hobbyId: number = null;

    constructor(
        private fb: FormBuilder,
        private hobbyService: ContactHobbiesService,
        private route: ActivatedRoute,
        private router: Router,
        private errorHandler: GlobalMessage,
        private userService: UserService) {
        super();
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null) {
                this.hobbyId = Number.parseInt(params.get('id'), 10);
                this.hobbyService.info(Number.parseInt(params.get('id'), 10)).subscribe(
                    (data) => {
                        this.hobbyForm.setValue({
                            hobbyId: data.id,
                            name: data.name,
                            global: data.hobbyGlobal ? data.hobbyGlobal.id : null,
                        });
                    }
                );
            }
        });
    }

    onSubmit() {
        if (this.hobbyForm.valid) {
            const hobby = this.hobbyForm.value;
            const hobbyGlobal = new GlobalModel({ id: hobby.global.id, name: hobby.global.name });
            this.hobbyService.createOrEdit(new ContactHobbyModel({
                id: hobby.hobbyId,
                name: hobby.name,
                hobbyGlobal: hobbyGlobal,
                region: this.userService.user.defaultRegion,
            })).subscribe((data: any) => {
            }, (error) => {
                this.errorHandler.showError('Form contains errors');
            }, () => {
                this.errorHandler.showSuccess('Your hobby has been saved successfully', 5000);
                this.router.navigate(['/workspace/hobbies']);

            });
        }
        else {
            this.validateAllFormFields(this.hobbyForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.hobbyForm;
    }
}
