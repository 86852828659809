import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LanguagesService } from 'src/app/providers/languages.service';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide : NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LanguageSelectComponent),
    multi : true,
};
@Component({
    selector: 'app-language-select',
    templateUrl: './language-select.component.html',
    providers : [CUSTOM_VALUE_ACCESSOR]
})
export class LanguageSelectComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    public disabled = false;
    public value: string = null;
    public languages: any[];
    private onChange: Function = (_: any) => {};
    private onTouched: Function = () => {};

    constructor(private languageService: LanguagesService) {}

    ngOnInit() {
        this.languageService.list().subscribe(data => {
            this.languages = data;
        });
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    languageChange(ev)
    {
        this.value = ev.target.value;
        this.onChange(this.value);
        this.onTouched();
    }
}
