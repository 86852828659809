import { ClusterModel } from './cluster.model';
import { RegionModel } from './region.model';

export class CountryModel {
    public id: number;
    public cluster?: ClusterModel;
    public code?: string;
    public deleted?: boolean;
    public name?: string;
    public region?: RegionModel;
    public createDate?: Date;
    public createdBy?: string;
    public updateDate?: Date;
    public updatedBy?: string;

    constructor(contryObj: Partial<CountryModel>) {
        this.id = contryObj.id;
        this.cluster = contryObj.cluster;
        this.code = contryObj.code;
        this.deleted = contryObj.deleted;
        this.name = contryObj.name;
        this.region = contryObj.region;
        this.createDate = contryObj.createDate || new Date();
        this.createdBy = contryObj.createdBy || 'c786968532d8481482ac60bb9190ab73';
        this.updateDate = contryObj.updateDate || new Date();
        this.updatedBy = contryObj.updatedBy || 'c786968532d8481482ac60bb9190ab73';
    }
}
