export class ClusterModel {
    public id: number;
    public code: string;
    public name: string;

    constructor(cluster: Partial<ClusterModel>) {
        this.id = cluster.id;
        this.code = cluster.code;
        this.name = cluster.name;
    }
}
