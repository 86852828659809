import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { InfluencerLevelModel } from '../models/influencer-level.model';
import { InfluencerLevelsApiService } from './influencer-levels-api.service';
import { GlobalModel } from '../models/global.model';
import { Observable } from 'rxjs';
import { GlobalId } from './global-id.interface';

@Injectable({
    providedIn: 'root'
})
export class InfluencerLevelsService implements GlobalId {
    constructor(
        private influencerLevelsApi: InfluencerLevelsApiService
    ) { }

    public search(params: any, size?: number, page?: number) {
        const influencerLevels: InfluencerLevelModel[] = [];
        return this.influencerLevelsApi.search(params, size, page).pipe(
            map(l => {
                if (l && l.content) {
                    l.content.forEach(element => influencerLevels.push(new InfluencerLevelModel(element)));
                }
                return influencerLevels;
            })
        );
    }

    public info(influencerLevelId: number): Observable<InfluencerLevelModel> {
        return this.influencerLevelsApi.info(influencerLevelId).pipe(
            map(res => new InfluencerLevelModel(res))
        );
    }

    public delete(influencerLevel: InfluencerLevelModel) {
        return this.influencerLevelsApi.delete(influencerLevel.id);
    }

    public createOrEdit(influencerLevel: InfluencerLevelModel) {
        return this.influencerLevelsApi.createOrEdit(influencerLevel);
    }

    public getGlobals() {
        const globals: GlobalModel[] = [];
        return this.influencerLevelsApi.getGlobals().pipe(
            map(l => {
                l.forEach(element => globals.push(new GlobalModel(element)));
                return globals;
            })
        );
    }

    public createGlobal(global) {
        return this.influencerLevelsApi.createGlobal(global);
    }
}
