import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrandModel } from '../models/brand.model';
import { BrandsApiService } from './brands-api.service';
import { GlobalId } from './global-id.interface';
import { BrandCategoriesService } from './brand-categories.service';

@Injectable({
    providedIn: 'root'
})
export class BrandsService implements GlobalId {

    constructor(
        private brandsApiService: BrandsApiService,
        private brandCategoriesService: BrandCategoriesService
    ) { }

    public list() {
        return this.brandsApiService.list();
    }

    public search(searchStr?, countryId?, brandCategoryId?) {
        return this.brandsApiService.search(searchStr, countryId, brandCategoryId);
    }

    public info(brandId: number): Observable<BrandModel> {
        return this.brandsApiService.info(brandId).pipe(
            map(res => new BrandModel(res))
        );
    }

    public delete(brand: BrandModel) {
        return this.brandsApiService.delete(brand.id);
    }

    public create(brand: BrandModel, loadPastPrograms: boolean = false) {
        return this.brandsApiService.create(brand, loadPastPrograms);
    }

    public edit(brand: BrandModel, loadPastPrograms: boolean = false) {
        return this.brandsApiService.edit(brand, loadPastPrograms);
    }

    public getGlobals() {
        return this.brandsApiService.getGlobals();
    }

    public createGlobal(global) {
        return this.brandsApiService.createGlobal(global);
    }

    public getByBrandCategory(brandCategoryId: number, countryId: number): Observable<any> {
        return this.brandsApiService.getByBrandCategory(brandCategoryId, countryId);
    }
}
