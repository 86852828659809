import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HotTableComponent } from 'ng2-handsontable';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivityTypeModel } from 'src/app/models/activity-type.model';
import { BrandModel } from 'src/app/models/brand.model';
import { ProgramModel } from 'src/app/models/program.model';
import { TargetTypeModel } from 'src/app/models/target-type.model';
import { TargetModel } from 'src/app/models/target.model';
import { UserModel } from 'src/app/models/user.model';
import { WinningInModel } from 'src/app/models/winning-in.model';
import { ActivitiesService } from 'src/app/providers/activities.service';
import { BrandsService } from 'src/app/providers/brands.service';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { ProgramsService } from 'src/app/providers/programs.service';
import { TargetsService } from 'src/app/providers/targets.service';
import { UserService } from 'src/app/providers/user.service';
import { UsersService } from 'src/app/providers/users.service';
import { CountriesService } from 'src/app/providers/countries.service';
import { CountryModel } from 'src/app/models/country.model';

@Component({
    selector: 'app-targets',
    templateUrl: './target-list.page.html',
    styleUrls: ['./target-list.page.scss']
})
export class TargetListPage implements OnInit {
    @ViewChild('deleteModal', null) deleteModal;
    @ViewChild(HotTableComponent, null) hotTableComponent;
    public editing = {};

    private users: UserModel[] = [];
    private countries: CountryModel[] = [];
    private activityTypes: ActivityTypeModel[] = [];
    private brands: BrandModel[] = [];
    private programs: ProgramModel[] = [];
    private targetTypes: TargetTypeModel[] = [];
    public targets: TargetModel[] = [];
    private winningIns: WinningInModel[] = [];
    public hasChanges = false;
    public ignoreValidity = false;
    public onlyMyTargets = false;
    private loggedUser: UserModel;

    private _countryId: number = null;

    private toDelete;

    /**************/
    /** ROW INDEX */
    private INDEX_USER = 1;
    private INDEX_COUNTRY = 2;
    private INDEX_TYPE = 3;
    private INDEX_ACTIVITY = 4;
    private INDEX_BRAND = 5;
    private INDEX_PROGRAM = 6;
    private INDEX_WINNING_IN = 7;
    /**************/

    private PLACEHOLDER_COUNTRY = 'Choose a user first';
    private PLACEHOLDER_BRAND = 'Choose a country first';
    private PLACEHOLDER_PROGRAM = 'Choose a brand first';

    public colHeaders: string[] = ['Name', 'User', 'Country', 'Target type', 'Activity', 'Brand',
        'Program', 'Winning In', 'Value', 'From', 'To', 'Progress', 'Comment', 'Saved'];
    public columns: any[] = [
        {
            data: 'name'
        },
        {
            data: 'user.name',
            type: 'dropdown',
            source: []
        },
        {
            data: 'country.name',
            type: 'dropdown',
            placeholder: this.PLACEHOLDER_COUNTRY,
            width: 100,
            source: []
        },
        {
            data: 'targetType.name',
            type: 'dropdown',
            source: []
        },
        {
            data: 'activityType.name',
            type: 'dropdown',
            source: []
        },
        {
            data: 'brand.name',
            type: 'dropdown',
            placeholder: this.PLACEHOLDER_BRAND,
            source: []
        },
        {
            data: 'program.name',
            type: 'dropdown',
            placeholder: this.PLACEHOLDER_PROGRAM,
            source: []
        },
        {
            data: 'winningIn.city',
            type: 'dropdown',
            source: []
        },
        {
            data: 'value'
        },
        {
            data: 'fromDate',
            type: 'date',
            dateFormat: 'MM/DD/YYYY',
            correctFormat: true,
        },
        {
            data: 'toDate',
            type: 'date',
            dateFormat: 'MM/DD/YYYY',
            correctFormat: true,
        },
        {
            data: 'progress',
            renderer: 'numeric',
            readOnly: true
        },
        {
            data: 'comment'
        },
        {
            data: 'saved',
            readOnly: true,
            renderer: function (instance, td: HTMLTableDataCellElement, row, col, prop, value, cellProperties) {
                td.innerText = value;

                if (value === 'Yes') {
                    td.style.backgroundColor = 'green';
                    td.style.color = 'white';
                }
                else if (value === 'No') {
                    td.style.backgroundColor = 'orange';
                    td.style.color = 'white';
                }
                else if (value) {
                    td.style.backgroundColor = 'red';
                    td.style.color = 'white';
                }
            }
        }
    ];
    public options: any = {
        stretchH: 'all',
        columnSorting: true,
        minSpareRows: 10,
        manualColumnResize: true,
        contextMenu: [
            'row_above', 'row_below', 'remove_row'
        ],
        mergeCells: false,
        readOnly: !this.userService.user.isAdmin,
        allowInsertRow: true,
        fillHandle: 'vertical'
    };

    constructor(
        private targetService: TargetsService,
        private usersService: UsersService,
        private activityService: ActivitiesService,
        private brandService: BrandsService,
        private programService: ProgramsService,
        private modalService: NgbModal,
        private globalMessage: GlobalMessage,
        private userService: UserService,
        private countryService: CountriesService,
        private ngxService: NgxUiLoaderService
    ) {
        this.loggedUser = UserModel.fromObject(this.userService.user);
    }

    ngOnInit() {
        if (this.loggedUser.isAdmin) {
            this.usersService.list(undefined, this.loggedUser.id).subscribe(data => {
                this.users = data;
                data.map(value => value.name).forEach(v => this.columns[this.INDEX_USER].source.push(v));
            });

            this.countryService.list(9999).subscribe(data => {
                this.countries = data;
            });
        }
        else {
            this.columns[this.INDEX_USER].source.push(this.loggedUser);
            this.countries = this.loggedUser.countries;
        }

        this.activityService.getActivityTypes().subscribe(data => {
            this.activityTypes = data;
            data.forEach(a => this.columns[this.INDEX_ACTIVITY].source.push(a.name));
            this.columns[this.INDEX_ACTIVITY].source.unshift(' ');
        });
        this.brandService.search().subscribe((data: any) => {
            this.brands = data.content;
            data.forEach(b => this.columns[this.INDEX_BRAND].source.push(b.name));
            this.columns[this.INDEX_BRAND].source.unshift(' ');
        });
        this.programService.list().subscribe((data: any) => {
            this.programs = data;
            data.forEach(p => this.columns[this.INDEX_PROGRAM].source.push(p.name));
            this.columns[this.INDEX_PROGRAM].source.unshift(' ');
        });
        this.targetService.getTypes().subscribe(data => {
            this.targetTypes = data;
            data.forEach(a => this.columns[this.INDEX_TYPE].source.push(a.name));
        });
        this.activityService.getWinningIns(this.loggedUser.defaultCountryId).subscribe(data => {
            this.winningIns = data;
            data.forEach(a => this.columns[this.INDEX_WINNING_IN].source.push(a.city));
            this.columns[this.INDEX_WINNING_IN].source.unshift(' ');
        });

        this.refreshList();
    }

    onAfterInit(event) {
        const hotInstance = this.hotTableComponent.getHandsontableInstance();
        hotInstance.addHook('beforeRemoveRow', (index, amout, physicalRows, source) => {
            if (this.targets[physicalRows] && this.targets[physicalRows].saved === 'Yes') {
                this.toDelete = physicalRows;
                this.delete();
            }
            return false;
        });

        hotInstance.addHook('beforePaste', (data, coords) => {
            this.unlockCells(hotInstance);
        });
        hotInstance.addHook('afterPaste', (data, coords) => {
            for (let i = 0; i <= hotInstance.countRows(); i++) {
                this.adjustCellsMeta(i);
            }
        });
        hotInstance.addHook('beforeAutofill', (start, end, data) => {
            this.unlockCells(hotInstance);
        });
    }

    onAfterLoadData(event) {
        this.refreshHandsontable();
    }

    refreshList() {
        this.ngxService.start();
        this.targetService.list(this.ignoreValidity, !this.onlyMyTargets, this.countryId).subscribe(data => {
            this.targets = data.filter(value => !value.deleted);
            this.targets.forEach(t => {
                if (t.winningIn && t.winningIn.id) {
                    t.winningIn = this.winningIns.find(w => w.id === t.winningIn.id);
                }

                if (!this.loggedUser.isAdmin && !t.user) {
                    t.user = this.loggedUser;
                }
            });
            this.refreshHandsontable();
            this.ngxService.stop();
        }, () => {
            this.ngxService.stop();
        });
    }

    public delete() {
        this.modalService.open(this.deleteModal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result && this.toDelete) {
                for (const row of this.toDelete) {
                    this.targetService.delete(this.targets[row]).subscribe(() => { });
                    this.targets.splice(row, 1);
                }
            }
            this.toDelete = null;
        }, (reason) => {
            return false;
        });
    }

    public afterChange(e: any) {
        if (e.length > 0 && (e[1] === 'edit' || e[1] === 'CopyPaste.paste' || e[1] === 'Autofill.fill') && this.loggedUser.isAdmin) {
            const hotInstance = this.hotTableComponent.getHandsontableInstance();
            let forceRender = false;
            const changes = [];
            for (const change of e[0]) {
                const index = change[0];

                if (change.length > 0) {
                    if (change[1] === 'brand.name') {
                        const newValue = change[3];
                        if (change[3] !== change[2]) {
                            this.adjustProgram(index, newValue);
                            if (e[1] === 'edit') {
                                changes.push([index, this.INDEX_PROGRAM, '']);
                            }
                            this.hasChanges = true;
                        }
                    }
                    else if (change[1] === 'country.name') {
                        const newValue = change[3];
                        if (change[3] !== change[2]) {
                            this.adjustBrand(index, newValue);
                            if (e[1] === 'edit') {
                                changes.push([index, this.INDEX_BRAND, '']);
                            }
                            this.hasChanges = true;
                        }
                    }
                    else if (change[1] === 'user.name') {
                        if (change[3] !== change[2]) {
                            const defaultCountry = this.adjustCountry(index, true);
                            if (defaultCountry) {
                                changes.push(defaultCountry);
                            }
                            this.hasChanges = true;
                        }
                    }
                    else if (change[3] !== change[2]) {
                        this.hasChanges = true;
                    }
                }

                if (this.targets[index] && this.hasChanges) {
                    this.targets[index].saved = 'No';
                    forceRender = true;
                }

                // ReadOnly
                this.adjustCellsMeta(index);
            }

            hotInstance.setDataAtCell(changes);

            if (forceRender) {
                hotInstance.render();
            }

            // Adjust locking for others row
            if (e[1] === 'Autofill.fill') {
                for (let i = 0; i <= hotInstance.countRows(); i++) {
                    this.adjustCellsMeta(i);
                }
            }
        }
    }

    public saveChanges() {
        this.hotTableComponent.getHandsontableInstance().validateCells((valid) => {
            if (valid) {
                let i = 0;
                let loopFinished = false;
                const errors = [];

                for (const target of this.targets) {
                    let validForSave = false;
                    Object.keys(target).forEach(key => {
                        if (key !== 'saved' && target[key]) {
                            validForSave = true;
                        }
                    });

                    if (validForSave) {
                        const t = TargetModel.fromObject(target);
                        this.fetchProperties(t);

                        if (t) {
                            // Auto default name
                            if (!t.name) {
                                t.name = 'Target ' + this.targets.filter(_t => Object.keys(_t).length > 0).length;
                                this.targets[i].name = t.name;
                            }

                            const index = i;
                            this.targetService.createOrEdit(t).subscribe((data: any) => {
                                if (data.status === 'error' && data.error.error_id !== 3109) // 3109 = No field to update
                                {
                                    errors[index] = ('Error on line ' + (index + 1) + ': ' + data.error.error_msg);
                                    this.targets[index].saved = 'Error';
                                }
                                else {
                                    this.targets[index].saved = 'Yes';
                                }

                                if (loopFinished) {
                                    if (errors.length <= 0) {
                                        this.globalMessage.showSuccess('All targets have been saved');
                                        this.hasChanges = false;
                                    }
                                    else {
                                        let errorMsg = '';
                                        errors.forEach(e => errorMsg += e + '<br />');
                                        this.globalMessage.showError(errorMsg);
                                    }
                                }

                                this.hotTableComponent.getHandsontableInstance().render();
                            });
                        }
                    }
                    else if (Object.keys(target).length > 0 && target.saved === 'No') {
                        target.saved = '';
                    }

                    i++;
                    if (i === this.targets.length - 1) {
                        loopFinished = true;
                    }
                }

                this.refreshHandsontable();
            }
            else {
                this.globalMessage.showError('Table contains error(s)', 2000);
            }
        });
    }

    private fetchProperties(target: TargetModel) {
        if (target.activityType) {
            target.activityType = this.activityTypes.find(a => a.name === target.activityType.name);
        }
        if (target.brand) {
            target.brand = this.brands.find(b => b.name === target.brand.name);
        }
        if (target.program) {
            target.program = this.programs.find(p => p.name === target.program.name);
        }
        if (target.winningIn) {
            target.winningIn = this.winningIns.find(w => w.city === target.winningIn.city);
        }
        if (target.user) {
            target.user = this.users.find(u => u.name === target.user.name);
        }
        if (target.targetType) {
            target.targetType = this.targetTypes.find(t => t.name === target.targetType.name);
        }
        if (target.country) {
            target.country = this.countries.find(c => c.name === target.country.name);
        }
    }

    private adjustProgram(row, brandName) {
        const hotInstance = this.hotTableComponent.getHandsontableInstance();

        const brand = this.brands.find(b => b.name === brandName);
        if (brand && this.targets[row] && this.targets[row].country) {
            const countryName = hotInstance.getDataAtCell(row, this.INDEX_COUNTRY);
            const country = this.countries.find(c => c.name === countryName);
            this.activityService.getBrandPrograms(brand, (country) ? country.id : null).subscribe(res => {
                hotInstance.setCellMetaObject(row, this.INDEX_PROGRAM, {
                    source: res.map(b => b.name)
                });
            });
        }
    }

    private adjustBrand(row, countryName) {
        const hotInstance = this.hotTableComponent.getHandsontableInstance();

        const country = this.countries.find(c => c.name === countryName);
        if (country) {
            this.activityService.getBrands(null, country.id).subscribe(res => {
                hotInstance.setCellMetaObject(row, this.INDEX_BRAND, {
                    source: res.map(b => b.name)
                });
            });
        }
    }

    private refreshHandsontable() {
        const hotInstance = this.hotTableComponent.getHandsontableInstance();
        const changes = [];

        for (let i = 0; i <= hotInstance.countRows(); i++) {
            // Adjust country list
            const defaultCountry = this.adjustCountry(i);
            if (defaultCountry) {
                changes.push(defaultCountry);
            }

            // Adjust program on brand name
            const brandName = hotInstance.getDataAtCell(i, this.INDEX_BRAND);
            if (brandName) {
                this.adjustProgram(i, brandName);
            }

            // ReadOnly
            this.adjustCellsMeta(i);
        }

        hotInstance.setDataAtCell(changes);
        hotInstance.validateCells(() => { });
    }

    private adjustCellsMeta(row: number) {
        const hotInstance = this.hotTableComponent.getHandsontableInstance();
        const countryData = hotInstance.getDataAtCell(row, this.INDEX_COUNTRY);
        const userData = hotInstance.getDataAtCell(row, this.INDEX_USER);

        hotInstance.setCellMetaObject(row, this.INDEX_COUNTRY, {
            readOnly: !countryData,
            placeholder: (userData) ? '' : this.PLACEHOLDER_COUNTRY
        });

        hotInstance.setCellMetaObject(row, this.INDEX_USER, {
            readOnly: this.targets[row] && this.targets[row].saved === 'Yes'
        });

        hotInstance.setCellMetaObject(row, this.INDEX_BRAND, {
            readOnly: !countryData,
            placeholder: (countryData) ? '' : this.PLACEHOLDER_BRAND
        });

        const brandData = hotInstance.getDataAtCell(row, this.INDEX_BRAND);
        hotInstance.setCellMetaObject(row, this.INDEX_PROGRAM, {
            readOnly: !brandData,
            placeholder: (brandData) ? '' : this.PLACEHOLDER_PROGRAM
        });
    }

    private adjustCountry(row: number, forceDefault = false) {
        const hotInstance = this.hotTableComponent.getHandsontableInstance();
        const userName = hotInstance.getDataAtCell(row, this.INDEX_USER);
        if (userName) {
            const userModel = (this.loggedUser.isAdmin) ? this.users.find(u => u.name === userName) : this.loggedUser;

            if (userModel && userModel.countries) {
                hotInstance.setCellMetaObject(row, this.INDEX_COUNTRY, {
                    source: userModel.countries.map(v => v.name)
                });
            }

            // Default value if empty
            if (!hotInstance.getDataAtCell(row, this.INDEX_COUNTRY) || forceDefault) {
                const defaultCountry = userModel.countries.find(c => c.id === userModel.defaultCountryId);
                if (defaultCountry) {
                    return [row, this.INDEX_COUNTRY, defaultCountry.name];
                }
            }
        }
    }

    public set countryId(value) {
        this._countryId = value;

        this.usersService.list(this.countryId, this.loggedUser.id).subscribe(data => {
            this.users = data;
            this.columns[this.INDEX_USER].source.length = 0;
            data.map(u => u.name).forEach(u => this.columns[this.INDEX_USER].source.push(u));
            this.refreshList();
        });
    }

    public get countryId() {
        return this._countryId;
    }

    private unlockCells(hotInstance: any) {
        for (let i = 0; i <= hotInstance.countRows(); i++) {
            hotInstance.setCellMetaObject(i, this.INDEX_USER, { readOnly: false, source: this.users.map(u => u.name) });
            hotInstance.setCellMetaObject(i, this.INDEX_COUNTRY, { readOnly: false, source: this.countries.map(c => c.name) });
            hotInstance.setCellMetaObject(i, this.INDEX_BRAND, { readOnly: false, source: this.brands.map(b => b.name) });
            hotInstance.setCellMetaObject(i, this.INDEX_PROGRAM, { readOnly: false, source: this.programs.map(p => p.name) });
        }
    }
}
