export class TargetTypeModel {
    public id: number;
    public name: string;

    public fromJson(jsonData: any)
    {
        this.id = jsonData.ID ? Number.parseInt(jsonData.ID, 10) : null;
        this.name = jsonData.name;
    }

    constructor(jsonData: any) {
        this.fromJson(jsonData);
    }
}
