import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { WorkHistoryModel } from '../models/work-history.model';

@Injectable({
  providedIn: 'root'
})
export class WorkHistoryApiService {
    private endpointMulti = 'contact_work_history';
    private endpointSingle = 'work_history';

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public list(contactId: number)
    {
        const params = {};

        return this.api.get(this.endpointMulti + '/' + contactId, params, {headers: {Authorization: this.user.token}})
        .pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }

    public info(workHistoryId: number)
    {
        return this.api.get(this.endpointSingle + '/' + workHistoryId.toString(), {}, {headers: {Authorization: this.user.token}})
        .pipe(
            map(
                (res: any) => {
                    return res.data;
                }
            )
        );
    }

    public delete(workHistoryId: number)
    {
        return this.api.delete(this.endpointSingle + '/' + workHistoryId.toString(), {}, {headers: {Authorization: this.user.token}});
    }

    public createOrEdit(workHistory: WorkHistoryModel)
    {
        const workHistoryObject = {
            contact_id: workHistory.contact.id,
            outlet_id: workHistory.outlet.id,
            work_from: workHistory.from,
            work_until: workHistory.until
        };

        let url = this.endpointSingle;
        if (workHistory.id)
        {
            url = url + '/' + workHistory.id.toString();
            workHistoryObject['contact_id'] = workHistory.contact.id;
        }

        return this.api.post(url, workHistoryObject, {headers: {Authorization: this.user.token}});
    }
}
