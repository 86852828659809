import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AttendeesTypesService } from 'src/app/providers/attendees-types.service';
import { AttendeeTypeModel } from 'src/app/models/attendee-type.model';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide : NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AttendeeTypeSelectComponent),
    multi : true,
};
@Component({
    selector: 'app-attendee-type-select',
    templateUrl: './attendee-type-select.component.html',
    providers : [CUSTOM_VALUE_ACCESSOR]
})
export class AttendeeTypeSelectComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    @Input() ignoreRegions: number[] = [];

    public disabled = false;
    public value: string = null;
    public attendeeTypes: AttendeeTypeModel[];
    private onChange: Function = (_: any) => {};
    private onTouched: Function = () => {};

    constructor(private attendeesTypeService: AttendeesTypesService) {}

    ngOnInit() {
        this.attendeesTypeService.search(this.ignoreRegions).subscribe(data => {
            this.attendeeTypes = data;
            this.attendeeTypes.unshift(new AttendeeTypeModel({'id': null, 'name': ''}));
        });
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    attendeeTypeChange(ev)
    {
        this.value = ev.target.value;
        this.onChange(this.value);
        this.onTouched();
    }
}
