import { CountryModel } from './country.model';
import { GlobalModel } from './global.model';

export class POSSegmentModel {
    public id: number;
    public name?: string;
    public posSegment?: Partial<GlobalModel>;
    public country?: Partial<CountryModel>;
    public createDate?: Date;
    public createdBy?: string;
    public updateDate?: Date;
    public updatedBy?: string;

    constructor(POSSegment: Partial<POSSegmentModel>) {
        this.id = POSSegment.id;
        this.name = POSSegment.name;
        this.posSegment = new GlobalModel(POSSegment.posSegment);
        this.createDate = POSSegment.createDate || new Date('2019-12-16T11:37:18.947+0000');
        this.createdBy = POSSegment.createdBy || 'c786968532d8481482ac60bb9190ab73';
        this.updateDate = POSSegment.updateDate || new Date('2019-12-16T11:37:18.947+0000');
        this.updatedBy = POSSegment.updatedBy || 'c786968532d8481482ac60bb9190ab73';
        this.country = new CountryModel(POSSegment.country);
    }

    clone(): POSSegmentModel {
        const c = new POSSegmentModel({});
        return Object.assign(c, this);
    }
}
