import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { CountryModel } from '../models/country.model';

@Injectable({
    providedIn: 'root'
})
export class CountriesApiService {
    private endpointMulti = 'countries';
    private endpointSingle = 'countries';

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public list(size: number, page?: number) {
        const params = {
            size: size,
            page: page
        };
        return this.api.post(this.endpointMulti + '/search/' + this.user.user.id, {},
            { headers: { Authorization: this.user.token }, params: params })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public search() {
        return this.api.post(this.endpointMulti
            + '/search?size=99999&page=undefined&sort=name,ASC&sort=id,ASC',
            {},
            { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public info(countryId: number) {
        return this.api.get(this.endpointSingle + '/detail/' + countryId, {}, { headers: { Authorization: this.user.token } });
    }

    public delete(countryId: number) {
        return this.api.patch(this.endpointSingle + '/delete/' + countryId, {}, { headers: { Authorization: this.user.token } });
    }

    public createOrEdit(country: CountryModel) {
        let url = this.endpointSingle;
        if (country.id) {
            url = url + '/edit/' + country.id;
            return this.api.put(url, country, { headers: { Authorization: this.user.token } });
        } else {
            delete country.region.code;
            delete country.region.name;
            delete country.cluster.code;
            delete country.cluster.name;
            delete country.id;
            url = url + '/add';
            return this.api.post(url, country, { headers: { Authorization: this.user.token } });
        }
    }

    public getRegions() {
        return this.api.get('regions/list', {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public getClusters() {
        return this.api.get('clusters/list', {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }
}
