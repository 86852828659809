import { AfterViewInit, Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfluencerLevelModel } from 'src/app/models/influencer-level.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { InfluencerLevelsService } from 'src/app/providers/influencer-levels.service';
import { GlobalMessage } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-influencer-level',
    templateUrl: './influencer-level-list.page.html'
})
export class InfluencerLevelListPage extends DatatablePage implements OnInit, AfterViewInit {
    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    public influencerLevels: InfluencerLevelModel[];
    private editingId: number;
    private editingValue: string;
    private editInfluencer: any;

    constructor(
        private influencerLevelService: InfluencerLevelsService,
        private modalService: NgbModal,
        private messageHandler: GlobalMessage
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.setDtOption('pageLength', 25);
    }

    ngAfterViewInit() {
        this.refreshList('');
        this.refreshDt();
    }

    refreshList(param: string) {
        const params = {
            'searchStr': param
        };
        const size = 999999;
        this.influencerLevelService.search(params, size).subscribe(data => {
            this.influencerLevels = data.filter(value => {
                if (!value.deleted) {
                    return value;
                }
            });
            this.reRenderDt();
        });
    }

    delete(content, influencerLevelId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result) {
                this.influencerLevelService.delete(this.influencerLevels.find(a => a.id === influencerLevelId)).subscribe(
                    (x: any) => {
                        if (x.status === 204) {
                            this.refreshList('');
                        }
                    }
                );
            }

        }, (reason) => {
        });
    }

    startEdit(influencerLevelId: number) {
        this.editingId = influencerLevelId;
        this.editingValue = this.influencerLevels.find(il => il.id === influencerLevelId).name;
        this.editInfluencer = this.influencerLevels.find(il => il.id === influencerLevelId);
    }

    validateEdit() {
        this.editInfluencer.name = this.editingValue;
        this.influencerLevelService.createOrEdit(this.editInfluencer).subscribe((data: any) => {
        }, error => {
            this.messageHandler.showError('Form contains errors');
        }, () => {
            this.editingId = 0;
            this.editingValue = '';
            this.refreshList('');
            this.reRenderDt();
            this.messageHandler.showSuccess('The influencer level has been saved successfully', 5000);
        });
    }
}
