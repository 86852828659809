import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { ContactSocialModel } from '../models/contact-social.model';

@Injectable({
  providedIn: 'root'
})
export class ContactSocialsApiService {
    private endpointMulti = 'contact_socials';
    private endpointSingle = 'contact_social';

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public list(contactId: number)
    {
        const params = {};

        return this.api.get(this.endpointMulti + '/' + contactId, params, {headers: {Authorization: this.user.token}})
        .pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }

    public info(contactSocialId: number)
    {
        return this.api.get(this.endpointSingle + '/' + contactSocialId.toString(), {}, {headers: {Authorization: this.user.token}})
        .pipe(
            map(
                (res: any) => {
                    return res.data;
                }
            )
        );
    }

    public delete(contactSocialId: number)
    {
        return this.api.delete(this.endpointSingle + '/' + contactSocialId.toString(), {}, {headers: {Authorization: this.user.token}});
    }

    public createOrEdit(contactSocial: ContactSocialModel)
    {
        const contactSocialObject = {
            social: contactSocial.social,
            social_value: contactSocial.value,
            contact_id: contactSocial.contact.id,
        };

        let url = this.endpointSingle;
        if (contactSocial.id)
        {
            url = url + '/' + contactSocial.id.toString();
            contactSocialObject['contact_id'] = contactSocial.contact.id;
        }

        return this.api.post(url, contactSocialObject, {headers: {Authorization: this.user.token}});
    }
}
