import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormPage } from 'src/app/pages/FormPage';
import { OutletWinningInService } from 'src/app/providers/outlet-winning-in.service';
import { WinningInModel } from 'src/app/models/winning-in.model';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { CountriesService } from 'src/app/providers/countries.service';
import { CountryModel } from '../../../../../models/country.model';

@Component({
    selector: 'app-winning-in-edit.page',
    templateUrl: './outlet-winning-in-edit.page.html'
})
export class OutletWinningInEditPage extends FormPage implements OnInit {
    public winningInForm = this.fb.group({
        winningInId: [''],
        city: ['', Validators.required],
        countryId: ['', Validators.required],
    });

    public winningInId: number = null;
    public countrySend: CountryModel;

    constructor(
        private fb: FormBuilder,
        private outletWinningInService: OutletWinningInService,
        private route: ActivatedRoute,
        private errorHandler: GlobalMessage,
        private countriesServicee: CountriesService) {
        super();
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null) {
                this.winningInId = Number.parseInt(params.get('id'), 10);
                this.outletWinningInService.info(Number.parseInt(params.get('id'), 10)).subscribe(
                    (data) => {
                        this.winningInForm.setValue({
                            winningInId: data.id,
                            city: data.city,
                            countryId: data.country ? data.country.id : null
                        });
                    }
                );
            }
        });
    }

    onSubmit() {
        if (this.winningInForm.valid) {
            const winningIn = this.winningInForm.value;
            const countrySend = new CountryModel({ id: +winningIn.countryId });

            this.countriesServicee.info(countrySend.id).subscribe(res => {
                this.outletWinningInService.createOrEdit(new WinningInModel({
                    id: winningIn.winningInId,
                    city: winningIn.city,
                    country: countrySend,
                })).subscribe((data: any) => {
                }, (error) => {
                    this.errorHandler.showError(error.error_msg);
                }, () => {
                    this.errorHandler.showSuccess('Your winning in has been saved successfully', 5000);
                });
            });
        }
        else {
            this.validateAllFormFields(this.winningInForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.winningInForm;
    }
}
