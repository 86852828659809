import { Component, forwardRef, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/providers/users.service';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide : NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UserSelectComponent),
    multi : true,
};
@Component({
    selector: 'app-user-select',
    templateUrl: './user-select.component.html',
    providers : [CUSTOM_VALUE_ACCESSOR]
})
export class UserSelectComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    public disabled = false;
    public value: string = null;
    public users: UserModel[];
    private onChange: Function = (_: any) => {};
    private onTouched: Function = () => {};

    constructor(private userService: UsersService, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.userService.list(null).subscribe(data => {
            this.users = data;

            this.cdr.detectChanges();

            const selectizeObj = $('#' + this.inputId)['selectize']({
                placeholder: 'Select an user',
            });

            selectizeObj[0].selectize.on('change', (value) => {
                this.emitChangeValue(value);
            });
        });
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    userChange(ev)
    {
        this.emitChangeValue(ev.target.value);
    }

    emitChangeValue(value)
    {
        this.value = value;
        this.onChange(value);
        this.onTouched();
    }
}
