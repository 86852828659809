import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ActivityTypeModel } from '../models/activity-type.model';
import { ApiService } from './api.service';
import { UserService } from './user.service';


@Injectable({
    providedIn: 'root'
})
export class ActivityTypesApiService {
    private endpointMulti = 'activitytypesregion';
    private endpointSingle = 'activitytypesregion';
    private endpointActivitytypes = 'activitytypes';
    private requestHeader = {
        headers: { Authorization: this.user.token }
    };

    constructor(
        private api: ApiService,
        private user: UserService) { }


    public list() {
        return this.api.get(this.endpointMulti + '/list', {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public search() {
        return this.api.post(this.endpointMulti + '/search/' + this.user.user.id, {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public info(activityTypeId: number) {
        return this.api.get(this.endpointSingle + '/' + activityTypeId.toString(), {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res.data;
                    }
                )
            );
    }

    public delete(activityTypeId: number) {
        return this.api.patch(this.endpointSingle + '/delete/' +
            activityTypeId.toString(), {}, { headers: { Authorization: this.user.token } });
    }

    public createOrEdit(activityType: Partial<ActivityTypeModel>) {
        // const activityTypeObject = {
        //     name: activityType.name,
        // };

        const url = this.endpointSingle + '/save/';

        if (activityType.id) {
            return this.api.post(url, activityType, { headers: { Authorization: this.user.token } });
        }
        else {
            delete activityType.id;
            return this.api.post(url, activityType, { headers: { Authorization: this.user.token } });
            // activityTypeObject['global_id'] = activityType.activityTypeGlobal.id;
            // activityTypeObject['global_name'] = activityType.activityTypeGlobal.name;
        }

        // return this.api.post(url, activityTypeObject, {headers: {Authorization: this.user.token}});
    }

    public getGlobals() {
        const params = {};

        return this.api.get(this.endpointActivitytypes + '/list', params, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public getByCountry(countryId: number) {
        return this.api.get(this.endpointMulti + '/activityTypeByCountry/' + countryId, {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public createGlobal(global) {
        return this.api.post(`${this.endpointActivitytypes}/add`, global, this.requestHeader);
    }
}
