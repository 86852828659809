export class BrandCategoryModel {
    public id: number;
    public name: string;
    public createDate: Date;
    public createdBy: string;
    public deleted: boolean;
    public updateDate: Date;
    public updatedBy: string;

    public fromJson(jsonData: any) {
        this.id = jsonData.id ? Number.parseInt(jsonData.id, 10) : null;
        this.name = jsonData.name;
    }

    constructor(data) {
        if (data.id) { this.id = data.id; }
        this.name = data.name || '';
        this.createDate = data.createDate || new Date();
        this.createdBy = data.createdBy || new Date();
    }
}
