import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class AdminApiService {
    private endpointMulti = 'admins';
    private endpointSingle = 'admin';

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public getExportConfig()
    {
        return this.api.postExcel('settings/export', {}, {headers: {Authorization: this.user.token}})
        .pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }

    public importConfig(file: File)
    {
        const formData: FormData = new FormData();
        formData.append('mpf', file, file.name);
        const headers = {
            'Accept': 'application/json',
            'Authorization': this.user.token
        };
        return this.api.postFile('settings/import', formData, {headers: headers});
    }

    public confirmImportConfig(importId: number)
    {
        const importObject = {
            import_id: importId,
        };

        return this.api.post(this.endpointSingle + '/import', importObject, {headers: {Authorization: this.user.token}});
    }

    public getCountries()
    {
        return this.api.get('countries/', {}, { headers: { Authorization: this.user.token } })
        .pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }

    public searchCountries()
    {
        return this.api.post('countries/search/', {}, { headers: { Authorization: this.user.token } })
        .pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }
}
