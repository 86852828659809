import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttendeesTypesApiService } from './attendees-types-api.service';
import { AttendeeTypeModel } from '../models/attendee-type.model';
import { GlobalId } from './global-id.interface';
import { GlobalModel } from '../models/global.model';

@Injectable({
    providedIn: 'root'
})
export class AttendeesTypesService implements GlobalId {
    constructor(
        private attendeesTypesApi: AttendeesTypesApiService
    ) { }

    public search(ignoreRegion: number[] = []) {
        const attendeesTypes: AttendeeTypeModel[] = [];
        return this.attendeesTypesApi.search(ignoreRegion).pipe(
            map(l => {
                if (l && l.content) {
                    l.content.forEach(element => attendeesTypes.push(new AttendeeTypeModel(element)));
                }
                return attendeesTypes;
            })
        );
    }

    public info(attendeesTypeId: number): Observable<AttendeeTypeModel> {
        return this.attendeesTypesApi.info(attendeesTypeId).pipe(
            map(res => new AttendeeTypeModel(res))
        );
    }

    public delete(attendeesType: AttendeeTypeModel) {
        return this.attendeesTypesApi.delete(attendeesType.id);
    }

    public createOrEdit(attendeesType: AttendeeTypeModel) {
        return this.attendeesTypesApi.createOrEdit(attendeesType);
    }

    public getGlobals() {

        return this.attendeesTypesApi.getGlobals();
        /*const globals: GlobalModel[] = [];
        return this.attendeesTypesApi.getGlobals().pipe(
            map( l => {
                l.data.forEach( element => globals.push(new GlobalModel(element)));
                return globals;
            })
        );*/
    }

    public getByCountry(countryId: number) {
        return this.attendeesTypesApi.getByCountry(countryId);
    }

    public createGlobal(global) {
        return this.attendeesTypesApi.createGlobal(global);
    }
}
