import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { OutletModel } from '../models/outlet.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OutletsApiService {
    private endpoint = 'outlets';

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public list(
        resultsNum: number = 20,
        offset: number = 0,
        search: string = '',
        orderBy: any = 'id',
        order: string = 'ASC',
        filterByCountry: number = null,
        filterByWinningIn: number = null,
        filterByPosSegment: number = null,
        filterByActivitySegment: number = null) {
        switch (orderBy) {
            case 0:
                orderBy = 'name';
                break;
            case 1:
                orderBy = 'zip';
                break;
            case 2:
                orderBy = 'city';
                break;
            default:
                orderBy = 'id';
                break;
        }

        if (search === '') {
            search = null;
        }
        const body = {
            searchStr: search,
            country: filterByCountry,
            winningIn: filterByWinningIn,
            posSegment: filterByPosSegment,
            activitySegment: filterByActivitySegment
        };

        const params = {
            size: resultsNum,
            page: offset / resultsNum,
            sort: orderBy + ',' + order
        };

        return this.api.post(this.endpoint
            + '/search/'
            + this.user.user.id, body, { headers: { Authorization: this.user.token }, params: params })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public info(outletId: number) {
        return this.api.get(this.endpoint + '/detail/' + outletId.toString(), {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public delete(outletId: number) {
        return this.api.patch(this.endpoint + '/delete/' + outletId.toString(), {}, { headers: { Authorization: this.user.token } });
    }

    public createOrEdit(outlet: OutletModel) {
        if (!outlet.id) {
            delete outlet.id;
            outlet.created_by = this.user.user.id;
        }

        return this.api.post(this.endpoint + '/save', outlet, { headers: { Authorization: this.user.token } });
    }

    public getExport(country: number, winningIn: number, posSegment: number,
        activitySegment: number, searchStr: string) {
        return this.api.postExcel(this.endpoint
            + '/export',
            { country, winningIn, posSegment, activitySegment, searchStr },
            { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public importXls(countryId, file: File) {
        const formData: FormData = new FormData();
        formData.append('mpf', file, file.name);
        const headers = {
            'Accept': 'application/json',
            'Authorization': this.user.token
        };

        return this.api.postFile(this.endpoint + '/import/' + countryId, formData, { headers: headers });
    }

    public confirmImportXls(importId: number) {
        const importObject = {
            import_id: importId,
        };

        return this.api.post(this.endpoint + '/import', importObject, { headers: { Authorization: this.user.token } });
    }

    public getByCountry(countryId: number) {
        return this.api.get(this.endpoint + '/outletByCountry/' + countryId, {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public getOutletManagersByCountry(countryId: number): Observable<any> {
        return this.api.get(this.endpoint + '/outletManagersByCountry/' + countryId, {}, { headers: { Authorization: this.user.token } });
    }
}
