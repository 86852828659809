import { AfterViewInit, Component, ContentChild, OnInit, TemplateRef, AfterContentInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import saveAs from 'file-saver';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivitySegmentModel } from 'src/app/models/activity-segment.model';
import { OutletModel } from 'src/app/models/outlet.model';
import { POSSegmentModel } from 'src/app/models/pos-segment.model';
import { WinningInModel } from 'src/app/models/winning-in.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { OutletsService } from 'src/app/providers/outlets.service';
import { OutletWinningInService } from 'src/app/providers/outlet-winning-in.service';
import { OutletPosSegmentsService } from '../../../../../providers/outlet-pos-segments.service';
import { OutletActivitySegmentsService } from '../../../../../providers/outlet-activity-segments.service';
import { UserService } from '../../../../../providers/user.service';
import { UserRoleConstants } from '../../../../../shared/constants/user-role.constants';

@Component({
    selector: 'app-outlets',
    templateUrl: './outlet-list.page.html'
})
export class OutletListPage extends DatatablePage implements AfterContentInit, AfterViewInit, OnInit {
    public readonly UserRoleConstants = UserRoleConstants;
    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    @ContentChild('importModal', null) importModal: TemplateRef<any>;

    public showSuccess = false;
    public importId: number = null;
    public importedFile: any = null;

    public _countryId = null;
    public _winningInId = null;
    public _posSegmentId = null;
    public _activitySegmentId = null;

    // Save search string for use in export fn
    public searchStr = null;

    public fileList: FileList;

    private outlets: OutletModel[];

    public winningIns: WinningInModel[];
    public posSegments: POSSegmentModel[];
    public activitySegments: ActivitySegmentModel[];

    constructor(
        private outletService: OutletsService,
        private modalService: NgbModal,
        private ngxService: NgxUiLoaderService,
        private outletWinningInService: OutletWinningInService,
        private outletPosSegmentsService: OutletPosSegmentsService,
        private outletActivitySegmentsService: OutletActivitySegmentsService,
        public userService: UserService
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.dtOptions.pagingType = 'full_numbers';
        this.dtOptions.serverSide = true;
        this.dtOptions.processing = true;

        const that = this;
        this.dtOptions.ajax = (dataTablesParameters: any, callback) => {

            // Save search string for use in export fn
            dataTablesParameters.search.value === ''
                ? this.searchStr = null
                : this.searchStr = dataTablesParameters.search.value;

            const options = [
                dataTablesParameters.length,
                dataTablesParameters.start,
                dataTablesParameters.search.value,
                dataTablesParameters.order[0].column,
                dataTablesParameters.order[0].dir,
                this.countryId,
                this.winningInId,
                this.posSegmentId,
                this.activitySegmentId
            ];

            const outletServiceList$ = that.outletService.list(...options);

            if (dataTablesParameters.search.value.length === 1) {
                return;
            }
            const request = outletServiceList$.subscribe(
                data => {

                    if (data && data.content) {
                        that.outlets = data.content;
                        if (data.totalElements) {
                            callback({
                                recordsTotal: data.totalElements,
                                recordsFiltered: data.totalElements,
                                data: []
                            });
                        }
                    } else {
                        that.outlets = [];
                        callback({
                            recordsTotal: 0,
                            recordsFiltered: 0,
                            data: []
                        });
                    }
                },
                err => console.error(err),
            );

        };
    }

    ngAfterContentInit() {
        if (sessionStorage.getItem('countryByUser')) {
            this.countryId = Number(sessionStorage.getItem('countryByUser'));
        }
    }

    ngAfterViewInit() {
        this.refreshDt();
    }

    delete(content, outletId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result) {
                this.outletService.delete(this.outlets.find(a => a.id === outletId)).subscribe(
                    x => {
                        this.reRenderDt();
                    }
                );
            }

        }, (reason) => {
        });
    }

    public exportOutlets() {
        this.ngxService.start();
        this.outletService.getExport(
            this.countryId,
            this.winningInId,
            this.posSegmentId,
            this.activitySegmentId,
            this.searchStr,
        ).subscribe(fileContent => {
            const blob = new Blob([fileContent], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'export-outlets-' + moment().format('Y-M-D').toString() + '.xlsx');
            this.ngxService.stop();
        }, () => this.ngxService.stop());
    }

    import(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result && this.fileList.length > 0) {
                this.ngxService.start();
                this.outletService.importXls(this._countryId ? this.countryId : -1, this.fileList[0]).subscribe((data: any) => {
                    if (data.status === 'success') {
                        this.importedFile = data.data.programs;
                        this.importId = data.data.db_import_id;
                    }
                    this.ngxService.stop();
                }, () => {
                    this.ngxService.stop();
                });
            }
        }, (reason) => {
        });
    }

    onFileChanged(event) {
        this.fileList = event.target.files;
    }

    confirmImportation() {
        this.ngxService.start();
        this.outletService.confirmImportXls(this.importId).subscribe((data: any) => {
            if (data.status === 'success') {
                this.showSuccess = true;
                setTimeout(() => this.showSuccess = false, 5000);
                this.importId = null;
                this.importedFile = null;
            }
            this.ngxService.stop();
        }, () => {
            this.ngxService.stop();
        });
    }

    public set countryId(value) {
        this._countryId = value;

        if (value) {
            this.outletWinningInService.getWinningIns(this.countryId, 99999).subscribe(data => this.winningIns = data);
            this.outletPosSegmentsService.list(this.countryId, 99999).subscribe(data => this.posSegments = data);
            this.outletActivitySegmentsService.list(this.countryId, 99999).subscribe(data => this.activitySegments = data);
        }
        else {
            this.winningIns = [];
            this.posSegments = [];
            this.activitySegments = [];
        }

        this._winningInId = null;
        this._posSegmentId = null;
        this._activitySegmentId = null;

        if (this._countryId) {
            sessionStorage.setItem('countryByUser', this._countryId);
        }
        else {
            sessionStorage.removeItem('countryByUser');
        }

        this.reRenderDt();
    }

    public get countryId() {
        if (this._countryId === 'null') {
            this._countryId = null;
        }
        return this._countryId;
    }

    public set winningInId(value) {
        this._winningInId = value;
        this.reRenderDt();
    }

    public get winningInId() {
        if (this._winningInId === 'null') {
            this._winningInId = null;
        }
        return this._winningInId;
    }

    public set posSegmentId(value) {
        this._posSegmentId = value;
        this.reRenderDt();
    }

    public get posSegmentId() {
        if (this._posSegmentId === 'null') {
            this._posSegmentId = null;
        }
        return this._posSegmentId;
    }

    public set activitySegmentId(value) {
        this._activitySegmentId = value;
        this.reRenderDt();
    }

    public get activitySegmentId() {
        if (this._activitySegmentId === 'null') {
            this._activitySegmentId = null;
        }
        return this._activitySegmentId;
    }
}
