import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserRoleModel } from 'src/app/models/user-role.model';
import { UsersService } from 'src/app/providers/users.service';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide : NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RoleSelectComponent),
    multi : true,
};
@Component({
    selector: 'app-role-select',
    templateUrl: './role-select.component.html',
    providers : [CUSTOM_VALUE_ACCESSOR]
})
export class RoleSelectComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    public disabled = false;
    public value: string = null;
    public roles: UserRoleModel[];
    private onChange: Function = (_: any) => {};
    private onTouched: Function = () => {};

    constructor(private userService: UsersService) {}

    ngOnInit() {
        this.userService.roles().subscribe(data => {
            this.roles = data;
        });
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    roleChange(ev)
    {
        this.value = ev.target.value;
        this.onChange(this.value);
        this.onTouched();
    }
}
