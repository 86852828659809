import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivitySegmentModel } from 'src/app/models/activity-segment.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { OutletActivitySegmentsService } from 'src/app/providers/outlet-activity-segments.service';
import { UserService } from 'src/app/providers/user.service';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { GlobalModel } from 'src/app/models/global.model';

@Component({
    selector: 'app-outlet-activity-segment-list',
    templateUrl: './outlet-activity-segment-list.page.html'
})
export class OutletActivitySegmentListPage extends DatatablePage implements AfterViewInit, OnInit
{
    public activitySegments: ActivitySegmentModel[];
    public activitySegment: ActivitySegmentModel;
    public countryId: number;
    public editingId: number;

    public activitySegmentForm = this.fb.group({
        name: ['', Validators.required],
        segment: [null]
    });

    constructor(
        private fb: FormBuilder,
        public outletActivitySegmentsService: OutletActivitySegmentsService,
        private userService: UserService,
        private messageHandler: GlobalMessage
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.countryId = Number(sessionStorage.getItem('activityByCountry'))
            ? Number(sessionStorage.getItem('activityByCountry'))
            : this.userService.user.defaultCountryId;
    }

    ngAfterViewInit() {
        this.refreshList();
        this.refreshDt();
    }

    refreshList() {
        if (this.countryId) {
            sessionStorage.setItem('activityByCountry', JSON.stringify(this.countryId));
        } else {
            sessionStorage.removeItem('activityByCountry');
        }

        const size = 999999;
        this.outletActivitySegmentsService.list(this.countryId, size).subscribe(data => {
            this.activitySegments = data;
            this.reRenderDt();
        });
    }

    startEdit(activitySegment: ActivitySegmentModel) {
        this.activitySegment = activitySegment;
        this.editingId = activitySegment.id;

        this.activitySegmentForm.patchValue({
            name: activitySegment.name,
            segment: new GlobalModel(activitySegment.segment)
        });
    }

    globalChange(segmentId: number) {
        this.activitySegmentForm.patchValue({
            segment: new GlobalModel({id: segmentId})
        });
    }

    cancelEdit()
    {
        this.editingId = null;

        this.activitySegmentForm.patchValue({
            name: '',
            segment: null
        });
    }

    validateEdit() {
        if (this.activitySegmentForm.valid) {
            const activitySegment = this.activitySegmentForm.value;
            activitySegment.segment.id = this.activitySegmentForm.value.segment.id;
            activitySegment.id = this.editingId;
            activitySegment.country = {id: this.countryId};
            this.outletActivitySegmentsService.update(new ActivitySegmentModel(activitySegment)).subscribe(
                () => {
                    this.cancelEdit();
                    this.refreshList();
                    this.messageHandler.showSuccess('The activity segment has been saved successfully', 5000);
                }, (e: Error) => {
                    this.messageHandler.showError(e.message);
                }
            );
        } else {
            this.validateAllFormFields(this.activitySegmentForm);
        }
    }

    private validateAllFormFields(formGroup: FormGroup)
    {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    public isFieldValid(field: string)
    {
        return !this.activitySegmentForm.get(field).valid && this.activitySegmentForm.get(field).touched;
    }

    public displayFieldCss(field: string)
    {
        return {
            'has-error': this.isFieldValid(field),
            'has-feedback': this.isFieldValid(field)
        };
    }
}
