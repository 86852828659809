import { GlobalModel } from './global.model';
import { CountryModel } from './country.model';

export class ActivitySegmentModel {
    public id: number;
    public name: string;
    public segment: Partial<GlobalModel>;
    public country: Partial<CountryModel>;
    public createDate?: Date;
    public createdBy?: string;
    public updateDate?: Date;
    public updatedBy?: string;

    constructor(activitySegment: Partial<ActivitySegmentModel>) {
        this.id = activitySegment.id;
        this.name = activitySegment.name;
        this.segment = new GlobalModel(activitySegment.segment);
        this.createDate = activitySegment.createDate || new Date('2019-12-16T11:37:18.947+0000');
        this.createdBy = activitySegment.createdBy || 'c786968532d8481482ac60bb9190ab73';
        this.updateDate = activitySegment.updateDate || new Date('2019-12-16T11:37:18.947+0000');
        this.updatedBy = activitySegment.updatedBy || 'c786968532d8481482ac60bb9190ab73';
        this.country = new CountryModel(activitySegment.country);
    }

}
