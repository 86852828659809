import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BrandsService } from 'src/app/providers/brands.service';
import { BrandModel } from 'src/app/models/brand.model';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide : NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BrandSelectComponent),
    multi : true,
};
@Component({
    selector: 'app-brand-select',
    templateUrl: './brand-select.component.html',
    providers : [CUSTOM_VALUE_ACCESSOR]
})
export class BrandSelectComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: number;
    @Input() multiple: boolean;

    public disabled = false;
    public value: any = null;
    public brands: BrandModel[];
    public selectedBrands: BrandModel[] = [];

    public pendingValues: any[] = [];

    private onChange: Function = (_: any) => {};
    private onTouched: Function = () => {};

    constructor(private brandService: BrandsService) {}

    ngOnInit() {
        this.brandService.search().subscribe((data: any) => {
            this.brands = data.content;
            this.flushPending();
        });
    }

    writeValue(obj: any): void {
        if (obj)
        {
            if (typeof obj === 'object')
            {
                obj.forEach(v => this.pendingValues.push(v));
            }
            else
            {
                this.pendingValues.push(obj);
            }
        }

        if (this.brands)
        {
            this.flushPending();
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private flushPending() {
        let v = this.pendingValues.pop();
        while (v) {
            if (typeof v === 'string') {
                this.selectedBrands.push(this.getBrandById(Number.parseInt(v, 10)));
            } else if (typeof v === 'number') {
                if (this.multiple) {
                    this.selectedBrands.push(this.getBrandById(v));
                } else {
                    this.value = v;
                }
            } else if (v instanceof BrandModel && v.id) {
                this.selectedBrands.push(this.getBrandById(v.id));
            }
            v = this.pendingValues.pop();
        }

        if (this.multiple) {
            this.onChange(this.getMultipleValues());
        } else {
            this.onChange(this.value);
        }
    }

    private getBrandById(brandId: number)
    {
        const found = this.brands.find(b => b.id === brandId);
        return found ? found : null;
    }

    brandChange(ev) {
        const brand = this.brands.find(i => i.id === +ev.target.value);
        this.value = brand;
        if (!this.multiple) {
            this.onChange(this.value);
            this.onTouched();
        }
    }

    public add()
    {
        if (this.value && this.getMultipleValues().indexOf(this.value) < 0)
        {
            this.selectedBrands.push(this.getBrandById(this.value));
            this.onChange(this.getMultipleValues());
            this.onTouched();
        }
    }

    public remove(brandId: number)
    {
        this.selectedBrands = this.selectedBrands.filter(b => b.id !== brandId);
        this.onChange(this.getMultipleValues());
        this.onTouched();
    }

    public getMultipleValues()
    {
        if (this.selectedBrands.length > 0)
        {
            return this.selectedBrands.map(brand => brand.id);
        }
        else
        {
            return [];
        }
    }
}
