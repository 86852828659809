export class GlobalModel {
    public id: number;
    public name: string;
    public createDate: Date;
    public createdBy: string;
    public deleted: boolean;
    public updateDate: Date;
    public updatedBy: string;

    public fromJson(data)
    {
        this.id = data.id ? Number.parseInt(data.id, 10) : null;
        this.name = (data.global_name) ? data.global_name : data.name;
    }

    constructor(data) {
        if (data) {
            this.id = data.id;
            this.name = data.name || '';
        }
    }
}
