import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../providers/user.service';
import { UserModel } from 'src/app/models/user.model';
import { LoginService } from 'src/app/providers/login.service';
import { interval, Subscription } from 'rxjs';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var gigya: any;

@Component({
  selector: 'app-panel-menu',
  templateUrl: './panel-menu.component.html',
  styleUrls: ['./panel-menu.component.scss']
})
export class PanelMenuComponent implements OnInit, OnDestroy {
  public user: UserModel;
  public showMenu = true;

  timeCheckSubscription: Subscription;
  timeExpired = false;

  constructor(
    private loginService: LoginService,
    private errorHandler: GlobalMessage,
    private ngZone: NgZone,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.user = this.userService.user;
    this.checkExpiredToken();
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.userService.clearUser();
    gigya.accounts.logout();
    this.router.navigate(['/login']);
  }

  checkExpiredToken() {
    this.timeCheckSubscription = interval(60000).subscribe(x => {
      const currentTime = new Date();
      const expireTimeNotice = Number(sessionStorage.getItem('loginDate')) + 3300000;
      const expireTime = Number(sessionStorage.getItem('loginDate')) + 3594000;
      if (currentTime.getTime() > expireTimeNotice && this.timeExpired === false) {
        this.timeExpired = true;
        this.errorHandler.showError('Your session will expire in 5 minutes, please save all your changes.', 290000);
      }
      else if (currentTime.getTime() > expireTime) {
        this.errorHandler.showError('Session expired. You will be redirected to login.', 10000);
        this.logout();
      }
    });
  }

  ngOnDestroy() {
    this.timeCheckSubscription.unsubscribe();
  }
}
