export class UserRoleModel {
    public id: number;
    public adminLevel: number;
    public name: string;
    public slug: string;

    public fromJson(jsonData: any)
    {
        this.id = jsonData.id ? Number.parseInt(jsonData.id, 10) : null;
        this.adminLevel = jsonData.admin_level;
        this.name = jsonData.name;
        this.slug = jsonData.slug;
    }

    constructor(jsonData: any) {
        this.fromJson(jsonData);
    }
}
