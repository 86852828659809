import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { EventModel } from '../models/event.model';

@Injectable({
    providedIn: 'root'
})
export class UserApiService {

    constructor(private api: ApiService) { }

    public login(email: string, password: string) {
        return this.api.post('login', { email, password }).pipe(
            map(
                (res: any) => {
                    if (res.status === 200) {

                        this.api.post('event/save', '');
                    }
                    return res;
                }
            )
        );
    }

    public userInfo(userId: number, token) {
        return this.api.get('user/' + userId.toString(), {}, { headers: { Authorization: token } })
            .pipe(
                map(
                    (res: any) => {
                        return res.data;
                    }
                )
            );
    }

    public logout(token: string) {
        return this.api.post('logout', {}, { headers: { Authorization: token } }).pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }

    public loginWithToken(token: string, user_remote_addr = 'UNDEFINED') {
        return this.api.post('login-with-token/' + token, {}).pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }

    public loginTokenRequest(email: string) {
        return this.api.get('login-token-request/' + email).pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }
}
