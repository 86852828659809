import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandModel } from 'src/app/models/brand.model';
import { TargetModel } from 'src/app/models/target.model';
import { FormPage } from 'src/app/pages/FormPage';
import { TargetsService } from 'src/app/providers/targets.service';
import { GlobalMessage } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-target-edit.page',
    templateUrl: './target-edit.page.html'
})
export class TargetEditPage extends FormPage implements OnInit
{
    public targetForm = this.fb.group({
        targetId: [''],
        name: ['', Validators.required],
        userId: ['', Validators.required],
        activityTypeId: [''],
        brandId: [''],
        programId: [''],
        targetTypeId: ['', Validators.required],
        value: ['', Validators.required],
        from: ['', Validators.required],
        to: ['', Validators.required],
        comment: [''],
    });

    public brands: BrandModel[] = null;

    public targetId: number = null;

    constructor(
        private fb: FormBuilder,
        private targetService: TargetsService,
        private route: ActivatedRoute,
        private errorHandler: GlobalMessage,
        private router: Router)
    {
        super();
    }

    ngOnInit()
    {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null)
            {
                this.targetId = Number.parseInt(params.get('id'), 10);
                this.targetService.info(Number.parseInt(params.get('id'), 10)).subscribe(
                    (data) => {
                        this.targetForm.setValue({
                            targetId: data.id,
                            name: data.name,
                            userId: data.user ? data.user.id : null,
                            activityTypeId: data.activityType ? data.activityType.id : null,
                            brandId: data.brand ? data.brand.id : null,
                            programId: data.program ? data.program.id : null,
                            targetTypeId: data.targetType ? data.targetType.id : null,
                            value: data.value,
                            from: {year: data.from.year(), month: data.from.month() + 1, day: data.from.day()},
                            to: {year: data.to.year(), month: data.to.month() + 1, day: data.to.day()},
                            comment: data.comment,
                        });
                    }
                );
            }
        });
    }

    onSubmit()
    {
        if (this.targetForm.valid)
        {
            const target = this.targetForm.value;
            this.targetService.createOrEdit(new TargetModel({
                ID: target.targetId,
                target_name: target.name,
                user_id: target.userId,
                activity_type_id: target.activityTypeId,
                brand_id: target.brandId,
                program_id: target.programId,
                target_type_id: target.targetTypeId,
                target_value: target.value,
                target_from: target.from.year + '-' + target.from.month + '-' + target.from.day,
                target_to: target.to.year + '-' + target.to.month + '-' + target.to.day,
                comment: target.comment,
            })).subscribe((data: any) => {
                if (data.status === 'success')
                {
                    this.errorHandler.showSuccess('Your target has been saved successfully', 5000);
                    this.router.navigate(['/workspace/targets']);
                }
                else
                {
                    this.errorHandler.showError(data.error.error_msg);
                }
            });
        }
        else
        {
            this.validateAllFormFields(this.targetForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.targetForm;
    }

    get brandId()
    {
        return this.targetForm.get('brandId').value;
    }
}
