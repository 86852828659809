import { AfterViewInit, Component, ContentChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandModel } from 'src/app/models/brand.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { BrandsService } from 'src/app/providers/brands.service';

@Component({
    selector: 'app-brands',
    templateUrl: './brand-list.page.html'
})
export class BrandListPage extends DatatablePage implements AfterViewInit {
    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    public brands: BrandModel[];

    constructor(
        private brandService: BrandsService,
        private modalService: NgbModal
    ) {
        super();
    }

    ngAfterViewInit() {
        this.refreshList();
        this.refreshDt();
    }

    refreshList() {
        this.brandService.search().subscribe((data: any) => {
            this.brands = data.content;
            this.reRenderDt();
        });
    }

    delete(content, brandId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result)
            {
                this.brandService.delete(this.brands.find( a => a.id === brandId)).subscribe(
                    x => {
                        this.refreshList();
                    }
                );
            }

        }, (reason) => {
        });
    }
}
