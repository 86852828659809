import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import saveAs from 'file-saver';
import * as moment from 'moment';
import { UserModel } from 'src/app/models/user.model';
import { UserService } from 'src/app/providers/user.service';
import { ActivitiesService } from '../../../providers/activities.service';
import { ActivityListComponent } from './activity/list/activity-list.page';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.page.html'
})
export class DashboardPage implements OnInit {
    @ViewChild(ActivityListComponent, null)
    private activityListComp: ActivityListComponent;

    private activities;
    public ambassadorId: number;
    public user: UserModel;

    constructor(
        private activitiesService: ActivitiesService,
        private router: Router,
        private userService: UserService,
        private ngxService: NgxUiLoaderService,
    ) {
        this.user = this.userService.user;
    }

    ngOnInit() {
        this.activitiesService.list().subscribe(
            data => {
                if (data == null) {
                    this.router.navigate(['login']);
                }
                else {
                    this.activities = data;
                }
            },
            err => {
                this.router.navigate(['login']);
            }
        );

        const ambassadorId = Number(sessionStorage.getItem('filterByAmbassador'));
        this.ambassadorId = ambassadorId ? ambassadorId : this.userService.user.id;

    }

    public exportActivities() {
        this.ngxService.start();
        this.activitiesService.export(this.ambassadorId, this.activityListComp.searchStr).subscribe(fileContent => {
            const blob = new Blob([fileContent], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'export-activities-' + moment().format('Y-M-D').toString() + '.xlsx');
            this.ngxService.stop();
        }, () => this.ngxService.stop());
    }

    public exportAll() {
        this.ngxService.start();
        this.activitiesService.exportAll().subscribe(fileContent => {
            const blob = new Blob([fileContent], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'export-activities-' + moment().format('Y-M-D').toString() + '.xlsx');
            this.ngxService.stop();
        }, () => this.ngxService.stop());
    }

    public onAdd() {
        this.router.navigate(['/workspace/activity/add/' + this.userService.user.id]);
    }

    public onFilterByAmbassadorChange(ev) {
        sessionStorage.setItem('filterByAmbassador', JSON.stringify(ev));
    }
}
