export class AttendeeTypeModel {
    public id: number;
    public attendeeTypeGlobal: any;
    public name: string;
    public region: any;

    constructor(attendeeType: Partial<AttendeeTypeModel>) {
        this.id = attendeeType.id;
        this.attendeeTypeGlobal = attendeeType.attendeeTypeGlobal;
        this.name = attendeeType.name;
        this.region = attendeeType.region;
    }

    clone(): AttendeeTypeModel
    {
        const c = new AttendeeTypeModel({});
        return Object.assign(c, this);
    }
}
