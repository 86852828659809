import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AttendeeContactModel } from 'src/app/models/attendee-contact.model';
import { ContactTitleModel } from 'src/app/models/contact-title.model';
import { FormPage } from 'src/app/pages/FormPage';
import { ContactTitlesService } from 'src/app/providers/contact-titles.service';
import { ContactsService } from 'src/app/providers/contacts.service';
import { GlobalMessage } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-contact-edit',
    templateUrl: './contact-edit.page.html'
})
export class ContactEditPage extends FormPage implements OnInit {
    @ViewChild('datePickerBirthday', null) datePickerBirthday: NgbDatepicker;

    public titles: ContactTitleModel[];

    public contactForm = this.fb.group({
        contactId: [''],
        firstName: ['', Validators.required],
        middleName: [''],
        lastName: ['', Validators.required],
        titleId: ['', Validators.required],
        attendeesTypeId: ['', Validators.required],
        language: ['', Validators.required],
        countryId: ['', Validators.required],
        phone: [''],
        mobile: [''],
        email: ['', [Validators.email, Validators.required]],
        birthday: [''],
        note: [''],
        newsletter: [''],
        influencerLevelId: ['', Validators.required],
        hobbies: [''],
        focusBrands: [''],
    });

    public contactId: number = null;
    public minDate = { year: moment().subtract(100, 'years').year(), month: 1, day: 1 };
    public maxDate = { year: moment().subtract(18, 'years').year(), month: 12, day: 31 };

    constructor(
        private fb: FormBuilder,
        private contactService: ContactsService,
        private contactTitlesService: ContactTitlesService,
        private route: ActivatedRoute,
        private router: Router,
        private errorHandler: GlobalMessage) {
        super();
    }

    @Input() set contact(data: AttendeeContactModel) {
        if (data !== undefined) {
            const birthdayDate = new NgbDate(data.birthday.year(), data.birthday.month() + 1, data.birthday.date());
            this.contactForm.patchValue({
                contactId: data.id,
                firstName: data.firstName,
                middleName: data.middleName,
                lastName: data.lastName,
                titleId: data.title.id,
                attendeesTypeId: data.attendeesType ? data.attendeesType.id : null,
                language: data.language ? data.language.id : null,
                countryId: data.country ? data.country.id : null,
                phone: data.phone,
                mobile: data.mobile,
                email: data.email,
                birthday: birthdayDate,
                note: data.note,
                newsletter: data.newsletter,
                influencerLevelId: data.influencerLevel ? data.influencerLevel.id : null,
                hobbies: data.hobbies.map(h => h.id),
                focusBrands: data.focusBrands.map(f => f.id),
            });
            this.datePickerBirthday.navigateTo(birthdayDate);
        }
    }

    @Input() returnToList = true;

    @Output() creationSuccess = new EventEmitter<AttendeeContactModel>();

    ngOnInit() {
        this.contactTitlesService.search({ searchStr: '' }).subscribe(data => this.titles = data);

        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null) {
                this.contactId = Number.parseInt(params.get('id'), 10);
                this.contactService.info(Number.parseInt(params.get('id'), 10)).subscribe(
                    (data) => {
                        this.contact = data;
                    }
                );
            }
        });
    }

    onSubmit() {
        if (this.contactForm.valid) {
            const contact = this.contactForm.value;
            this.contactService.createOrEdit(new AttendeeContactModel({
                ID: contact.contactId,
                firstname: contact.firstName,
                middlename: contact.middleName,
                lastname: contact.lastName,
                title_id: contact.titleId,
                attendee_type_id: contact.attendeesTypeId,
                lang: contact.language,
                country_id: contact.countryId,
                phone: contact.phone,
                mobile: contact.mobile,
                email: contact.email,
                birthday: contact.birthday.year + '-' + contact.birthday.month + '-' + contact.birthday.day,
                note: contact.note,
                newsletter: contact.newsletter,
                influencer_level: contact.influencerLevelId,
                hobbies: contact.hobbies,
                focus_brands: contact.focusBrands,
            })).subscribe((data: any) => {
                if (data.status === 'success') {
                    this.errorHandler.showSuccess('Your contact has been saved successfully', 5000);
                    this.creationSuccess.emit(new AttendeeContactModel(data.data.contact));
                    if (this.returnToList) {
                        this.router.navigate(['/workspace/contacts']);
                    }
                }
                else {
                    this.errorHandler.showError(data.error.error_msg);
                }
            });
        }
        else {
            this.validateAllFormFields(this.contactForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.contactForm;
    }
}
