import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttendeeContactModel } from 'src/app/models/attendee-contact.model';
import { ActivitiesService } from 'src/app/providers/activities.service';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide : NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AttendeeContactSelectComponent),
    multi : true,
};
@Component({
    selector: 'app-attendee-contact-select',
    templateUrl: './attendee-contact-select.component.html',
    providers : [CUSTOM_VALUE_ACCESSOR]
})
export class AttendeeContactSelectComponent implements ControlValueAccessor, AfterViewInit {
    @Input() inputId: string;
    @Input() countryId: number;
    @Input() languageId: string;
    @Input() clearAfterSelect = false;

    @Output() change = new EventEmitter<number>();

    public disabled = false;
    public value: number = undefined;
    public contacts: AttendeeContactModel[];

    private isAdding = false;
    private isLoading = false;
    private selectize: any = null;
    private _attendeesTypeId: number;
    private onChange: Function = (_: any) => {};
    private onTouched: Function = () => {};

    constructor(private activityService: ActivitiesService, private modalService: NgbModal) {}

    @Input() set attendeesTypeId(val)
    {
        this._attendeesTypeId = val;
        this.refresh();
    }

    get attendeesTypeId()
    {
        return this._attendeesTypeId;
    }

    ngAfterViewInit()
    {
        const selectizeObj = $('#' + this.inputId)['selectize']({
            placeholder: 'Select / Create a contact',
            create: true,
            options: []
        });
        this.selectize = selectizeObj[0].selectize;

        this.selectize.on('option_add', (value) => {
            if (!this.isLoading)
            {
                this.isAdding = true;

                const modalRef = this.modalService.open(ContactModalComponent);
                modalRef.componentInstance.contact =
                    new AttendeeContactModel({
                        firstname: value, attendee_type_id: this.attendeesTypeId, country_id: this.countryId, lang: this.languageId});

                modalRef.result.then( contact => {
                    this.emitChangeValue(parseInt(contact.id, 10));
                    this.isAdding = false;
                    modalRef.close();

                    if (this.clearAfterSelect)
                    {
                        this.selectize.clear(true);
                    }
                },
                () => {
                    this.selectize.clear();
                    this.selectize.removeOption(value);
                    this.isAdding = false;
                });
            }
        });

        this.selectize.on('change', (value) => {
            if (!this.isAdding && !this.isLoading)
            {
                this.emitChangeValue(parseInt(value, 10));

                if (this.clearAfterSelect)
                {
                    this.selectize.clear(true);
                }
            }
        });
    }

    private refresh()
    {
        this.isLoading = true;
        this.activityService.getAttendeesContacts(this.countryId, this.attendeesTypeId).subscribe(data => {
            this.contacts = data;
            this.selectize.clear(true);
            this.selectize.clearOptions();

            for (const contact of this.contacts)
            {
                this.selectize.addOption({
                    value: contact.id,
                    text: contact.name
                });
            }
            this.selectize.refreshOptions(false);
            this.selectize.setValue(this.value);
            this.isLoading = false;
        });
    }

    writeValue(obj: any): void {
        this.value = parseInt(obj, 10);
        if (this.selectize)
        {
            this.selectize.setValue(this.value);
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    contactChange(ev)
    {
        this.emitChangeValue(parseInt(ev.target.value, 10));
    }

    emitChangeValue(value: number)
    {
        this.value = value;
        this.onChange(value);
        this.onTouched();
        this.change.emit(value);
    }
}
