import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CountryModel } from '../models/country.model';
import { CountriesApiService } from './countries-api.service';
import { Observable } from 'rxjs';
import { RegionModel } from '../models/region.model';
import { ClusterModel } from '../models/cluster.model';

@Injectable({
  providedIn: 'root'
})
export class CountriesService
{
    constructor(
        private countriesApi: CountriesApiService
    ) { }

    public list(size: number, page?: number)
    {
        const countries: CountryModel[] = [];
        return this.countriesApi.list(size, page).pipe(
            map( l => {
                if (l.content)
                {
                    l.content.forEach( element => countries.push(new CountryModel(element)));
                }
                return countries;
            })
        );
    }

    public search() {
        return this.countriesApi.search();
    }

    public info(countryId: number): Observable<CountryModel>
    {
        return this.countriesApi.info(countryId).pipe(
            map( res => new CountryModel(res))
        );
    }

    public delete(country: CountryModel)
    {
        return this.countriesApi.delete(country.id);
    }

    public createOrEdit(country: CountryModel)
    {
        return this.countriesApi.createOrEdit(country);
    }

    public getRegions(): Observable<RegionModel[]>
    {
        return this.countriesApi.getRegions().pipe(
            map(res => {
                const regions: RegionModel[] = [];
                if (res)
                {
                    res.forEach( region => {
                        regions.push(new RegionModel(region));
                    });
                }

                return regions;
            })
        );
    }

    public getClusters(): Observable<ClusterModel[]>
    {
        return this.countriesApi.getClusters().pipe(
            map(res => {
                const clusters: ClusterModel[] = [];
                if (res)
                {
                    res.forEach( cluster => {
                        clusters.push(new ClusterModel(cluster));
                    });
                }
                return clusters;
            })
        );
    }
}
