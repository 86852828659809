import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AttendeeContactModel } from 'src/app/models/attendee-contact.model';

@Component({
    selector: 'app-contact-modal',
    templateUrl: './contact-modal.component.html'
})
export class ContactModalComponent
{
    constructor(public activeModal: NgbActiveModal)
    {

    }

    @Input() contact: AttendeeContactModel;

    onCreationSuccess(contact: AttendeeContactModel)
    {
        this.activeModal.close(contact);
    }
}
