import { AfterViewInit, Component, OnInit } from '@angular/core';
import { WinningInModel } from 'src/app/models/winning-in.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { UserService } from 'src/app/providers/user.service';
import { OutletWinningInService } from 'src/app/providers/outlet-winning-in.service';

@Component({
    selector: 'app-outlet-winning-in-list',
    templateUrl: './outlet-winning-in-list.page.html'
})
export class OutletWinningInListPage extends DatatablePage implements AfterViewInit, OnInit {
    public winningIns: WinningInModel[];
    public countryId: number;

    constructor(
        private outletWinningInService: OutletWinningInService,
        private userService: UserService
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.countryId = Number(sessionStorage.getItem('winninginByCountry'))
            ? Number(sessionStorage.getItem('winninginByCountry'))
            : this.userService.user.defaultCountryId;
    }

    ngAfterViewInit() {
        this.refreshList();
        this.refreshDt();
    }

    refreshList(e?) {
        if (this.countryId) {
            sessionStorage.setItem('winninginByCountry', JSON.stringify(this.countryId));
        } else {
            sessionStorage.removeItem('winninginByCountry');
        }

        const size = 999999;
        this.outletWinningInService.getWinningIns(this.countryId, size).subscribe(content => {
            this.winningIns = content;
            this.reRenderDt();
        });
    }
}
