import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BrandCategoryModel } from '../models/brand-category.model';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BrandCategoriesApiService {
    private endpoint = 'brandcategories';

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public list() {
        return this.api.get(this.endpoint + '/list', {}, { headers: { Authorization: this.user.token } })
            .pipe(map((res: any) => res));
    }

    public info(brandCategoryId: number): Observable<BrandCategoryModel> {
        return this.api.get(this.endpoint + '/' + brandCategoryId.toString(), {}, { headers: { Authorization: this.user.token } })
            .pipe(map((res: any) => res.data));
    }

    public delete(brandCategoryId: number) {
        return this.api.patch(this.endpoint + '/delete/' + brandCategoryId.toString(), {}, { headers: { Authorization: this.user.token } });
    }

    public createOrEdit(brandCategory: BrandCategoryModel) {
        let brandCategoryObject = null;

        const url = this.endpoint + '/save';
        if (brandCategory.id) {
            brandCategoryObject = {
                id: brandCategory.id,
                name: brandCategory.name
            };
        } else {
            brandCategoryObject = {
                name: brandCategory.name
            };
        }
        return this.api.post(url, brandCategoryObject, { headers: { Authorization: this.user.token } });
    }

    public getByCountry(countryId: number): Observable<any> {
        return this.api.get(this.endpoint + '/brandCategoryByCountry/' + countryId, {}, { headers: { Authorization: this.user.token } });
    }
}
