import { Component } from '@angular/core';

@Component({
    template: `
        <div class='workspace'>
            <div id='sidebar'>
                <app-panel-menu></app-panel-menu>
            </div>
            <div id='content' class='inner'>
                <router-outlet></router-outlet>
            </div>
        </div>
        `
})
export class WorkspaceComponent { }
