import { Component, forwardRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserModel } from 'src/app/models/user.model';
import { UserService } from 'src/app/providers/user.service';
import { UsersService } from 'src/app/providers/users.service';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FilterByAmbassadorComponent),
    multi: true,
};
@Component({
    selector: 'app-filter-by-ambassador',
    templateUrl: './filter-by-ambassador.component.html',
    providers: [CUSTOM_VALUE_ACCESSOR]
})
export class FilterByAmbassadorComponent implements ControlValueAccessor, OnInit {
    @Input() ambassadorId: string;
    @Input() inputId: string;
    @Output() dataChange = new EventEmitter();

    public disabled = false;
    public value: number;
    public loggedUserId: number;
    public ambassadors: UserModel[];

    private onChange: Function = (_: any) => { };
    private onTouched: Function = () => { };

    constructor(
        private usersService: UsersService,
        private userService: UserService
    ) {
        this.loggedUserId = this.userService.user.id;
    }

    ngOnInit() {
        if (this.userService.user.isAdmin || this.userService.user.isManager) {
            this.usersService.listUsers().subscribe(res => {
                this.ambassadors = res;
            });
        }
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public ambassadorChange(ev) {
        this.value = Number.parseInt(ev.target.value, 10);
        this.onChange(this.value);
        this.onTouched();
        this.dataChange.emit(this.value);
    }
}
