import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { GlobalMessage } from './global-message.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

const HEADERS = new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded');

export interface ResponseStatus {
    success: boolean;
    text: string;
    tokenValidity: boolean;
}
@Injectable()
export class ApiService {
    private readonly url: string = environment.serviceUrl;
    private readonly api_key: string = environment.apikey;
    private readonly oauth2ClientId: string = environment.oauth2ClientId;
    private readonly oauth2ClientSecret: string = environment.oauth2ClientSecret;
    private readonly OAUTH2_URL = environment.serviceUrl.replace('/v2/ambassador/api', '/v1/oauthv2/cors/token');

    constructor(
        public http: HttpClient,
        private messageHandler: GlobalMessage,
        private router: Router
    ) { }

    get(endpoint: string, params?: any, reqOpts: any = {}): Observable<Object> {
        if (!reqOpts) {
            reqOpts = {
                params: new HttpParams(),
            };
        }

        if (params) {
            reqOpts.params = new HttpParams();
            for (const k in params) {
                if (params[k]) {
                    reqOpts.params = reqOpts.params.set(k, params[k]);
                }
            }
        }

        // Add authentication to headers
        if (!reqOpts.headers) {
            reqOpts.headers = {
                apikey: this.api_key,
                jwt: sessionStorage.getItem('jwt'),
                uid: sessionStorage.getItem('uid'),
                Authorization: `Bearer ${sessionStorage.getItem('oauth')}`,
            };
        } else {
            reqOpts.headers['apikey'] = this.api_key;
            reqOpts.headers['jwt'] = sessionStorage.getItem('jwt');
            reqOpts.headers['uid'] = sessionStorage.getItem('uid');
            reqOpts.headers['Authorization'] = `Bearer ${sessionStorage.getItem('oauth')}`;
        }

        const options = {
            headers: reqOpts.headers,
            params: reqOpts.params,
            responseType: 'json' as 'json',
        };
        return this.http.get(this.url + '/' + endpoint, options).pipe(
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getExcel(endpoint: string, reqOpts: any = {}): Observable<any> {
        if (reqOpts.headers === undefined) {
            reqOpts = { headers: { 'Content-Type': 'application/json' } };
        } else if (reqOpts.headers['Content-Type'] === undefined) {
            reqOpts.headers['Content-Type'] = 'application/json';
        }

        // Add authorization to headers
        reqOpts.headers['apikey'] = this.api_key;
        reqOpts.headers['jwt'] = sessionStorage.getItem('jwt');
        reqOpts.headers['uid'] = sessionStorage.getItem('uid');
        reqOpts.headers['Authorization'] = `Bearer ${sessionStorage.getItem('oauth')}`;

        const options = {
            headers: reqOpts.headers,
            params: reqOpts.params,
            responseType: 'blob' as 'blob',
        };
        return this.http.get(this.url + '/' + endpoint, options).pipe(
            retry(1),
            catchError((e) => {
                const message =
                    e.error.message ||
                    'An error occurred on the server. Please retry later or contact your administrator';
                this.messageHandler.showError(
                    message,
                    undefined,
                    e.name + ': ' + e.message
                );
                return throwError(e);
            })
        );
    }

    getRaw(
        endpoint: string,
        params?: any,
        reqOpts: any = {}
    ): Observable<Blob> {
        if (!reqOpts) {
            reqOpts = {
                params: new HttpParams(),
            };
        }

        if (params) {
            reqOpts.params = new HttpParams();
            for (const k in params) {
                if (params[k]) {
                    reqOpts.params = reqOpts.params.set(k, params[k]);
                }
            }
        }

        // Add authentication to headers
        if (!reqOpts.headers) {
            reqOpts.headers = {
                apikey: this.api_key,
                jwt: sessionStorage.getItem('jwt'),
                uid: sessionStorage.getItem('uid'),
                Authorization: `Bearer ${sessionStorage.getItem('oauth')}`,
            };
        } else {
            reqOpts.headers['apikey'] = this.api_key;
            reqOpts.headers['jwt'] = sessionStorage.getItem('jwt');
            reqOpts.headers['uid'] = sessionStorage.getItem('uid');
            reqOpts.headers['Authorization'] = `Bearer ${sessionStorage.getItem('oauth')}`;
        }

        return this.http
            .get(this.url + '/' + endpoint, {
                headers: reqOpts.headers,
                params: reqOpts.params,
                responseType: 'blob',
            })
            .pipe(
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    post(endpoint: string, body: any, reqOpts: any = {}): Observable<any> {
        if (reqOpts.headers === undefined) {
            reqOpts = { headers: { 'Content-Type': 'application/json' } };
        } else if (reqOpts.headers['Content-Type'] === undefined) {
            reqOpts.headers['Content-Type'] = 'application/json';
        }

        // Add authorization to headers
        reqOpts.headers['apikey'] = this.api_key;
        reqOpts.headers['jwt'] = sessionStorage.getItem('jwt');
        reqOpts.headers['uid'] = sessionStorage.getItem('uid');
        reqOpts.headers['Authorization'] = `Bearer ${sessionStorage.getItem('oauth')}`;

        const options = {
            headers: reqOpts.headers,
            params: reqOpts.params,
            responseType: 'json' as 'json',
        };
        return this.http.post(this.url + '/' + endpoint, body, options).pipe(
            retry(1),
            catchError((e) => {
                const message = e.error
                    ? e.error.message
                    : 'An error occurred on the server. Please retry later or contact your administrator';
                this.messageHandler.showError(
                    message,
                    undefined,
                    e.name + ': ' + e.message
                );
                return throwError(e);
            })
        );
    }

    postExcel(endpoint: string, body: any, reqOpts: any = {}): Observable<any> {
        if (reqOpts.headers === undefined) {
            reqOpts = { headers: { 'Content-Type': 'application/json' } };
        } else if (reqOpts.headers['Content-Type'] === undefined) {
            reqOpts.headers['Content-Type'] = 'application/json';
        }

        // Add authorization to headers
        reqOpts.headers['apikey'] = this.api_key;
        reqOpts.headers['jwt'] = sessionStorage.getItem('jwt');
        reqOpts.headers['uid'] = sessionStorage.getItem('uid');
        reqOpts.headers['Authorization'] = `Bearer ${sessionStorage.getItem('oauth')}`;

        const options = {
            headers: reqOpts.headers,
            params: reqOpts.params,
            responseType: 'blob' as 'blob',
        };
        return this.http.post(this.url + '/' + endpoint, body, options).pipe(
            retry(1),
            catchError((e) => {
                const message =
                    e.error.message ||
                    'An error occurred on the server. Please retry later or contact your administrator';
                this.messageHandler.showError(
                    message,
                    undefined,
                    e.name + ': ' + e.message
                );
                return throwError(e);
            })
        );
    }

    postFile(endpoint: string, data: FormData, reqOpts: any = {}) {
        // Add authorization to headers
        reqOpts.headers['apikey'] = this.api_key;
        reqOpts.headers['jwt'] = sessionStorage.getItem('jwt');
        reqOpts.headers['uid'] = sessionStorage.getItem('uid');
        reqOpts.headers['Authorization'] = `Bearer ${sessionStorage.getItem('oauth')}`;

        const options = {
            headers: reqOpts.headers,
            params: reqOpts.params,
            responseType: 'blob' as 'blob',
        };
        return this.http.post(this.url + '/' + endpoint, data, options).pipe(
            retry(1),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    put(endpoint: string, body: any, reqOpts?: any) {
        if (reqOpts.headers) {
            reqOpts.headers['Content-Type'] = 'application/json';
        } else {
            reqOpts = { headers: { 'Content-Type': 'application/json' } };
        }

        // Add authorization to headers
        reqOpts.headers['apikey'] = this.api_key;
        reqOpts.headers['jwt'] = sessionStorage.getItem('jwt');
        reqOpts.headers['uid'] = sessionStorage.getItem('uid');
        reqOpts.headers['Authorization'] = `Bearer ${sessionStorage.getItem('oauth')}`;

        const options = {
            headers: reqOpts.headers,
            params: reqOpts.params,
            responseType: 'json' as 'json',
        };
        return this.http.put(this.url + '/' + endpoint, body, options).pipe(
            retry(1),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    delete(endpoint: string, body: any, reqOpts?: any) {
        if (reqOpts.headers) {
            reqOpts.headers['Content-Type'] = 'application/json';
        } else {
            reqOpts = { headers: { 'Content-Type': 'application/json' } };
        }

        // Add authorization to headers
        reqOpts.headers['apikey'] = this.api_key;
        reqOpts.headers['jwt'] = sessionStorage.getItem('jwt');
        reqOpts.headers['uid'] = sessionStorage.getItem('uid');
        reqOpts.headers['Authorization'] = `Bearer ${sessionStorage.getItem('oauth')}`;

        const options = {
            body: body,
            headers: reqOpts.headers,
            params: reqOpts.params,
            responseType: 'json' as 'json',
        };
        return this.http
            .request('delete', this.url + '/' + endpoint, options)
            .pipe(
                retry(1),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    patch(endpoint: string, body: any, reqOpts?: any) {
        // Add authorization to headers
        reqOpts.headers['apikey'] = this.api_key;
        reqOpts.headers['jwt'] = sessionStorage.getItem('jwt');
        reqOpts.headers['uid'] = sessionStorage.getItem('uid');
        reqOpts.headers['Authorization'] = `Bearer ${sessionStorage.getItem('oauth')}`;

        return this.http.patch(this.url + '/' + endpoint, body, reqOpts).pipe(
            retry(1),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    private handleError(error: any): Observable<never> {
        if (
            error.status === 0
            && error.url && error.url.includes('/getInfoUid/')
        ) {
            this.messageHandler.showError(
                'Your user is not authorized. Please contact a admin.',
                undefined,
                undefined
            );
        } else {
            this.messageHandler.showError(
                'An error occurred on the server. Please retry later or contact your administrator',
                undefined,
                error.name + ': ' + error.message
            );
        }

        return throwError(error);
    }

    postOauth(): Observable<HttpResponse<any>> {
        const headers = HEADERS;
        const body = new HttpParams()
            .set('client_id', this.oauth2ClientId)
            .set('client_secret', this.oauth2ClientSecret)
            .set('grant_type', 'client_credentials');
        return this.http.post(this.OAUTH2_URL, body, {
            headers: headers,
            observe: 'response',
        }).pipe(
            map((res: any) => res),
            catchError((err) => throwError(err))
        );
    }
}
