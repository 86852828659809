import { AfterViewInit, Component, ContentChild, TemplateRef, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DatatablePage } from '../../../../DatatablePage';
import { ActivityModel } from '../../../../../models/activity.model';
import { ActivitiesService } from '../../../../../providers/activities.service';
import { UserService } from '../../../../../providers/user.service';

@Component({
    selector: 'app-activity-list',
    templateUrl: './activity-list.page.html'
})
export class ActivityListComponent extends DatatablePage implements OnInit, AfterViewInit {
    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    public activities: ActivityModel[];

    // Save search string for use in export fns
    public searchStr: string;

    private _filterByAmbassador = null;
    @Input() set filterByAmbassador(id: number) {
        this._filterByAmbassador = id;
        this.reRenderDt();
    }
    get filterByAmbassador() {
        return this._filterByAmbassador;
    }

    constructor(
        private activityService: ActivitiesService,
        private userService: UserService,
        private modalService: NgbModal,
        private ngxService: NgxUiLoaderService
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();

        this.dtOptions.pageLength = 25;
        this.dtOptions.pagingType = 'full_numbers';
        this.dtOptions.serverSide = true;
        this.dtOptions.processing = true;
        this.dtOptions.order = [[0, 'desc']];
        this.dtOptions.columnDefs = [
            { 'orderable': false, 'targets': 1 }
        ];

        const that = this;
        this.dtOptions.ajax = (dataTablesParameters: any, callback) => {

            // Save search string for use in export fns
            this.searchStr = dataTablesParameters.search.value;

            that.activityService.list(
                dataTablesParameters.length,
                dataTablesParameters.start,
                dataTablesParameters.search.value,
                'date', // No se puede ordenar por otro campo en la query a BBDD
                dataTablesParameters.order[0].dir,
                that.filterByAmbassador ? that.filterByAmbassador : that.userService.user.id
            ).subscribe(data => {
                if (data && data.content) {
                    that.activities = data.content;
                    if (data.totalElements) {
                        callback({
                            recordsTotal: data.totalElements,
                            recordsFiltered: data.totalElements,
                            data: []
                        });
                    }
                } else {
                    that.activities = [];
                    callback({
                        recordsTotal: 0,
                        recordsFiltered: 0,
                        data: []
                    });
                }
            });
        };
    }

    ngAfterViewInit() {
        this.refreshDt();
    }

    delete(content, activityId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result) {
                this.activityService.delete(this.activities.find(a => a.id === activityId)).subscribe(
                    x => {
                        this.reRenderDt();
                    }
                );
            }

        }, (reason) => {
        });
    }

}
