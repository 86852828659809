import { Component } from '@angular/core';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.page.html'
})
export class NotFoundPage
{
    constructor() {}
}
