import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Message {
    type: string;
    content: string;
    details?: string;
    timeout: number;
}

@Injectable()
export class GlobalMessage
{
    public message: Subject<Message>;

    constructor()
    {
        this.message = new Subject();
    }

    private show(content: string, type: string, timeout: number = 10000, details?: string)
    {
        this.message.next({
            type,
            content,
            details,
            timeout
        });
    }

    public showError(content: string, timeout: number = 10000, details?: string)
    {
        this.show(content, 'danger', timeout, details);
    }

    public showInfo(content: string, timeout: number = 10000, details?: string)
    {
        this.show(content, 'info', timeout, details);
    }

    public showSuccess(content: string, timeout: number = 10000, details?: string)
    {
        this.show(content, 'success', timeout, details);
    }

    public showWarning(content: string, timeout: number = 10000, details?: string)
    {
        this.show(content, 'warning', timeout, details);
    }

    public showPrimary(content: string, timeout: number = 10000, details?: string)
    {
        this.show(content, 'primary', timeout, details);
    }

    public showSecondary(content: string, timeout: number = 10000, details?: string)
    {
        this.show(content, 'secondary', timeout, details);
    }

    public showDark(content: string, timeout: number = 10000, details?: string)
    {
        this.show(content, 'dark', timeout, details);
    }
}
