import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { AttendeeContactModel } from '../models/attendee-contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactsApiService {
    private endpointMulti = 'contacts';
    private endpointSingle = 'contact';

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public list(attendeesTypeId: number = 0)
    {
        const params = {};
        if (attendeesTypeId)
        {
            params['attendees_type_id'] = attendeesTypeId;
        }

        return this.api.get(this.endpointMulti, params, {headers: {Authorization: this.user.token}})
        .pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }

    public info(contactId: number)
    {
        return this.api.get(this.endpointSingle + '/' + contactId.toString(), {}, {headers: {Authorization: this.user.token}})
        .pipe(
            map(
                (res: any) => {
                    return res.data;
                }
            )
        );
    }

    public delete(contactId: number)
    {
        return this.api.delete(this.endpointSingle + '/' + contactId.toString(), {}, {headers: {Authorization: this.user.token}});
    }

    public createOrEdit(contact: AttendeeContactModel)
    {
        const contactObject = {
            firstname: contact.firstName,
            middlename: contact.middleName,
            lastname: contact.lastName,
            title_id: contact.title.id,
            attendees_type_id: contact.attendeesType.id,
            lang: contact.language.id,
            country_id: contact.country.id,
            phone: contact.phone,
            mobile: contact.mobile,
            email: contact.email,
            birthday: contact.birthday.format('YYYY-MM-DD'),
            note: contact.note,
            newsletter: contact.newsletter,
            influencer_level: contact.influencerLevel.id,
            hobbies: contact.hobbies.map(h => h.id),
            focus_brands: contact.focusBrands.map(f => f.id)
        };

        let url = this.endpointSingle;
        if (contact.id)
        {
            url = url + '/' + contact.id.toString();
        }

        return this.api.post(url, contactObject, {headers: {Authorization: this.user.token}});
    }

    public ownedByUser()
    {
        const params = {};

        return this.api.get('user_contacts', params, {headers: {Authorization: this.user.token}})
        .pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }

    public getActivities(contactId: number)
    {
        const params = {};
        if (contactId)
        {
            params['contact_id'] = contactId;
        }

        return this.api.get('contact_activities', {}, {headers: {Authorization: this.user.token}})
        .pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }
}
