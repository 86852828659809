import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { InfluencerLevelModel } from '../models/influencer-level.model';

@Injectable({
    providedIn: 'root'
})
export class InfluencerLevelsApiService {
    private endpointRegion = 'influencerslevelsregion';
    private endpoint = 'influencerslevels';
    private requestHeader = {
        headers: { Authorization: this.user.token }
    };

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public search(body: any, size?: number, page?: number) {
        const params = { size: size, page: page };

        return this.api.post(this.endpointRegion
            + '/search/'
            + this.user.user.id, body,
            {
                headers: { Authorization: this.user.token },
                params: params
            })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public info(influencerLevelId: number) {
        return this.api.get(this.endpoint + '/' + influencerLevelId.toString(), {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res.data;
                    }
                )
            );
    }

    public delete(influencerLevelId: number) {
        return this.api.patch(this.endpointRegion
            + '/delete/'
            + influencerLevelId.toString(),
            {},
            { headers: { Authorization: this.user.token }, observe: 'response' });
    }

    public createOrEdit(influencerLevel: InfluencerLevelModel) {
        let influencerLevelObject: any;
        const url = this.endpointRegion + '/save';
        if (influencerLevel.id) {
            influencerLevelObject = {
                id: influencerLevel.id,
                name: influencerLevel.name,
                region: {
                    id: influencerLevel.region.id,
                    code: influencerLevel.region.code,
                    name: influencerLevel.region.name,
                },
                influencerLevelsGlobal: {
                    id: influencerLevel.influencerLevelsGlobal.id,
                    hobby: influencerLevel.influencerLevelsGlobal.name,
                }
            };
        } else {
            influencerLevelObject = {
                name: influencerLevel.name,
                region: {
                    id: influencerLevel.region.id,
                    code: influencerLevel.region.code,
                    name: influencerLevel.region.name,
                },
                influencerLevelsGlobal: {
                    id: influencerLevel.influencerLevelsGlobal.id,
                    hobby: influencerLevel.influencerLevelsGlobal.name,
                }
            };
        }
        return this.api.post(url, influencerLevelObject, { headers: { Authorization: this.user.token }, observe: 'response' });
    }

    public getGlobals() {
        const params = {};
        return this.api.get(this.endpoint + '/list', params, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public createGlobal(global) {
        return this.api.post(`${this.endpoint}/add`, global, this.requestHeader);
    }
}
