import { Injectable } from '@angular/core';
import { OutletsApiService } from './outlets-api.service';
import { OutletModel } from '../models/outlet.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OutletsService {
    constructor(
        private outletsApi: OutletsApiService
    ) { }

    public list(resultsNum: number = 20,
        offset: number = 0,
        search: string = '',
        orderBy: string = 'name',
        order: string = 'ASC',
        filterByCountry: number = null,
        filterByWinningIn: number = null,
        filterByPosSegment: number = null,
        filterByActivitySegment: number = null) {
        return this.outletsApi.list(resultsNum,
            offset,
            search,
            orderBy,
            order,
            filterByCountry,
            filterByWinningIn,
            filterByPosSegment,
            filterByActivitySegment).pipe(
                map(res => res)
            );
    }

    public info(outletId: number): Observable<OutletModel> {
        return this.outletsApi.info(outletId).pipe(
            map(res => res)
        );
    }

    public delete(outlet: OutletModel) {
        return this.outletsApi.delete(outlet.id);
    }

    public createOrEdit(outlet: OutletModel) {
        return this.outletsApi.createOrEdit(outlet);
    }

    public getExport(countryId: number, winningInId: number, posSegmentId: number,
        activitySegmentId: number, searchStr: string): Observable<string> {
        return this.outletsApi.getExport(countryId, winningInId, posSegmentId, activitySegmentId, searchStr);
    }

    public importXls(countryId: number, file: File) {
        return this.outletsApi.importXls(countryId, file);
    }

    public confirmImportXls(importId: number) {
        return this.outletsApi.confirmImportXls(importId);
    }

    public getByCountry(countryId: number) {
        return this.outletsApi.getByCountry(countryId);
    }

    public getOutletManagersByCountry(countryId: number): Observable<any> {
        return this.outletsApi.getOutletManagersByCountry(countryId);
    }
}
