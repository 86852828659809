import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormPage } from 'src/app/pages/FormPage';
import { WorkHistoryService } from 'src/app/providers/work-history.service';
import { WorkHistoryModel } from 'src/app/models/work-history.model';
import { GlobalMessage } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-work-history-edit.page',
    templateUrl: './work-history-edit.page.html'
})
export class WorkHistoryEditPage extends FormPage implements OnInit
{
    public workHistoryForm = this.fb.group({
        workHistoryId: [''],
        outletId: ['', Validators.required],
        from: ['', Validators.required],
        until: ['', Validators.required],
    });

    public workHistoryId: number = null;
    public userId: number = null;

    constructor(
        private fb: FormBuilder,
        private workHistoryService: WorkHistoryService,
        private route: ActivatedRoute,
        private router: Router,
        private errorHandler: GlobalMessage)
    {
        super();
    }

    ngOnInit()
    {
        this.route.paramMap.subscribe(params => {
            if (params.get('userid'))
            {
                this.userId = Number.parseInt(params.get('userid'), 10);
            }
            if (params.get('id') !== null)
            {
                this.workHistoryId = Number.parseInt(params.get('id'), 10);
                this.workHistoryService.info(Number.parseInt(params.get('id'), 10)).subscribe(
                    (data) => {
                        this.workHistoryForm.setValue({
                            workHistoryId: data.id,
                            outletId: data.outlet ? data.outlet.id : null,
                            from: {year: data.from.year(), month: data.from.month() + 1, day: data.from.day()},
                            until: {year: data.until.year(), month: data.until.month() + 1, day: data.until.day()}
                        });
                    }
                );
            }
        });
    }

    onSubmit()
    {
        if (this.workHistoryForm.valid)
        {
            const workHistory = this.workHistoryForm.value;
            this.workHistoryService.createOrEdit(new WorkHistoryModel({
                ID: workHistory.workHistoryId,
                work_from: workHistory.from.year + '-' + workHistory.from.month + '-' + workHistory.from.day,
                work_until: workHistory.until.year + '-' + workHistory.until.month + '-' + workHistory.until.day,
                person_id: this.userId,
                outlet_id: workHistory.outletId
            })).subscribe((data: any) => {
                if (data.status === 'success')
                {
                    this.errorHandler.showSuccess('Your work history has been saved successfully', 5000);
                    this.router.navigate(['/contact/' + this.userId]);
                }
                else
                {
                    this.errorHandler.showError(data.error.error_msg);
                }
            });
        }
        else
        {
            this.validateAllFormFields(this.workHistoryForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.workHistoryForm;
    }
}
