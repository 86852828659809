import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OutletFormComponent } from './outlet-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CountrySelectComponent } from '../country-select/country-select.component';
import { FieldErrorDisplayComponent } from '../field-error-display/field-error-display.component';



@NgModule({
  declarations: [OutletFormComponent, CountrySelectComponent, FieldErrorDisplayComponent],
  exports: [OutletFormComponent, CountrySelectComponent, FieldErrorDisplayComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ]
})
export class OutletFormModule { }
