import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
import { StorageServiceModule } from 'angular-webstorage-service';
import { ChartsModule } from 'ng2-charts';
import { HotTableModule } from 'ng2-handsontable';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ActivityCountrySelectComponent } from '../../components/activity-country-select/activity-country-select.component';
import { ActivityTypeSelectComponent } from '../../components/activity-type-select/activity-type-select.component';
import { AttendeeContactSelectComponent } from '../../components/attendee-contact-select/attendee-contact-select.component';
import { AttendeeTypeSelectComponent } from '../../components/attendee-type-select/attendee-type-select.component';
import { BrandCategorySelectComponent } from '../../components/brand-category-select/brand-category-select.component';
import { BrandSelectComponent } from '../../components/brand-select/brand-select.component';
import { CountrySelectComponent } from '../../components/country-select/country-select.component';
import { CountryByUserComponent } from '../../components/country-by-user/filter-by-admin-country.component';
import { FilterByAmbassadorComponent } from '../../components/filter-by-ambassador/filter-by-ambassador.component';
import { GlobalSelectComponent } from '../../components/global-select/global-select.component';
import { HobbySelectComponent } from '../../components/hobby-select/hobby-select.component';
import { InfluencerLevelSelectComponent } from '../../components/influencer-level-select/influencer-level-select.component';
import { LanguageSelectComponent } from '../../components/language-select/language-select.component';
import { ManagerSelectComponent } from '../../components/manager-select/manager-select.component';
import { OutletSelectComponent } from '../../components/outlet-select/outlet-select.component';
import { ProgramSelectComponent } from '../../components/program-select/program-select.component';
import { RoleSelectComponent } from '../../components/role-select/role-select.component';
import { SocialListComponent } from '../../components/social-list/social-list.component';
import { StatisticsFilterComponent } from '../../components/statistics-filter/statistics-filter.component';
import { StatisticsViewComponent } from '../../components/statistics-view/statistics-view.component';
import { TargetDashboardComponent } from '../../components/target-dashboard/target-dashboard.component';
import { TargetTypeSelectComponent } from '../../components/target-type-select/target-type-select.component';
import { UserSelectComponent } from '../../components/user-select/user-select.component';
import { WorkHistoryComponent } from '../../components/work-history/work-history.component';
import { PanelMenuModule } from '../../layout/panel-menu/panel-menu.module';
import { NotFoundPage } from '../not-found/not-found.page';
import { ActivityTypeEditPage } from './admin/activity-types/edit/activity-type-edit.page';
import { ActivityTypeListPage } from './admin/activity-types/list/activity-type-list.page';
import { AttendeesTypeEditPage } from './admin/attendees-type/edit/attendees-type-edit.page';
import { AttendeesTypeListPage } from './admin/attendees-type/list/attendees-type-list.page';
import { BrandCategoryEditPage } from './admin/brand-categories/edit/brand-category-edit.page';
import { BrandCategoryListPage } from './admin/brand-categories/list/brand-category-list.page';
import { BrandEditPage } from './admin/brands/edit/brand-edit.page';
import { BrandListPage } from './admin/brands/list/brand-list.page';
import { ContactSocialEditPage } from './admin/contact-socials/edit/contact-socials-edit.page';
import { ContactTitleEditPage } from './admin/contact-titles/edit/contact-titles-edit.page';
import { ContactTitleListPage } from './admin/contact-titles/list/contact-titles-list.page';
import { ContactEditPage } from './admin/contacts/edit/contact-edit.page';
import { ContactListPage } from './admin/contacts/list/contact-list.page';
import { ContactViewPage } from './admin/contacts/view/contact-view.page';
import { CountryEditPage } from './admin/country/edit/country-edit.page';
import { CountryListPage } from './admin/country/list/country-list.page';
import { HobbyEditPage } from './admin/hobbies/edit/hobby-edit.page';
import { HobbyListPage } from './admin/hobbies/list/hobby-list.page';
import { InfluencerLevelEditPage } from './admin/influencer-levels/edit/influencer-level-edit.page';
import { InfluencerLevelListPage } from './admin/influencer-levels/list/influencer-level-list.page';
import { OutletActivitySegmentEditPage } from './admin/outlet-activity-segments/edit/outlet-activity-segment-edit.page';
import { OutletActivitySegmentListPage } from './admin/outlet-activity-segments/list/outlet-activity-segment-list.page';
import { OutletPosSegmentEditPage } from './admin/outlet-pos-segments/edit/outlet-pos-segment-edit.page';
import { OutletPosSegmentListPage } from './admin/outlet-pos-segments/list/outlet-pos-segment-list.page';
import { OutletWinningInEditPage } from './admin/outlet-winning-in/edit/outlet-winning-in-edit.page';
import { OutletWinningInListPage } from './admin/outlet-winning-in/list/outlet-winning-in-list.page';
import { OutletListPage } from './admin/outlets/list/outlet-list.page';
import { ProgramEditPage } from './admin/programs/edit/program-edit.page';
import { ProgramListPage } from './admin/programs/list/program-list.page';
import { TargetEditPage } from './admin/targets/edit/target-edit.page';
import { TargetListPage } from './admin/targets/list/target-list.page';
import { UserEditPage } from './admin/users/edit/user-edit.page';
import { UserListPage } from './admin/users/list/user-list.page';
import { WorkHistoryEditPage } from './admin/work-history/edit/work-history-edit.page';
import { ActivityEditPage } from './dashboard/activity/edit/activity-edit.page';
import { ActivityListComponent } from './dashboard/activity/list/activity-list.page';
import { DashboardPage } from './dashboard/dashboard.page';
import { SettingsPage } from './settings/settings.page';
import { StatisticsPage } from './statistics/statistics.page';
import { TargetListStaticPage } from './target-list-static/target-list-static.page';
import { WorkspaceComponent } from './workspace.component';
import { OutletFormModule } from 'src/app/components/outlet-form/outlet-form.module';
import { OutletEditPage } from './admin/outlets/edit/outlet-edit.page';

@NgModule({
    declarations: [
        WorkspaceComponent,
        NotFoundPage,
        DashboardPage,
        ActivityListComponent,
        StatisticsPage,
        SettingsPage,
        ActivityEditPage,
        OutletListPage,
        OutletEditPage,
        CountryListPage,
        CountryEditPage,
        BrandCategoryListPage,
        BrandCategoryEditPage,
        ProgramListPage,
        ProgramEditPage,
        BrandListPage,
        BrandEditPage,
        ActivityTypeListPage,
        ActivityTypeEditPage,
        UserListPage,
        UserEditPage,
        LanguageSelectComponent,
        RoleSelectComponent,
        ManagerSelectComponent,
        ActivityCountrySelectComponent,
        StatisticsFilterComponent,
        StatisticsViewComponent,
        ContactListPage,
        ContactEditPage,
        AttendeeTypeSelectComponent,
        AttendeesTypeListPage,
        AttendeesTypeEditPage,
        InfluencerLevelSelectComponent,
        TargetListPage,
        TargetEditPage,
        UserSelectComponent,
        ActivityTypeSelectComponent,
        BrandSelectComponent,
        ProgramSelectComponent,
        TargetTypeSelectComponent,
        ContactTitleListPage,
        ContactTitleEditPage,
        GlobalSelectComponent,
        HobbyListPage,
        HobbyEditPage,
        InfluencerLevelListPage,
        InfluencerLevelEditPage,
        ContactViewPage,
        SocialListComponent,
        ContactSocialEditPage,
        WorkHistoryComponent,
        WorkHistoryEditPage,
        OutletSelectComponent,
        AttendeeContactSelectComponent,
        BrandCategorySelectComponent,
        OutletPosSegmentListPage,
        OutletPosSegmentEditPage,
        OutletActivitySegmentListPage,
        OutletActivitySegmentEditPage,
        OutletWinningInListPage,
        OutletWinningInEditPage,
        TargetDashboardComponent,
        HobbySelectComponent,
        CountryByUserComponent,
        FilterByAmbassadorComponent,
        TargetListStaticPage
    ],
    exports: [
        WorkspaceComponent
    ],
    imports: [
        NgbModule,
        NgbModalModule,
        BrowserModule,
        CommonModule,
        RouterModule,
        StorageServiceModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        DataTablesModule,
        NgxUiLoaderModule,
        ChartsModule,
        NgxDatatableModule,
        HotTableModule,
        NgxJsonViewerModule,
        PanelMenuModule,
        OutletFormModule
    ]
})
export class WorkspaceModule { }
