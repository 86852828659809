import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment.local';
import { EventModel } from '../models/event.model';


const URL_USER_APPROVE = 'users/approve';
const URL_USER_DENY = 'users/deny';

@Injectable({
    providedIn: 'root'
})

export class UsersApiService {
    private endpointMulti = 'users';
    private endpointSingle = 'users';
    private endpointRol = 'roles';

    private requestHeader: any = {
        headers: { Authorization: this.user.token }
    };

    constructor(
        private api: ApiService,
        private user: UserService) { }


    public activate(userId: number) {
        return this.api.get(this.endpointSingle + '/reactivate/' + userId.toString(), {}, { headers: { Authorization: this.user.token } });
    }

    public list(body: any = {}, paramTable?) {
        switch (paramTable.orderBy) {
            case 0:
                paramTable.orderBy = 'lastName';
                break;
            case 1:
                paramTable.orderBy = 'email';
                break;
            case 2:
                paramTable.orderBy = 'userCountries';
                break;
            case 3:
                paramTable.orderBy = 'rol.name';
                break;
            case 4:
                paramTable.orderBy = 'manager.lastName';
                break;
            case 5:
                paramTable.orderBy = 'isAdmin';
                break;
            case 6:
                paramTable.orderBy = 'isActive';
                break;
            case 7:
                paramTable.orderBy = 'deleted';
                break;
            default:
                paramTable.orderBy = 'id';
                break;
        }

        if (body && body.searchStr === '') {
            body.searchStr = null;
        }

        if (paramTable) {
            const param = {
                headers: { Authorization: this.user.token },
                params: {
                    size: paramTable.resultsNum,
                    page: paramTable.offset / paramTable.resultsNum,
                    sort: paramTable.orderBy + ',' + paramTable.order
                }
            };
            return this.api.post(`${this.endpointMulti}/search/` + this.user.user.id, body, param);
        } else {
            return this.api.post(`${this.endpointMulti}/search` + this.user.user.id, body, this.requestHeader);
        }
    }

    public info(userId: number) {
        return this.api.get(this.endpointSingle + '/getInfo/' + userId, {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public getInfoByUID(uid: string) {
        return this.api.get(this.endpointSingle + '/getInfoUid/' + uid,
            {}, { headers: { Authorization: this.user.token } });
    }

    public delete(userId: number) {
        return this.api.patch(this.endpointSingle + '/delete/' + userId.toString(), {}, { headers: { Authorization: this.user.token } });
    }

    public createOrEdit(user: any) {
        if (!user.id) {
            delete user.id;
            return this.api.post(this.endpointSingle + '/add', user, { headers: { Authorization: this.user.token } });
        } else {
            return this.api.put(this.endpointSingle + '/edit/' + user.id, user, { headers: { Authorization: this.user.token } });
        }
    }

    public roles() {
        return this.api.get(this.endpointRol
            + '/rolesByUser/'
            + this.user.user.role.id, {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public listUsers() {
        return this.api.get(this.endpointMulti + '/listUsers/', {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public listManagers() {
        return this.api.get(this.endpointMulti + '/listManagers', {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public ambassadors() {
        return this.api.get('ambassadors/' + this.user.user.id, {}, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public sendInvitation(userId: number) {
        const url = this.endpointSingle + '/sendInvitation/' + userId;
        return this.api.get(url, {}, { headers: { Authorization: this.user.token } });
    }

    public getCountriesByUser() {
        return this.api.get(this.endpointMulti
            + '/allCountriesByUser/'
            + this.user.user.id, {}, { headers: { Authorization: this.user.token } });
    }

    public getActivityCategoryByUser() {
        return this.api.get('activitycategoryregion'
            + '/activityCategoryByUser/'
            + this.user.user.id, {},
            { headers: { Authorization: this.user.token } });
    }

    public approveUserRequest(user) {
        const url = `${URL_USER_APPROVE}`;
        return this.api.post(url, { email: user.email, firstName: user.firstName, lastName: user.lastName, uid: user.uid });
    }

    public denyUserRequest(user) {
        const url = `${URL_USER_DENY}`;
        return this.api.post(url, { email: user.email, firstName: user.firstName, lastName: user.lastName, uid: user.uid }).pipe(
            map((res: any) => res),
            catchError(error => throwError(error))
        );
    }

    public logEvent(type, uid, table_relationship) {
        const date_time = Date.now();
        const evento = new EventModel(type, uid, table_relationship, date_time);
        return this.api.post('event/save', evento);
    }
}
