import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivityTypesService } from 'src/app/providers/activity-types.service';
import { ActivityTypeModel } from 'src/app/models/activity-type.model';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide : NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ActivityTypeSelectComponent),
    multi : true,
};
@Component({
    selector: 'app-activity-type-select',
    templateUrl: './activity-type-select.component.html',
    providers : [CUSTOM_VALUE_ACCESSOR]
})
export class ActivityTypeSelectComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    public disabled = false;
    public value: string = null;
    public activityTypes: ActivityTypeModel[];
    private onChange: Function = (_: any) => {};
    private onTouched: Function = () => {};

    constructor(private activityTypeService: ActivityTypesService) {}

    ngOnInit() {
        this.activityTypeService.search().subscribe(data => {
            this.activityTypes = data;
            this.activityTypes.unshift(new ActivityTypeModel({'id': null, 'name': ''}));
        });
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    activityTypeChange(ev)
    {
        this.value = ev.target.value;
        this.onChange(this.value);
        this.onTouched();
    }
}
