import { BrandModel } from './brand.model';
import { RegionModel } from './region.model';
import * as moment from 'moment';
import { GlobalModel } from './global.model';

export class ProgramModel {
    public brandRegion: Partial<BrandModel>;
    public createDate: Date;
    public createdBy: string;
    public deleted: boolean;
    public globalProgram: GlobalModel;
    public id: number;
    public name: string;
    public progFrom: any;
    public progTo: any;
    public region: any;
    public updateDate: Date;
    public updatedBy: string;

    // Properties maintained for compatibility
    public programGlobal: any;
    public global: any;

    // Fn maintained for compatibility
    public fromJson(jsonData: any) {
        this.id = jsonData.ID ? Number.parseInt(jsonData.ID, 10) : null;
        this.name = jsonData.name;
        this.brandRegion = new BrandModel({ ID: jsonData.brand_id });
        this.progFrom = moment(jsonData.progFrom, 'YYYY-MM-DD');
        this.progTo = moment(jsonData.progTo, 'YYYY-MM-DD');
        this.deleted = (jsonData.deleted === '1');
    }

    constructor(data: any) {
        this.brandRegion = new BrandModel(data.brandRegion || null);
        this.createDate = data.createDate || new Date();
        this.createdBy = data.createdBy || 'userUid';
        this.deleted = data.deleted || false;
        this.globalProgram = data.globalProgram;
        if (data.id) { this.id = data.id; }
        this.name = data.name;
        this.progFrom = moment(data.progFrom).format();
        this.progTo = moment(data.progTo).format();
        // this.region = data.region;
        this.region = data.region || new RegionModel({});
        this.updateDate = data.updateDate;
        this.updatedBy = data.updatedBy || 'userUid';
    }

    clone(): ProgramModel {
        const c = new ProgramModel({});
        return Object.assign(c, this);
    }
}
