export class OutletModel {
    public id: number;
    public bacId?: number;
    public name?: string;
    public address?: string;
    public zip?: string;
    public city?: string;
    public phone?: string;
    public country?: { id: number};
    public winningIn?: { id: number};
    public posSegment?: { id: number};
    public activitySegment?: { id: number};
    public manager?: { id: number};
    public createdOn?: string;
    public created_by?: number;
    public deleted?: boolean;
    public imported?: boolean;
    public isSfa?: boolean;

    constructor(outletObjc: Partial<OutletModel> ) {
        this.id = outletObjc.id;
        this.bacId = outletObjc.bacId;
        this.name = outletObjc.name;
        this.address = outletObjc.address;
        this.country = outletObjc.country;
        this.zip = outletObjc.zip;
        this.city = outletObjc.city;
        this.phone = outletObjc.phone;
        this.winningIn = outletObjc.winningIn;
        this.posSegment = outletObjc.posSegment;
        this.activitySegment = outletObjc.activitySegment;
        this.manager = outletObjc.manager;
        this.createdOn = outletObjc.createdOn;
        this.created_by = outletObjc.created_by;
        this.deleted = outletObjc.deleted;
        this.imported = outletObjc.imported;
        this.isSfa = outletObjc.isSfa;
    }
}
