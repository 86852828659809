export class ActivityTypeModel {
    public id: number;
    public activityTypeGlobal: any;
    public name: string;
    public region: any;

    constructor(activityType: Partial<ActivityTypeModel>) {
        this.id = activityType.id;
        this.activityTypeGlobal = activityType.activityTypeGlobal;
        this.name = activityType.name;
        this.region = activityType.region;
    }

    clone(): ActivityTypeModel
    {
        const c = new ActivityTypeModel({});
        return Object.assign(c, this);
    }
}
