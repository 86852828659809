import { AfterViewInit, Component, ContentChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttendeeTypeModel } from 'src/app/models/attendee-type.model';
import { AttendeesTypesService } from 'src/app/providers/attendees-types.service';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { GlobalMessage } from 'src/app/providers/global-message.service';

@Component({
    selector: 'app-attendees-types',
    templateUrl: './attendees-type-list.page.html'
})
export class AttendeesTypeListPage extends DatatablePage implements AfterViewInit
{
    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    public attendeesTypes: AttendeeTypeModel[];
    public attendeeType: AttendeeTypeModel;
    public editingId: number;
    private editingValue: string;

    constructor(
        private attendeesTypeService: AttendeesTypesService,
        private modalService: NgbModal,
        private messageHandler: GlobalMessage
    )
    {
        super();
    }

    ngAfterViewInit() {
        this.refreshList();
        this.refreshDt();
    }

    refreshList() {
        this.attendeesTypeService.search().subscribe(data => {
            this.attendeesTypes = data;
            this.reRenderDt();
        });
    }

    delete(content, attendeesTypeId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result)
            {
                this.attendeesTypeService.delete(this.attendeesTypes.find( a => a.id === attendeesTypeId)).subscribe(
                    x => {
                        this.refreshList();
                    }
                );
            }

        }, (reason) => {
        });
    }

    startEdit(attendeeType: AttendeeTypeModel)
    {
        this.editingId = attendeeType.id;
        this.attendeeType = attendeeType;
        // this.editingValue = this.attendeesTypes.find(h => h.id === attendeesTypeId).name;
    }

    validateEdit()
    {
        /*this.attendeesTypeService.createOrEdit(new AttendeeTypeModel({
            ID: this.editingId,
            name: this.editingValue
        }))*/
        this.attendeesTypeService.createOrEdit(this.attendeeType)
        .subscribe((r: Response) => {
            if (r === null)
            {
                this.editingId = 0;
                this.editingValue = '';
                this.refreshList();
                this.reRenderDt();
                this.messageHandler.showSuccess('The attendee type has been saved successfully', 5000);
            }
            else
            {
                this.messageHandler.showError('error');
            }
        });
    }
}
