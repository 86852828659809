import { OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

export abstract class DatatablePage implements OnInit, OnDestroy
{
    @ViewChild(DataTableDirective, null) dtElement: DataTableDirective;
    public dtTrigger: Subject<void> = new Subject();
    public dtOptions: DataTables.Settings = {};

    constructor() {}

    ngOnInit(): void {
        this.dtOptions = {
            responsive: true,
            pageLength: 50,
            deferRender: true,
            processing: true,
            searchDelay: 1000,
            language: {
                searchPlaceholder: 'Search',
                search: '_INPUT_'
            }
        };
    }

    ngOnDestroy(): void
    {
        this.dtTrigger.unsubscribe();
    }

    refreshDt(): void
    {
        this.dtTrigger.next();
    }

    reRenderDt(): void
    {
        if (this.dtElement && this.dtElement.dtInstance)
        {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
                this.dtTrigger.next();
            });
        }
    }

    setDtOption(key, value)
    {
        this.dtOptions[key] = value;
    }
}
