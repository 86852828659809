import { AfterViewInit, Component, OnInit } from '@angular/core';
import { POSSegmentModel } from 'src/app/models/pos-segment.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { OutletPosSegmentsService } from 'src/app/providers/outlet-pos-segments.service';
import { UserService } from 'src/app/providers/user.service';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { GlobalModel } from 'src/app/models/global.model';

@Component({
    selector: 'app-outlet-pos-segment-list',
    templateUrl: './outlet-pos-segment-list.page.html'
})
export class OutletPosSegmentListPage extends DatatablePage implements AfterViewInit, OnInit {
    public posSegments: POSSegmentModel[];
    public posSegment: POSSegmentModel;
    public countryId: number;
    public editingId: number;

    public posSegmentForm = this.fb.group({
        posSegmentId: [''],
        name: ['', Validators.required],
        posSegment: ['', Validators.required]
    });

    constructor(
        private fb: FormBuilder,
        public outletPosSegmentsService: OutletPosSegmentsService,
        private userService: UserService,
        private messageHandler: GlobalMessage
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.countryId = Number(sessionStorage.getItem('segmentByCountry'))
            ? Number(sessionStorage.getItem('segmentByCountry'))
            : this.userService.user.defaultCountryId;
    }

    ngAfterViewInit() {
        this.refreshList();
        this.refreshDt();
    }

    refreshList() {
        if (this.countryId) {
            sessionStorage.setItem('segmentByCountry', JSON.stringify(this.countryId));
        } else {
            sessionStorage.removeItem('segmentByCountry');
        }

        const size = 999999;
        this.outletPosSegmentsService.list(this.countryId, size).subscribe(data => {
            this.posSegments = data;
            this.reRenderDt();
        });
    }

    startEdit(posSegment: POSSegmentModel) {
        this.posSegment = posSegment;
        this.editingId = posSegment.id;

        this.posSegmentForm.patchValue({
            name: posSegment.name,
            posSegment: new GlobalModel(posSegment.posSegment)
        });
    }

    globalChange(posSegmentId: number) {
        this.posSegmentForm.patchValue({
            posSegment: new GlobalModel({ id: posSegmentId })
        });
    }

    cancelEdit() {
        this.editingId = null;

        this.posSegmentForm.patchValue({
            name: '',
            posSegment: null
        });
    }

    validateEdit() {
        if (this.posSegmentForm.valid) {
            const posSegment = this.posSegmentForm.value;
            posSegment.posSegment.id = this.posSegmentForm.value.posSegment.id;
            posSegment.id = this.editingId;
            posSegment.country = { id: this.countryId };
            this.outletPosSegmentsService.update(new POSSegmentModel(posSegment)).subscribe(
                () => {
                    this.cancelEdit();
                    this.refreshList();
                    this.messageHandler.showSuccess('The POS segment has been saved successfully', 5000);
                }, (e: Error) => {
                    this.messageHandler.showError(e.message);
                }
            );
        } else {
            this.validateAllFormFields(this.posSegmentForm);
        }
    }

    private validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    public isFieldValid(field: string) {
        return !this.posSegmentForm.get(field).valid && this.posSegmentForm.get(field).touched;
    }

    public displayFieldCss(field: string) {
        return {
            'has-error': this.isFieldValid(field),
            'has-feedback': this.isFieldValid(field)
        };
    }
}
