import { GlobalModel } from './global.model';
import { RegionModel } from './region.model';

export class InfluencerLevelModel {
    public id: number;
    public name?: string;
    public deleted?: boolean;
    public region?: RegionModel;
    public influencerLevelsGlobal?: GlobalModel;
    public createDate?: Date;
    public createdBy?: string;


    constructor(influencerObjec: Partial<InfluencerLevelModel>) {
        this.id = influencerObjec.id;
        this.name = influencerObjec.name;
        this.deleted = influencerObjec.deleted;
        this.region = influencerObjec.region  || new RegionModel({});
        this.influencerLevelsGlobal = influencerObjec.influencerLevelsGlobal;
        this.createDate = influencerObjec.createDate || new Date();
        this.createdBy = influencerObjec.createdBy || 'c786968532d8481482ac60bb9190ab73';
    }
}
