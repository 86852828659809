import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


// Anyadimos el script de Gigya de forma dinamica
document.write(
  `<script type='text/javascript' lang='javascript' src='https://cdns.gigya.com/js/gigya.js?apikey=`
  + environment.gigya_apiKey
  + `'></script>`
);

if (environment.production) {
  enableProdMode();
}

// add GTM script to <head>
if (environment.gtm) {
  const script = document.createElement('script');
  script.innerHTML = environment.gtm;
  document.head.prepend(script);
}

// add GTM noscript to <body>
if (environment.gtmNoscript) {
  const noscript = document.createElement('noscript');
  noscript.innerHTML = environment.gtmNoscript;
  document.body.prepend(noscript);
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
