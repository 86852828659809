import { BrandModel } from './brand.model';
import { BrandCategoryModel } from './brand-category.model';
import { ProgramModel } from './program.model';

export class BrandProgramModel {
    public id: number;
    public brand: BrandModel;
    public category: BrandCategoryModel;
    public program: ProgramModel;
    public brandProgramNumberOfSites: number;
    public brandProgramNumberOfServes: number;
    public brandProgramSalesVolume: number;
    public brandProgramBottlesMerchandised: number;
    public brandProgramDisplaysBuilt: number;
    public brandProgramIncreasedVisibility: number;

    public fromJson(jsonData: any)
    {
        this.id = jsonData.ID ? Number.parseInt(jsonData.ID, 10) : null;
        this.brand = jsonData.brand;
    }

    public fromItems(
        brand: BrandModel,
        category: BrandCategoryModel,
        program: ProgramModel,
        brandProgramNumberOfSites?: number,
        brandProgramNumberOfServes?: number,
        brandProgramSalesVolume?: number,
        brandProgramBottlesMerchandised?: number,
        brandProgramDisplaysBuilt?: number,
        brandProgramIncreasedVisibility?: number,
    ): BrandProgramModel
    {
        this.brand = brand;
        this.category = category;
        this.program = program;
        this.brandProgramNumberOfSites = brandProgramNumberOfSites;
        this.brandProgramNumberOfServes = brandProgramNumberOfServes;
        this.brandProgramSalesVolume = brandProgramSalesVolume;
        this.brandProgramBottlesMerchandised = brandProgramBottlesMerchandised;
        this.brandProgramDisplaysBuilt = brandProgramDisplaysBuilt;
        this.brandProgramIncreasedVisibility = brandProgramIncreasedVisibility;
        return this;
    }

    constructor(jsonData: any) {
        this.fromJson(jsonData);
    }
}
