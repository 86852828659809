import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { BrandModel } from 'src/app/models/brand.model';
import { ProgramModel } from 'src/app/models/program.model';
import { FormPage } from 'src/app/pages/FormPage';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { ProgramsService } from 'src/app/providers/programs.service';
import * as moment from 'moment';

@Component({
    selector: 'app-program-edit.page',
    templateUrl: './program-edit.page.html'
})
export class ProgramEditPage extends FormPage implements OnInit {
    @ViewChild('datePickerFrom', null) datePickerFrom: NgbDatepicker;
    @ViewChild('datePickerTo', null) datePickerTo: NgbDatepicker;

    public programForm = this.fb.group({
        programId: [''],
        name: ['', Validators.required],
        brandId: ['', Validators.required],
        progFrom: ['', Validators.required],
        progTo: ['', Validators.required],
        global: [''],
    });

    public brands: BrandModel[] = null;
    public program: ProgramModel = null;

    public programId: number = null;

    constructor(
        private fb: FormBuilder,
        public programService: ProgramsService,
        private route: ActivatedRoute,
        private router: Router,
        private errorHandler: GlobalMessage
    ) {
        super();
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null) {
                this.programId = +params.get('id');

                this.programService.info(this.programId).subscribe(
                    (data: ProgramModel) => {
                        this.program = data;

                        const dates = [];
                        [data.progFrom, data.progTo].forEach(v => {
                            dates.push({
                                year: moment(v).year(),
                                month: moment(v).month() + 1,
                                day: moment(v).date() // day() devuelve el DIA DE LA SEMANA (1-7), date() devuelve el DIA DEL MES
                            }
                            );
                        });

                        this.programForm.patchValue({
                            programId: data.id,
                            name: data.name,
                            progFrom: dates[0],
                            progTo: dates[1],
                            brandId: data.brandRegion && data.brandRegion.id || null,
                            global: data.programGlobal || null,
                        });
                        this.datePickerFrom.navigateTo(dates[0]);
                        this.datePickerTo.navigateTo(dates[1]);
                    }
                );
            }
        });
    }

    onSubmit() {
        if (this.programForm.valid) {
            const program: any = this.program || {};
            program.name = this.programForm.value.name;
            program.progFrom = this.programForm.value.progFrom;
            program.progTo = this.programForm.value.progTo;

            // Restamos uno al mes ya que Enero = 0
            program.progFrom.month--;
            program.progTo.month--;

            if (this.programForm.controls['brandId'].touched) {
                program.brandRegion = new BrandModel(this.programForm.value.brandId);
            }
            if (this.programForm.controls['global'].touched) {
                program.globalProgram = this.programForm.value.global;
            }

            this.programService.createOrEdit(new ProgramModel(program))
                .subscribe((data: any) => {
                    if (data) {
                        this.router.navigate(['/workspace/programs']);
                        this.errorHandler.showSuccess('Your program has been saved successfully', 5000);
                    } else {
                        this.errorHandler.showError(data.error.message);
                    }
                });
        } else {
            this.validateAllFormFields(this.programForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.programForm;
    }
}
