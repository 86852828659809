import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { WinningInModel } from '../models/winning-in.model';


@Injectable({
    providedIn: 'root'
})
export class OutletWinningInApiService {
    private endpointSingle = 'winningin';

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public search(countryId: number) {
        return this.api.post(this.endpointSingle
            + `/search?size=99999&page=undefined&sort=city,ASC&sort=id,ASC`,
            { country: countryId },
            { headers: { Authorization: this.user.token } });
    }

    public createOrEdit(winningIn: WinningInModel) {
        if (!winningIn.id) {
            delete winningIn.id;
        }
        return this.api.post(this.endpointSingle + '/save', winningIn, { headers: { Authorization: this.user.token } });
    }

    public info(winningInId: number) {
        const params = {};

        return this.api.get(this.endpointSingle + '/detail/' + winningInId.toString(),
            params, { headers: { Authorization: this.user.token } })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public getWinningIns(countryId: number, size?: number, page?: number) {
        const params = { size: size, page: page };
        return this.api.post(this.endpointSingle + '/search',
            { country: countryId }, { headers: { Authorization: this.user.token }, params: params })
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }
}
