import { Injectable, Inject } from '@angular/core';
import { UserApiService } from './user-api.service';
import { UserModel } from './../models/user.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { StorageService, LOCAL_STORAGE, SESSION_STORAGE } from 'angular-webstorage-service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: UserModel = new UserModel({});
    private isLoginSubject = new BehaviorSubject<boolean>(this.hasUser());

    constructor(
        private router: Router,
        private userApiService: UserApiService,
        @Inject(LOCAL_STORAGE) private localStorage: StorageService,
        @Inject(SESSION_STORAGE) private sessionStorage: StorageService
    ) {}

    private hasUser(): boolean {
        const user = this.localStorage.get('user') !== null ?
            this.localStorage.get('user')
            : this.sessionStorage.get('user');
        if (user) {
            this.sessionStorage.set('user', user);
            this._user = UserModel.fromObject(user);
            return true;
        }
        return false;
    }

    public isLogged(): Observable<boolean> {
        return this.isLoginSubject.asObservable();
    }

    public get token(): string {
        if (this._user && this._user.token) {
            return this._user.token;
        } else {
            return '';
        }
    }

    public clearUser() {
        this.localStorage.remove('user');
        this.sessionStorage.remove('user');
        this.isLoginSubject.next(false);
    }

    public set user(user: UserModel) {
        this._user = user;
        if (Boolean(this.sessionStorage.get('remember')) === true) {
            this.localStorage.set('user', this._user);
        }
        this.sessionStorage.set('user', this._user);
        this.isLoginSubject.next(true);
    }

    public get user() {
        return this._user;
    }
}
