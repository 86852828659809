import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrandCategoryModel } from '../models/brand-category.model';
import { BrandCategoriesApiService } from './brand-categories-api.service';

@Injectable({
    providedIn: 'root'
})
export class BrandCategoriesService {
    constructor(
        private brandCategoriesApi: BrandCategoriesApiService
    ) { }

    public list(countryId: number = 0) {
        const brandCategories: BrandCategoryModel[] = [];
        return this.brandCategoriesApi.list().pipe(
            map(l => {
                if (l) {
                    l.forEach(element => {
                        brandCategories.push(new BrandCategoryModel(element));
                    });
                }
                return brandCategories;
            })
        );
    }

    public info(brandCategoryId: number): Observable<BrandCategoryModel> {
        return this.brandCategoriesApi.info(brandCategoryId).pipe(
            map(res => new BrandCategoryModel(res))
        );
    }

    public delete(brandCategory: BrandCategoryModel) {
        return this.brandCategoriesApi.delete(brandCategory.id);
    }

    public createOrEdit(brandCategory: BrandCategoryModel) {
        return this.brandCategoriesApi.createOrEdit(brandCategory);
    }

    public getByCountry(countryId: number): Observable<BrandCategoryModel[]> {
        return this.brandCategoriesApi.getByCountry(countryId);
    }
}
