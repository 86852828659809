export class RegionModel {
    public id?: number;
    public code?: string;
    public name?: string;
    public createDate?: Date;
    public createdBy?: string;

    constructor(region?: Partial<RegionModel>) {
        this.id = region.id;
        this.code = region.code;
        this.name = region.name;
        this.createDate = region.createDate || new Date();
        this.createdBy = region.createdBy || '';
    }
}
