import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LanguagesApiService } from './languages-api.service';

@Injectable({
    providedIn: 'root'
})
export class LanguagesService {
    constructor(
        private languagesApi: LanguagesApiService
    ) { }

    public list() {
        return this.languagesApi.list().pipe(
            map(languages => {
                return languages;
            })
        );
    }
}
