import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AttendeesTypesService } from 'src/app/providers/attendees-types.service';
import { FormPage } from 'src/app/pages/FormPage';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { UserService } from 'src/app/providers/user.service';

@Component({
    selector: 'app-attendees-type-edit.page',
    templateUrl: './attendees-type-edit.page.html'
})
export class AttendeesTypeEditPage extends FormPage implements OnInit
{
    public attendeesTypeForm = this.fb.group({
        attendeesTypeId: [''],
        name: ['', Validators.required],
        attendeeTypeGlobal: ['', Validators.required],
    });

    public attendeesTypeId: number = null;

    constructor(
        private fb: FormBuilder,
        public attendeesTypeService: AttendeesTypesService,
        private route: ActivatedRoute,
        private errorHandler: GlobalMessage,
        private userService: UserService,
    )
    {
        super();
    }

    ngOnInit()
    {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null)
            {
                this.attendeesTypeId = Number.parseInt(params.get('id'), 10);
                this.attendeesTypeService.info(Number.parseInt(params.get('id'), 10)).subscribe(
                    (data) => {
                        this.attendeesTypeForm.setValue({
                            attendeesTypeId: data.id,
                            name: data.name,
                            attendeeTypeGlobal: data.attendeeTypeGlobal ? data.attendeeTypeGlobal.id : null,
                        });
                    }
                );
            }
        });
    }

    onSubmit() {
        if (this.attendeesTypeForm.valid) {
            const attendeesType = this.attendeesTypeForm.value;

            delete attendeesType.attendeesTypeId;

            attendeesType.region = this.userService.user.defaultRegion;

            this.attendeesTypeService.createOrEdit(attendeesType).subscribe(
                () => {
                    this.errorHandler.showSuccess('Your activity type has been saved successfully', 5000);
                }
            );
        } else {
            this.validateAllFormFields(this.attendeesTypeForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.attendeesTypeForm;
    }
}
