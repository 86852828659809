import { Component, OnInit } from '@angular/core';
import { StatisticsFilterModel } from 'src/app/models/statistics-filter.model';

@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.page.html'
})
export class StatisticsPage implements OnInit {
    public filter: StatisticsFilterModel;

    constructor() { }

    ngOnInit() {

    }
}
