import { Component, ContentChild, TemplateRef, Input, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkHistoryService } from 'src/app/providers/work-history.service';
import { Subject } from 'rxjs';
import { WorkHistoryModel } from 'src/app/models/work-history.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';

@Component({
    selector: 'app-work-history',
    templateUrl: './work-history.component.html'
})
export class WorkHistoryComponent extends DatatablePage implements AfterViewInit
{
    private _userId: number = null;
    @Input() set userId(v) {
        this._userId = v;
        this.refreshList();
    }
    get userId()
    {
        return this._userId;
    }

    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    public dtTrigger: Subject<void> = new Subject();
    public dtOptions: any = {};
    public workHistories: WorkHistoryModel[];

    constructor(
        private workHistoryService: WorkHistoryService,
        private modalService: NgbModal
    )
    {
        super();
    }

    ngAfterViewInit() {
        this.refreshList();
        this.refreshDt();
    }

    refreshList() {
        if (this.userId)
        {
            this.workHistoryService.list(this.userId).subscribe(data => {
                this.workHistories = data.filter(value =>  {
                    return !value.deleted;
                });
                this.reRenderDt();
            });
        }
    }

    delete(content, workHistoryId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result)
            {
                this.workHistoryService.delete(this.workHistories.find( a => a.id === workHistoryId)).subscribe(
                    x => {
                        this.refreshList();
                    }
                );
            }

        }, (reason) => {
        });
    }

}
