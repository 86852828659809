import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SPINNER } from 'ngx-ui-loader';
import { UserService } from './providers/user.service';
import { version } from 'package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    public SPINNER = SPINNER;
    version = version;
    constructor(
        public activatedRoute: ActivatedRoute,
        public router: Router,
        public userService: UserService
    ) {}

    ngOnInit() { }
}
