import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StorageServiceModule } from 'angular-webstorage-service';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './/app-routing.module';
import { AppComponent } from './app.component';
import { ContactModalComponent } from './components/contact-modal/contact-modal.component';
import { ModalComponent } from './components/modal/modal.component';
import { OutletModalComponent } from './components/outlet-modal/outlet-modal.component';
import { ApiService } from './providers/api.service';
import { GlobalMessage } from './providers/global-message.service';
import { TokenInterceptor } from './token.interceptor';
import { CommonModule } from '@angular/common';
import { WorkspaceModule } from './pages/workspace/workspace.module';
import { LoginModule } from './pages/login/login.page.module';
import { MessageDisplayComponent } from './components/message-display/message-display.component';
import { OutletFormModule } from './components/outlet-form/outlet-form.module';

@NgModule({
    declarations: [
        AppComponent,
        MessageDisplayComponent,
        // NotFoundPage,
        ModalComponent,
        OutletModalComponent,
        ContactModalComponent,
    ],
    imports: [
        NgbModule,
        NgbModalModule,
        BrowserModule,
        CommonModule,
        StorageServiceModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        NgxUiLoaderModule,
        LoginModule,
        WorkspaceModule,
        OutletFormModule
    ],
    providers: [
        ApiService,
        GlobalMessage,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    entryComponents: [
        ModalComponent,
        OutletModalComponent,
        ContactModalComponent,
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    static injector: Injector;

    constructor(private injector: Injector) {
        AppModule.injector = injector;
    }
}
