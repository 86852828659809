import { Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { NotFoundPage } from '../not-found/not-found.page';
import { ActivityTypeEditPage } from './admin/activity-types/edit/activity-type-edit.page';
import { ActivityTypeListPage } from './admin/activity-types/list/activity-type-list.page';
import { AttendeesTypeEditPage } from './admin/attendees-type/edit/attendees-type-edit.page';
import { AttendeesTypeListPage } from './admin/attendees-type/list/attendees-type-list.page';
import { BrandCategoryEditPage } from './admin/brand-categories/edit/brand-category-edit.page';
import { BrandCategoryListPage } from './admin/brand-categories/list/brand-category-list.page';
import { BrandEditPage } from './admin/brands/edit/brand-edit.page';
import { BrandListPage } from './admin/brands/list/brand-list.page';
import { ContactSocialEditPage } from './admin/contact-socials/edit/contact-socials-edit.page';
import { ContactTitleEditPage } from './admin/contact-titles/edit/contact-titles-edit.page';
import { ContactTitleListPage } from './admin/contact-titles/list/contact-titles-list.page';
import { ContactEditPage } from './admin/contacts/edit/contact-edit.page';
import { ContactListPage } from './admin/contacts/list/contact-list.page';
import { ContactViewPage } from './admin/contacts/view/contact-view.page';
import { CountryEditPage } from './admin/country/edit/country-edit.page';
import { CountryListPage } from './admin/country/list/country-list.page';
import { HobbyEditPage } from './admin/hobbies/edit/hobby-edit.page';
import { HobbyListPage } from './admin/hobbies/list/hobby-list.page';
import { InfluencerLevelEditPage } from './admin/influencer-levels/edit/influencer-level-edit.page';
import { InfluencerLevelListPage } from './admin/influencer-levels/list/influencer-level-list.page';
import { OutletActivitySegmentEditPage } from './admin/outlet-activity-segments/edit/outlet-activity-segment-edit.page';
import { OutletActivitySegmentListPage } from './admin/outlet-activity-segments/list/outlet-activity-segment-list.page';
import { OutletPosSegmentEditPage } from './admin/outlet-pos-segments/edit/outlet-pos-segment-edit.page';
import { OutletPosSegmentListPage } from './admin/outlet-pos-segments/list/outlet-pos-segment-list.page';
import { OutletWinningInEditPage } from './admin/outlet-winning-in/edit/outlet-winning-in-edit.page';
import { OutletWinningInListPage } from './admin/outlet-winning-in/list/outlet-winning-in-list.page';
import { OutletEditPage } from './admin/outlets/edit/outlet-edit.page';
import { OutletListPage } from './admin/outlets/list/outlet-list.page';
import { ProgramEditPage } from './admin/programs/edit/program-edit.page';
import { ProgramListPage } from './admin/programs/list/program-list.page';
import { TargetEditPage } from './admin/targets/edit/target-edit.page';
import { TargetListPage } from './admin/targets/list/target-list.page';
import { UserEditPage } from './admin/users/edit/user-edit.page';
import { UserListPage } from './admin/users/list/user-list.page';
import { WorkHistoryEditPage } from './admin/work-history/edit/work-history-edit.page';
import { ActivityEditPage } from './dashboard/activity/edit/activity-edit.page';
import { DashboardPage } from './dashboard/dashboard.page';
import { SettingsPage } from './settings/settings.page';
import { StatisticsPage } from './statistics/statistics.page';
import { TargetListStaticPage } from './target-list-static/target-list-static.page';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        component: DashboardPage,
        canActivate: [AuthGuard],
        data: { ambassadorAccessGranted: true, managerAccessGranted: true }
    },
    {
        path: 'statistics',
        component: StatisticsPage,
        canActivate: [AuthGuard],
        data: { ambassadorAccessGranted: true, managerAccessGranted: true }
    },
    { path: 'settings', component: SettingsPage, canActivate: [AuthGuard] },
    {
        path: 'outlets',
        component: OutletListPage,
        canActivate: [AuthGuard],
        data: { ambassadorAccessGranted: true, managerAccessGranted: true }
    },
    {
        path: 'outlet/:id/edit',
        component: OutletEditPage,
        canActivate: [AuthGuard],
        data: { ambassadorAccessGranted: true, managerAccessGranted: true }
    },
    {
        path: 'outlet/add',
        component: OutletEditPage,
        canActivate: [AuthGuard],
        data: { ambassadorAccessGranted: true, managerAccessGranted: true }
    },
    { path: 'countries', component: CountryListPage, canActivate: [AuthGuard] },
    {
        path: 'country/:id/edit',
        component: CountryEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'country/add',
        component: CountryEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'brand-categories',
        component: BrandCategoryListPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'brand-category/:id/edit',
        component: BrandCategoryEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'brand-category/add',
        component: BrandCategoryEditPage,
        canActivate: [AuthGuard]
    },
    { path: 'programs', component: ProgramListPage, canActivate: [AuthGuard] },
    {
        path: 'program/:id/edit',
        component: ProgramEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'program/add',
        component: ProgramEditPage,
        canActivate: [AuthGuard]
    },
    { path: 'brands', component: BrandListPage, canActivate: [AuthGuard] },
    {
        path: 'brand/:id/edit',
        component: BrandEditPage,
        canActivate: [AuthGuard]
    },
    { path: 'brand/add', component: BrandEditPage, canActivate: [AuthGuard] },
    {
        path: 'activity-types',
        component: ActivityTypeListPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'activity-type/:id/edit',
        component: ActivityTypeEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'activity-type/add',
        component: ActivityTypeEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'users',
        component: UserListPage,
        canActivate: [AuthGuard],
        data: { ambassadorAccessGranted: false, managerAccessGranted: true }
    },
    {
        path: 'user/:id/edit',
        component: UserEditPage,
        canActivate: [AuthGuard],
        data: { ambassadorAccessGranted: true, managerAccessGranted: true }
    },
    {
        path: 'user/add',
        component: UserEditPage,
        canActivate: [AuthGuard],
        data: { ambassadorAccessGranted: false, managerAccessGranted: true }
    },
    { path: 'contacts', component: ContactListPage, canActivate: [AuthGuard] },
    {
        path: 'contact/add',
        component: ContactEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'contact/:id',
        component: ContactViewPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'contact/:id/edit',
        component: ContactEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'attendees-types',
        component: AttendeesTypeListPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'attendees-type/:id/edit',
        component: AttendeesTypeEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'attendees-type/add',
        component: AttendeesTypeEditPage,
        canActivate: [AuthGuard]
    },
    { path: 'targets', component: TargetListPage, canActivate: [AuthGuard] },
    {
        path: 'my-targets',
        component: TargetListStaticPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'target/:id/edit',
        component: TargetEditPage,
        canActivate: [AuthGuard]
    },
    { path: 'target/add', component: TargetEditPage, canActivate: [AuthGuard] },
    {
        path: 'contact-titles',
        component: ContactTitleListPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'contact-title/:id/edit',
        component: ContactTitleEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'contact-title/add',
        component: ContactTitleEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'contact-social/:userid/:id/edit',
        component: ContactSocialEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'contact-social/:userid/add',
        component: ContactSocialEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'work-history/:userid/:id/edit',
        component: WorkHistoryEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'work-history/:userid/add',
        component: WorkHistoryEditPage,
        canActivate: [AuthGuard]
    },
    { path: 'hobbies', component: HobbyListPage, canActivate: [AuthGuard] },
    {
        path: 'hobby/:id/edit',
        component: HobbyEditPage,
        canActivate: [AuthGuard]
    },
    { path: 'hobby/add', component: HobbyEditPage, canActivate: [AuthGuard] },
    {
        path: 'influencer-levels',
        component: InfluencerLevelListPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'influencer-level/:id/edit',
        component: InfluencerLevelEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'influencer-level/add',
        component: InfluencerLevelEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'outlet-activity-segments',
        component: OutletActivitySegmentListPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'outlet-activity-segment/:id/edit',
        component: OutletActivitySegmentEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'outlet-pos-segments',
        component: OutletPosSegmentListPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'outlet-pos-segment/:id/edit',
        component: OutletPosSegmentEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'outlet-winning-ins',
        component: OutletWinningInListPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'outlet-winning-in/:id/edit',
        component: OutletWinningInEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'outlet-winning-in/add',
        component: OutletWinningInEditPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'activity/:id/edit',
        component: ActivityEditPage,
        canActivate: [AuthGuard],
        data: { ambassadorAccessGranted: true, managerAccessGranted: true }
    },
    {
        path: 'activity/add/:idUser',
        component: ActivityEditPage,
        canActivate: [AuthGuard],
        data: { ambassadorAccessGranted: true, managerAccessGranted: true }
    },
    { path: '**', component: NotFoundPage }
];
