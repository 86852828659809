import { AttendeeContactModel } from './attendee-contact.model';
import { OutletModel } from './outlet.model';
import * as moment from 'moment';

export class WorkHistoryModel {
    public id: number;
    public outlet: OutletModel;
    public contact: AttendeeContactModel;
    public from: moment.Moment;
    public until: moment.Moment;
    public deleted: boolean;

    public fromJson(jsonData)
    {
        this.id = jsonData.ID ? Number.parseInt(jsonData.ID, 10) : null;
        this.from = moment(jsonData.work_from, 'YYYY-MM-DD');
        this.until = moment(jsonData.work_until, 'YYYY-MM-DD');
        this.deleted = jsonData.deleted === '1';

        if (this.contact !== undefined)
        {
            this.contact.fromJson({ID: jsonData.person_id});
        }
        else
        {
            this.contact = new AttendeeContactModel({ID: jsonData.person_id});
        }

        // if (this.outlet !== undefined)
        // {
        //     this.outlet.fromJson({ID: jsonData.outlet_id, name: jsonData.outlet_name});
        // }
        // else
        // {
        //     this.outlet = new OutletModel({ID: jsonData.outlet_id, name: jsonData.outlet_name});
        // }
    }

    constructor(jsonData) {
        this.fromJson(jsonData);
    }
}
