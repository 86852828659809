import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/providers/user.service';
import { UserModel } from '../../models/user.model';
import { Subject } from 'rxjs/internal/Subject';
import { UsersService } from '../../providers/users.service';
import { environment } from '../../../environments/environment';
import { GlobalMessage } from '../../providers/global-message.service';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../providers/api.service';
declare const gigya: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit, OnDestroy {
    destroySubject$: Subject<void> = new Subject();
    loginForm: FormGroup;
    jwt: any;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private usersService: UsersService,
        private router: Router,
        private ngZone: NgZone,
        private errorHandler: GlobalMessage,
        private api: ApiService
    ) { }

    ngOnInit() {
        // Recuperamos el UID y JWT del sistema de almacenamiento
        const storedUID =
            localStorage.getItem('uid') != null
                ? localStorage.getItem('uid')
                : sessionStorage.getItem('uid');
        const storedJWT =
            localStorage.getItem('jwt') != null
                ? localStorage.getItem('jwt')
                : sessionStorage.getItem('jwt');

        this.createLoginForm();

        // Comprobar con acceso a WS permisos del usuario y validez del token
        // En el caso de ser válido, se accede a workspace, si no lo es, pantalla de login
        // Por ahora solo se comprueba si estan los atributos uid y jwt en el localstore
        if (storedUID != null && storedJWT != null) {
            sessionStorage.setItem('uid', storedUID);
            sessionStorage.setItem('jwt', storedJWT);
            environment.jwt = storedUID;
            environment.uid = storedJWT;
            this.usersService.getInfoByUID(storedUID)
                .pipe(takeUntil(this.destroySubject$))
                .subscribe(
                    (data) => {
                        this.setUser(data);
                        this.router.navigate(['/workspace']);
                    },
                    (error) => {
                        this.logout();
                    }
                );
        }
    }

    ngOnDestroy(): void {
        this.destroySubject$.unsubscribe();
    }

    createLoginForm() {
        this.loginForm = this.formBuilder.group({
            user: [null, [Validators.required, Validators.email]],
            password: [null, Validators.required],
        });
    }

    // ############## Gigya Login ############## //

    // External login
    loginWithRaaS() {
        // Hide initial screen elements
        this.hideInit();
        // Show padding top for this screen
        document
            .getElementById('gigya-placeholder')
            .classList.remove('no-padding');
        // Redirects directly using URL
        gigya.accounts.showScreenSet({
            screenSet: 'Bacardi-style-RegistrationLogin',
            startScreen: 'gigya-login-screen',
            containerID: 'gigya-placeholder',
            onError: this.showErrors,
        });

        /* Actions associated to events */
        // socialize -> accounts?
        if (!environment.jwt) {
            gigya.accounts.addEventHandlers({
                onLogin: this.getJWT,
            });
        }
        this.setJWTResponse();

    }

    getJWT(eventObj) {
        if (eventObj.data.userEnabled || eventObj.provider.includes('bacardi')) {
            const uid = eventObj.UID;
            if (eventObj.remember) {
                localStorage.setItem('uid', uid);
            }
            sessionStorage.setItem('remember', eventObj.remember);
            sessionStorage.setItem('uid', uid);
            environment.uid = uid;
        } else {
            sessionStorage.setItem('notActiveAtGigya', 'true');
        }
        gigya.accounts.getJWT({ callback: gigya.getJWT2 });
    }

    logout() {
        gigya.accounts.logout();
        localStorage.clear();
        sessionStorage.clear();
        this.ngZone.run(() => this.router.navigate(['/login']));
        this.userService.clearUser();
    }

    showErrors(response) {
        // handle errors
        document.querySelector(
            '#gigya-login-form > div:nth-child(3) > div'
        ).innerHTML = '<p>Error details: ' + response.errorMessage + '</p>';
    }

    // HIDE SHOW THINGS
    restoreInit() {
        document.getElementById('internal-login').style.display = 'block';
        document.getElementById('wrap-gigya').style.display = 'block';
        document.querySelector('.my_bg').classList.remove('my_bg_with_scroll');
        document
            .getElementById('gigya-placeholder')
            .classList.remove('no-padding');
        document.querySelector('.login-form').classList.remove('white');
    }

    hideInit() {
        document.getElementById('internal-login').style.display = 'none';
        document.getElementById('wrap-gigya').style.display = 'none';
        document.querySelector('.my_bg').classList.add('my_bg_with_scroll');
        document
            .getElementById('gigya-placeholder')
            .classList.remove('no-padding');
        document.querySelector('.login-form').classList.add('white');
    }

    goBack() {
        this.restoreInit();
        // Redirects directly using URL
        gigya.accounts.hideScreenSet({
            screenSet: 'Bacardi-style-RegistrationLogin',
            containerID: 'gigya-placeholder',
        });
    }

    // Bacardi users Login
    loginInternal() {
        gigya.accounts.socialLogin({ provider: 'saml-bacardi-adfs' });
        gigya.accounts.addEventHandlers({
            onLogin: this.getJWT,
        });

        this.setJWTResponse();
    }

    // New Account
    registerWithRaaS() {
        // Hide initial screen elements
        this.hideInit();

        // Hide padding top for this screen
        document
            .getElementById('gigya-placeholder')
            .classList.add('no-padding');

        // Redirects directly using URL
        gigya.accounts.showScreenSet({
            screenSet: 'Bacardi-style-RegistrationLogin',
            startScreen: 'gigya-register-screen',
            containerID: 'gigya-placeholder',
        });

        /* Actions associated to events */
        gigya.accounts.addEventHandlers({});
        return false;
    }

    // ############## Gigya Login ############## //

    //#region Util
    private setJWTResponse(): void {
        this.jwt = (response) => {
            if (!Boolean(sessionStorage.getItem('notActiveAtGigya'))) {
                if (response.id_token !== null) {
                    if (Boolean(sessionStorage.getItem('remember')) === true) {
                        sessionStorage.removeItem('remember');
                        localStorage.setItem('jwt', response.id_token);
                    }
                    sessionStorage.setItem('jwt', response.id_token);
                    environment.jwt = response.id_token;

                    // oauth2 token call
                    this.usersService.getTokenApi().subscribe((res) => {
                        if (res && res.status === 200) {
                            const dateNow = new Date();
                            sessionStorage.setItem('loginDate', dateNow.getTime().toString());
                            sessionStorage.setItem('oauth', res.body.access_token);
                            // First call to our back
                            this.usersService.eventLog(environment.uid, 'login', 'user').subscribe();
                            this.usersService.getInfoByUID(environment.uid)
                                .pipe(takeUntil(this.destroySubject$))
                                .subscribe(
                                    (data) => {
                                        this.setUser(data);
                                        this.ngZone.run(() =>
                                            this.router.navigate(['/workspace'])
                                        );
                                    },
                                    (error) => {
                                        this.logout();
                                    }
                                );
                        }
                        else {
                            this.logout();
                        }
                    });

                } else {
                    this.logout();
                }
            } else {
                sessionStorage.clear();
                this.ngZone.run(() => this.errorHandler.showError(
                    'Your user has not been activated at Gigya yet. Please contact an Admin if the problem persists.'
                ));
            }
        };
        gigya.getJWT2 = this.jwt;
    }

    private setUser(userData: any) {
        this.userService.user = new UserModel({
            adminLevel: userData.rol.adminLevel,
            countries: userData.userCountries,
            default_country_id: userData.defaultCountry ? userData.defaultCountry.id : null,
            defaultRegion: userData.defaultCountry ? userData.defaultCountry.region : null,
            email: userData.email,
            firstname: userData.firstName,
            ID: userData.id,
            is_Admin: userData.isAdmin ? 1 : 0,
            language: 'en',
            lastname: userData.lastName,
            role: userData.rol.name,
            uid: userData.uid,
            user_role_id: userData.rol.id,
        });
    }
    //#endregion
}
