import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { WinningInModel } from '../models/winning-in.model';
import { OutletWinningInApiService } from './outlet-winning-in-api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OutletWinningInService {
    constructor(
        private outletWinningInApi: OutletWinningInApiService
    ) { }

    public info(winningInId: number) {
        return this.outletWinningInApi.info(winningInId).pipe(
            map(res => new WinningInModel(res))
        );
    }

    public search(countryId: number) {
        return this.outletWinningInApi.search(countryId);
    }

    public createOrEdit(winningIn: WinningInModel) {
        return this.outletWinningInApi.createOrEdit(winningIn);
    }

    public getWinningIns(countryId: number, size?: number, page?: number): Observable<WinningInModel[]> {
        return this.outletWinningInApi.getWinningIns(countryId, size, page).pipe(
            map(res => {
                if (res && res.content) {
                    const winningIns: WinningInModel[] = [];
                    res.content.forEach(wi => {
                        winningIns.push(new WinningInModel(wi));
                    });

                    return winningIns;
                }
            })
        );
    }
}
