import { GlobalModel } from './global.model';
import { RegionModel } from './region.model';

export class ContactHobbyModel {
    public id: number;
    public name?: string;
    public region?: RegionModel;
    public deleted?: boolean;
    public hobbyGlobal?: GlobalModel;
    public createDate?: Date;
    public createdBy?: string;

    constructor(hobbyObj: Partial<ContactHobbyModel>) {
        this.id = hobbyObj.id;
        this.name = hobbyObj.name;
        this.region = hobbyObj.region  || new RegionModel({});
        this.deleted = hobbyObj.deleted;
        this.hobbyGlobal = hobbyObj.hobbyGlobal;
        this.createDate = hobbyObj.createDate || new Date();
        this.createdBy = hobbyObj.createdBy || 'c786968532d8481482ac60bb9190ab73';
    }
}
