import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InfluencerLevelModel } from 'src/app/models/influencer-level.model';
import { InfluencerLevelsService } from 'src/app/providers/influencer-levels.service';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide : NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InfluencerLevelSelectComponent),
    multi : true,
};
@Component({
    selector: 'app-influencer-level-select',
    templateUrl: './influencer-level-select.component.html',
    providers : [CUSTOM_VALUE_ACCESSOR]
})
export class InfluencerLevelSelectComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;
    public disabled = false;
    public value: string = null;
    public influencerLevels: InfluencerLevelModel[];
    private onChange: Function = (_: any) => {};
    private onTouched: Function = () => {};

    constructor(private influencerLevelService: InfluencerLevelsService) {}

    ngOnInit() {
        const params = {
            'searchStr': ''
        };
        this.influencerLevelService.search(params).subscribe(data => {
            this.influencerLevels = data;
            this.influencerLevels.unshift(new InfluencerLevelModel({'id': null, 'name': ''}));
        });
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    influencerLevelChange(ev)
    {
        this.value = ev.target.value;
        this.onChange(this.value);
        this.onTouched();
    }
}
