import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class LanguagesApiService {
    private endpointMulti = 'users';
    private endpointSingle = 'lang';

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public list()
    {
        return this.api.get(this.endpointMulti + '/allLanguajes', {}, {headers: {Authorization: this.user.token}})
        .pipe(
            map(
                (res: any) => {
                    return res;
                }
            )
        );
    }
}
