import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersApiService } from './users-api.service';
import { UserModel } from '../models/user.model';
import { ApiService } from './api.service';
import { HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(
        private usersApi: UsersApiService,
        private api: ApiService
    ) { }

    public activate(userId: number) {
        return this.usersApi.activate(userId);
    }

    public list(params, paramTable?) {
        return this.usersApi.list(params, paramTable);
    }

    public info(userId: number) {
        return this.usersApi.info(userId);
    }

    public getInfoByUID(uid: string): Observable<any> {
        return this.usersApi.getInfoByUID(uid);
    }

    public delete(userId: number) {
        return this.usersApi.delete(userId);
    }

    public createOrEdit(user: any) {
        return this.usersApi.createOrEdit(user);
    }

    public roles() {
        return this.usersApi.roles().pipe(
            map(l => {
                return l;
            })
        );
    }

    public listUsers() {
        return this.usersApi.listUsers();
    }

    public listManagers() {
        return this.usersApi.listManagers();
    }

    public ambassadors() {
        const ambassadors: UserModel[] = [];
        return this.usersApi.ambassadors().pipe(
            map(l => {
                if (l.data) {
                    l.data.forEach(element => ambassadors.push(new UserModel(element)));
                }
                return ambassadors;
            })
        );
    }

    public sendInvitation(userId: number) {
        return this.usersApi.sendInvitation(userId);
    }

    public getCountriesByUser(): Observable<any> {
        return this.usersApi.getCountriesByUser();
    }

    public getActivityCategoriesByUser(): Observable<any> {
        return this.usersApi.getActivityCategoryByUser();
    }

    public acceptRequestUser(user: any) {
        return this.usersApi.approveUserRequest(user);
    }

    public denyRequestUser(user: any) {
        return this.usersApi.denyUserRequest(user);
    }

    public eventLog(user: string, type: string, table_relationship: string) {
        return this.usersApi.logEvent(type, user, table_relationship);
    }

    public getTokenApi(): Observable<HttpResponse<any>> {
        return this.api.postOauth();
    }

}
