export const environment = {
  production: true,
  apikey: 'TE40lesixkWU6ho66MXPxJV8lA1aGcAS',
  jwt: '',
  uid: '',
  // Google Tag Manager
  gtm: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
    {'gtm.start': new Date().getTime(),event:'gtm.js'}
    );var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=TX8ejyNcC3pDUAlhdm7OEw&gtm_preview=env-8&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-NN7WFJS');`,
  gtmNoscript: `<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-NN7WFJS&gtm_auth=TX8ejyNcC3pDUAlhdm7OEw&gtm_preview=env-8&gtm_cookies_win=x' height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
  serviceUrl: 'https://apim.integration.bacardiapps.com/v2/ambassador/api',
  oauth2ClientId: 'TE40lesixkWU6ho66MXPxJV8lA1aGcAS',
  oauth2ClientSecret: 'dvkukbIBvh31YRDi',
  gigya_apiKey: '3_SdNBb4fCz2bwAlq6yb-P1GeKE6l7LmyUPronwi89hCLpEFS3LS_dIfFaAKIIsLgu'
};
