import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormPage } from 'src/app/pages/FormPage';
import { InfluencerLevelsService } from 'src/app/providers/influencer-levels.service';
import { InfluencerLevelModel } from 'src/app/models/influencer-level.model';
import { GlobalMessage } from 'src/app/providers/global-message.service';
import { GlobalModel } from 'src/app/models/global.model';
import { UserService } from '../../../../../providers/user.service';


@Component({
    selector: 'app-influencer-level-edit.page',
    templateUrl: './influencer-level-edit.page.html'
})
export class InfluencerLevelEditPage extends FormPage implements OnInit {
    public influencerLevelForm = this.fb.group({
        influencerLevelId: [''],
        name: ['', Validators.required],
        global: ['', Validators.required],
    });

    public influencerLevelId: number = null;

    constructor(
        private fb: FormBuilder,
        private influencerLevelService: InfluencerLevelsService,
        private route: ActivatedRoute,
        private errorHandler: GlobalMessage,
        private router: Router,
        private userService: UserService) {
        super();
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (params.get('id') !== null) {
                this.influencerLevelId = Number.parseInt(params.get('id'), 10);
                this.influencerLevelService.info(Number.parseInt(params.get('id'), 10)).subscribe(
                    (data) => {
                        this.influencerLevelForm.setValue({
                            influencerLevelId: data.id,
                            name: data.name,
                            global: data.influencerLevelsGlobal ? data.influencerLevelsGlobal.id : null
                        });
                    }
                );
            }
        });
    }

    onSubmit() {
        if (this.influencerLevelForm.valid) {
            const influencerLevel = this.influencerLevelForm.value;
            const influencerGlobal = new GlobalModel({ id: influencerLevel.global.id, name: influencerLevel.global.name });
            this.influencerLevelService.createOrEdit(new InfluencerLevelModel({
                id: influencerLevel.influencerLevelId,
                name: influencerLevel.name,
                influencerLevelsGlobal: influencerGlobal,
                region: this.userService.user.defaultRegion,
            })).subscribe((data: any) => {
            }, error => {
                this.errorHandler.showError('Form contains errors');

            }, () => {
                this.errorHandler.showSuccess('Your influencer level has been saved successfully', 5000);
                this.router.navigate(['/workspace/influencer-levels']);
            });
        }
        else {
            this.validateAllFormFields(this.influencerLevelForm);
            this.errorHandler.showError('Form contains errors');
        }
    }

    getForm(): FormGroup {
        return this.influencerLevelForm;
    }
}
