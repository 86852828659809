import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BrandModel } from '../models/brand.model';
import { ApiService } from './api.service';
import { UserService } from './user.service';


@Injectable({
    providedIn: 'root'
})
export class BrandsApiService {
    private endpoint = 'brandregion';
    private endpointAlt = 'brands';
    private requestHeader = {
        headers: { Authorization: this.user.token }
    };

    constructor(
        private api: ApiService,
        private user: UserService) { }

    public list() {
        return this.api.get(this.endpoint + '/list', null, this.requestHeader)
            .pipe((res: any) => res);
    }

    public search(searchStr = '', countryId?, brandCategoryId?) {
        const params = { searchStr: searchStr, size: 99999, sort: 'name,asc' };
        if (countryId) {
            params['country_id'] = countryId;
        }
        if (brandCategoryId) {
            params['brand_category_id'] = brandCategoryId;
        }

        const headers = this.requestHeader;
        headers['params'] = params;

        return this.api.post(this.endpoint + '/search/' + this.user.user.id, {}, headers)
            .pipe(
                (res: any) => res
            );
    }

    public info(brandId: number) {
        return this.api.get(`${this.endpoint}/detail/${brandId}`, {}, this.requestHeader);
    }

    public delete(brandId: number) {
        return this.api.patch(this.endpoint + '/delete/' + brandId.toString(), {}, this.requestHeader);
    }

    public create(brand: BrandModel, loadPastPrograms: boolean = false) {
        // Enviar el region por defecto del usuario logado
        brand.region = this.user.user.countries[0].region;
        return this.api.post(`${this.endpoint}/add`, brand, this.requestHeader);
    }

    public edit(brand: BrandModel, loadPastPrograms: boolean = false) {
        return this.api.put(`${this.endpoint}/edit/${brand.id}`, brand, this.requestHeader);
    }

    public getGlobals() {
        return this.api.get(this.endpointAlt + '/list', null, this.requestHeader)
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                )
            );
    }

    public getByBrandCategory(brandCategoryId: number, countryId: number) {
        return this.api.get(this.endpoint + '/brandByCategory/' + brandCategoryId + '/' + countryId, {}, this.requestHeader);
    }

    public createGlobal(global) {
        return this.api.post(`${this.endpointAlt}/add`, global, this.requestHeader);
    }
}
