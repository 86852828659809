import * as moment from 'moment';
import { AttendeeTypeModel } from './attendee-type.model';
import { BrandModel } from './brand.model';
import { ContactHobbyModel } from './contact-hobby.model';
import { ContactTitleModel } from './contact-title.model';
import { CountryModel } from './country.model';
import { InfluencerLevelModel } from './influencer-level.model';
import { LanguageModel } from './language.model';
import { UserModel } from './user.model';

export class AttendeeContactModel {
    public id: number;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public email: string;
    public title: ContactTitleModel;
    public attendeesType: AttendeeTypeModel;
    public language: LanguageModel;
    public country: CountryModel;
    public phone: string;
    public mobile: string;
    public birthday: moment.Moment;
    public note: string;
    public newsletter: boolean;
    public influencerLevel: InfluencerLevelModel;
    public createdBy: UserModel;
    public createdOn: moment.Moment;
    public deleted: boolean;
    public hobbies: ContactHobbyModel[];
    public focusBrands: BrandModel[];

    public fromJson(jsonData: any)
    {
        this.id = jsonData.ID ? Number.parseInt(jsonData.ID, 10) : null;
        this.firstName = jsonData.firstname;
        this.middleName = jsonData.middlename;
        this.lastName = jsonData.lastname;
        this.email = jsonData.email;
        this.phone = jsonData.phone;
        this.mobile = jsonData.mobile;
        this.birthday = moment(jsonData.birthday, 'YYYY-MM-DD');
        this.note = jsonData.note;
        this.newsletter = jsonData.newsletter === '1';
        this.createdOn = moment(jsonData.created_on, 'YYYY-MM-DD HH:mm:ss');
        this.deleted = jsonData.deleted === '1';

        this.attendeesType = new AttendeeTypeModel({id: jsonData.attendee_type_id});

        if (this.language !== undefined)
        {
            this.language.fromJson({ID: jsonData.lang});
        }
        else
        {
            this.language = new LanguageModel({ID: jsonData.lang});
        }

        if (this.createdBy !== undefined)
        {
            this.createdBy.fromJson({ID: jsonData.created_by});
        }
        else
        {
            this.createdBy = new UserModel({ID: jsonData.created_by});
        }

        this.hobbies = [];
        if (jsonData.hobbies)
        {
            jsonData.hobbies.forEach(hobby => {
                let h;
                if (typeof hobby === 'object')
                {
                    h = new ContactHobbyModel(hobby);
                }
                else
                {
                    h = new ContactHobbyModel({id: hobby});
                }
                this.hobbies.push(h);
            });
        }

        this.focusBrands = [];
        if (jsonData.focus_brands)
        {
            jsonData.focus_brands.forEach(focusBrand => {
                let f;
                if (typeof focusBrand === 'object')
                {
                    f = new BrandModel(focusBrand);
                }
                else
                {
                    f = new BrandModel({ID: focusBrand});
                }
                this.focusBrands.push(f);
            });
        }
    }

    constructor(jsonData: any) {
        this.fromJson(jsonData);
    }

    get name()
    {
        let name = '';
        if (this.firstName)
        {
            name += this.firstName;
            if (this.lastName)
            {
                name += ' ' + this.lastName;
            }
        }
        else if (this.lastName)
        {
            name = this.lastName;
        }
        else
        {
            name = this.email;
        }

        return name;
    }
}
