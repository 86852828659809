import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProgramsService } from 'src/app/providers/programs.service';
import { ProgramModel } from 'src/app/models/program.model';

const CUSTOM_VALUE_ACCESSOR: any = {
    provide : NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProgramSelectComponent),
    multi : true,
};
@Component({
    selector: 'app-program-select',
    templateUrl: './program-select.component.html',
    providers : [CUSTOM_VALUE_ACCESSOR]
})
export class ProgramSelectComponent implements ControlValueAccessor, OnInit {
    @Input() inputId: string;

    public disabled = false;
    public value: string = null;
    public programs: ProgramModel[];
    private _brandId: number = null;
    private onChange: Function = (_: any) => {};
    private onTouched: Function = () => {};

    constructor(private programService: ProgramsService) {}

    @Input() set brandId(value: number) {
        this._brandId = value;
        this.refreshValue();
    }

    ngOnInit() {
        this.refreshValue();
    }

    refreshValue() {
        this.programService.list(this._brandId).subscribe((data: any) => {
            this.programs = data;
            this.programs.unshift(new ProgramModel({'ID': null, 'name': ''}));
        });
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    programChange(ev)
    {
        this.value = ev.target.value;
        this.onChange(this.value);
        this.onTouched();
    }
}
