import { AfterViewInit, Component, ContentChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryModel } from 'src/app/models/country.model';
import { DatatablePage } from 'src/app/pages/DatatablePage';
import { CountriesService } from 'src/app/providers/countries.service';

@Component({
    selector: 'app-countries',
    templateUrl: './country-list.page.html'
})
export class CountryListPage extends DatatablePage implements AfterViewInit
{
    @ContentChild('deleteModal', null) deleteModal: TemplateRef<any>;
    public countries: CountryModel[];

    constructor(
        private countryService: CountriesService,
        private modalService: NgbModal
    )
    {
        super();
    }

    ngAfterViewInit() {
        this.refreshList();
        this.refreshDt();
    }

    refreshList() {
        this.countryService.search().subscribe(data => {
            this.countries = data.content;
            this.reRenderDt();
        });
    }

    delete(content, countryId) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            if (result)
            {
                this.countryService.delete(this.countries.find( a => a.id === countryId)).subscribe(
                    x => {
                        this.refreshList();
                    }
                );
            }

        }, (reason) => {
        });
    }

}
